import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Router } from '@angular/router';

@Component({
  selector: 'app-redirect-old-v',
  standalone: true,
  imports: [CommonModule],
  template: '',
})
export class RedirectOldVComponent {

  constructor(private router: Router) {}

  ngOnInit(): void {

    const currentUrl = this.router.url;

    if (currentUrl.includes('AmericanSim')) {
      this.router.navigate(['/get-your-sim/united-states/esim']);
    } else if ('supportedEsimDevices') {
      this.router.navigate(['/supported-esim-devices']);
    } else {
      this.router.navigate(['/']);    
    }
  }
}
