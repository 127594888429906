import { Component, EventEmitter, HostListener, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-credit-card',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './credit-card.component.html',
  styleUrls: ['./credit-card.component.scss']
})
export class CreditCardComponent {
  @Input() showCreditCard: boolean = false 
  @Output() closeModalEvent: EventEmitter<void> = new EventEmitter<void>();
  flipped: boolean = false;
  nameCard: string = "Matias Silva"
  numCard: string = "4242 4242 4242 4242"
  exp: string = "21 / 12"
  cvv: string = "122"
  closeCCModal() {
    this.showCreditCard = false
    this.closeModalEvent.emit();
  }

  @HostListener('document:click', ['$event'])
  onClickOutside(event: any) {

  //   if(event?.target?.id !== "creditModal") {
  //     this.closeCCModal()
  //   }
    
  }

  flipCard() {
    this.flipped = !this.flipped;
  }

  @HostListener('document:keydown', ['$event'])
  onKeyDown(event: KeyboardEvent) {
    if (event.key === 'Escape') {
      this.closeCCModal();
    }
  }

  ngOnInit(): void {
    //Called after the constructor, initializing input properties, and the first call to ngOnChanges.
    //Add 'implements OnInit' to the class.
    
  }
}
