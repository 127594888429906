import { Injectable } from '@angular/core';
import { SettingsService } from '../settings.service';
import { HttpClient } from '@angular/common/http';
import { ControllerApiList } from '../models/controllers/ControllerList';
import { lastValueFrom } from 'rxjs';
import { ApiResponse } from '../models/base/ApiResponse';

@Injectable({
  providedIn: 'root'
})
export class CountriesService {


  constructor(private http:HttpClient, private settings:SettingsService) { }

  async getCountries<T> () {
    // const res = this.http.post<Countries>(`${this.settings.getBaseUrlApi}/${ControllerApiList.WebApi.Countries}`, "")
    const res = this.http.get<ApiResponse<T>>(`${this.settings.getGlobusimBaseUrlApi}/${ControllerApiList.GlobusimApi.GetCountries}`)
    const data = await lastValueFrom(res)
    return data
  }
}
