import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { TranslocoModule } from '@ngneat/transloco';
import { Router, RouterModule } from '@angular/router';
import { ActivateSimService } from 'src/app/services/activate-sim/activate-sim.service';
import { GetActivationPlanResult } from 'src/app/services/models/market-plans.model';
import { PopUpComponent } from 'src/app/core/shared/pop-up/pop-up.component';
import { SubmitOrderService } from '../../services/submit-order/submit-order.service';
import { ApiMessageCode } from 'src/app/services/models/base/ApiMessageCode';
import { Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'app-activate-page',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, TranslocoModule, RouterModule, PopUpComponent],
  templateUrl: './activate-page.component.html',
  styleUrls: ['./activate-page.component.scss'],
})
export class ActivatePageComponent {
  activateForm = this.submitOrderService.submitForm.get('activateForm') as FormGroup
  formSubmitted: boolean = false 
  inputForm: FormGroup = new FormGroup({
    activateNumber: new FormControl(
      '', Validators.compose([Validators.required])
    ),
  });

  showPopUp: boolean = false
  popUpMessage: string = ""

  constructor(
    private formBluider: FormBuilder,
    private activateSimService: ActivateSimService,
    private router: Router,
    private submitOrderService: SubmitOrderService,
    private titleService: Title,
    private meta: Meta
  ) {}

  ngOnInit(): void {
    this.updateMetaTags(
      'Globusim | Activate your SIM here',
      'esim, sim, data only, connectivity ',
      'Travel the world, stay connected'
    );
  }

  updateMetaTags(title: string, keywords: string, description: string) {
    this.titleService.setTitle(title);
    this.meta.updateTag({ name: 'keywords', content: keywords });
    this.meta.updateTag({ name: 'description', content: description });
    this.meta.updateTag({
      rel: 'canonical',
      href: 'https://globusim.com/activate-sim',
    });
  }

  onClosePopUp() {
    this.showPopUp = false;
  }

  onSubmitActivation() {
    this.formSubmitted = true
    if (this.inputForm.valid) {
      this.formSubmitted = false

      const activateNumber = this.inputForm.get('activateNumber').value

      if (activateNumber && activateNumber.charAt(0) === '8') {
          this.activateForm.get('serialNumber').setValue(activateNumber)
      } else {
        this.activateForm.get('phoneNumber').setValue(activateNumber)
      }
      
      this.validatePhoneInStore(activateNumber);
    }
  }
  
  async validatePhoneInStore(phoneNumber: string) {
    const res = await this.activateSimService.validateActivationPhone<GetActivationPlanResult>(phoneNumber);

    if (res.isSuccessful) {
      const marketSelected = res.result.groupName
      const types = res.result.plans.map(t=>t.simTypeName) 
      const simTypes = (types && !types.includes('esim')) ? types[0] : 'esim';
      this.router.navigate(['activate-sim', marketSelected, simTypes], {queryParams: {phoneNumber: phoneNumber}});
    } else if (!res.isError) {
      const message = res.messages ? res.messages[0].code : false
      const apiMessageCode = ApiMessageCode.MessageCode

      if (message === apiMessageCode.InvalidPhoneNumber || message === apiMessageCode.InvalidSerialNumber  ) {
        this.popUpMessage = "invalid-activation-number"
      } else if (message === apiMessageCode.NumberAlreadyExists) {
        this.popUpMessage = "alreadyInUse-activation-number"
      } else {
        this.popUpMessage = "unknown-problem"
      }

      this.showPopUp = true
      console.log(res.messages.join('\n'))
    } else {
      console.log(res.errorMessage)
    }
  }
}
