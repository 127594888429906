import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslocoModule } from '@ngneat/transloco';

@Component({
  selector: 'app-home-why-globusim',
  standalone: true,
  imports: [CommonModule, TranslocoModule],
  templateUrl: './home-why-globusim.component.html',
  styleUrls: ['./home-why-globusim.component.scss']
})
export class HomeWhyGlobusimComponent {

}
