import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { SettingsService } from '../settings.service';
import { ControllerApiList } from '../models/controllers/ControllerList';
import { lastValueFrom } from 'rxjs';
import { IpLocation, IpLocationRoot } from '../models/IpLocation.model';

@Injectable({
  providedIn: 'root'
})
export class IpLocationService {
  private userIpLocation: IpLocation;

  constructor(private http:HttpClient, private settings:SettingsService) { }

  async getIp () {
    const res = this.http.get<IpLocationRoot>(`${this.settings.getBaseUrlApi}/${ControllerApiList.WebApi.IpLocation}`)
    const data = await lastValueFrom(res)
    return data
  }

  setUserIpLocation(data: IpLocation) {
    this.userIpLocation = data
  }

  getUserIpLocation() {
    return this.userIpLocation
  }
}
