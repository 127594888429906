import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslocoModule } from '@ngneat/transloco';
import { PickupStoreLocationsService } from '../../../services/pickup-store-locations/pickup-store-locations.service';
import { Router } from '@angular/router';
import { SubmitOrderService } from 'src/app/services/submit-order/submit-order.service';
import { SimPlan } from 'src/app/services/models/market-plans.model';
import { FormGroup } from '@angular/forms';
import { ShipElement } from 'src/app/services/models/shipping-options.model';
import { IEnvelopeObj } from 'src/app/services/models/pickup-store.model';

@Component({
  selector: 'app-modal-store-info',
  standalone: true,
  imports: [CommonModule, TranslocoModule],
  templateUrl: './modal-store-info.component.html',
  styleUrls: ['./modal-store-info.component.scss'],
})
export class ModalStoreInfoComponent {
  @Output() showStoreInfo = new EventEmitter<boolean>();
  @Output() envelopeData = new EventEmitter<IEnvelopeObj>();
  @Output() optionSelected: EventEmitter<ShipElement> =
    new EventEmitter<ShipElement>() || null;

  @Input() simQuantity: number = 1;
  @Input() selectedPlan: SimPlan | undefined = undefined;

  storeData: any = this.pickupService.getStore();
  addressForm = this.submitOrderService.submitForm.get(
    'addressForm'
  ) as FormGroup;

  constructor(
    private pickupService: PickupStoreLocationsService,
    private router: Router,
    private submitOrderService: SubmitOrderService
  ) {}

  ngOnInit(): void {}

  closeModal() {
    this.showStoreInfo.emit(false);
  }

  goToAmericanSubmit() {
    this.showStoreInfo.emit(false);
    this.router.navigate(['pickup-store']);
  }

  getEnvelope(location: string) {

      const envelopeObj = {
        envelopes: "",
        PromoCode: "",
        locationID: location.toString()
      }
      
      this.envelopeData.emit(envelopeObj)
      this.optionSelected.emit(null)
      this.showStoreInfo.emit(false);
  }
}
