import { CommonModule, ViewportScroller } from '@angular/common';
import { Component } from '@angular/core';
import { ActivatedRoute, RouterOutlet } from '@angular/router';
import { TranslocoService } from '@ngneat/transloco';
import { LangDefinition } from '@ngneat/transloco';
import { HeaderComponent } from './core/header/header.component';
import { FooterComponent } from './core/footer/footer.component';
import { cookiesStorage } from '@ngneat/transloco-persist-lang';
import { IpLocationService } from './services/IpLocation/ip-location.service';
import { WhatsappBtnComponent } from './core/shared/whatsapp-btn/whatsapp-btn.component';
import { ScrollToTopBtnComponent } from './core/shared/scroll-to-top-btn/scroll-to-top-btn.component';

@Component({
  selector: 'app-root',
  standalone: true,
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  imports: [CommonModule, RouterOutlet, HeaderComponent, FooterComponent, WhatsappBtnComponent, ScrollToTopBtnComponent],
})
export class AppComponent {
  textDir: string = 'ltr';
  ipData: any;
  userCountryId: number = 0;
  countryIso: string = '';
  countryUser: string = '';

  constructor(
    private translate: TranslocoService,
    private ipLocationService: IpLocationService,
    private route: ActivatedRoute,
    private scroller: ViewportScroller
    
  ) {
    this.verifyLanguage();
  }

  /**
   * Verify user languague
   */
  verifyLanguage() {
    const supportedLangs =
      this.translate.getAvailableLangs() as LangDefinition[];

    this.route.queryParams.subscribe((params) => {
      const lang = params['lang'];

      if (lang && supportedLangs.find((s) => s.id == lang)) {
        this.translate.setActiveLang(lang);
      } else {
        const storedLanguage = cookiesStorage().getItem('translocoLang');

        if (!storedLanguage) {
          const browserLang = navigator.language.split('-')[0];
          if (supportedLangs.find((s) => s.id == browserLang)) {
            this.translate.setActiveLang(browserLang);
          }
        }
      }
    });

    this.translate.langChanges$.subscribe((lang: string) => {
      if (lang == 'he') {
        this.textDir = 'rtl';
      } else {
        this.textDir = 'ltr';
      }
    });
  }

  async getIpLocation() {
    const res = await this.ipLocationService.getIp();
    this.ipData = res.elements[0];
    this.ipLocationService.setUserIpLocation(this.ipData)
  }

  onActivate(event: any) {
    window.scroll(0, 0);
  }

  ngOnInit(): void {
    this.getIpLocation();
  }

  ngAfterViewInit(): void {
    this.scroller.scrollToPosition([0, 0]);
  }
}
