import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslocoService } from '@ngneat/transloco';

@Component({
  selector: 'app-whatsapp-btn',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './whatsapp-btn.component.html',
  styleUrls: ['./whatsapp-btn.component.scss']
})
export class WhatsappBtnComponent {
  whatsappNumbers: any = {
    en: '+19542312500',
    es: '+19542312500',
    he: '+19542312500',
    pt: '+19542312500',
  };
  currentLanguage: string;

  constructor(private translocoService: TranslocoService) {
    this.currentLanguage = this.translocoService.getActiveLang();
  }

  getWhatsappNumber(): string {
    return this.whatsappNumbers[this.currentLanguage] || '+19542312500'; // Default number if there is no number for the current language
  }
}
