<main *ngIf="article" class="relative py-10 px-6 md:px-20 xl:px-40">
  <ng-container *ngIf="isLoading">
    <app-spinner></app-spinner>
  </ng-container>
  <span routerLink="/blog" class="absolute top-4 left-6 opacity-50 hover:opacity-100 hover:scale-105 cursor-pointer">
    <img src="assets/images/icons/arrow-back-icon.svg" alt="back arrow" class="w-[2rem] h-[2rem]">
  </span>

  <section *ngIf="!isLoading" class="">
    <button class="bg-orangeLight text-orange text-xs px-2 py-1 rounded my-4 capitalize"
      [transloco]="article.category"></button>
    <h1 class="text-3xl font-semibold mb-4" [transloco]="article.title"></h1>
    <p class="text-sm my-8">{{ article.date | date:'medium' }}</p>

    <img *ngIf="viewport > 480" [src]="article.imageBlog" alt="blog image" class="w-full">

    <img *ngIf="viewport < 480" [src]="article.imageCard" alt="blog image" class="w-full">

  </section>
  <section class="py-10">
    <app-blog-article-content [translatedMarkdown]="translatedMarkdown"></app-blog-article-content>
  </section>
</main>