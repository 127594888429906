import { Component, ElementRef, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LanguageDropdownComponent } from '../language-dropdown/language-dropdown.component';
import { NavLinksComponent } from '../nav-links/nav-links.component';
import { ProfileDropdownComponent } from '../profile-dropdown/profile-dropdown.component';
import { RouterModule } from '@angular/router';

@Component({
  selector: 'app-nav-bar',
  standalone: true,
  imports: [
    CommonModule,
    LanguageDropdownComponent,
    NavLinksComponent,
    ProfileDropdownComponent,
    RouterModule
  ],
  templateUrl: './nav-bar.component.html',
  styleUrls: ['./nav-bar.component.scss'],
})
export class NavBarComponent {
  showMenu = false;

  constructor(private elementRef: ElementRef) {}

  ngOnInit(): void {
    document.addEventListener('click', this.onClickOutside.bind(this));
  }

  toggleMenu() {
    this.showMenu = !this.showMenu;
  }

  closeMenu() {
    // Cierra el menú al hacer clic en un enlace
    this.showMenu = false;
  }

  onClickOutside(event: Event) {
    if (!this.elementRef.nativeElement.contains(event.target)) {
      this.closeMenu();
    }
  }
}
