<div class="block rounded-xl bg-white p-6 md:p-12 shadow-[0_2px_15px_-3px_rgba(0,0,0,0.07),0_10px_20px_-2px_rgba(0,0,0,0.04)]">
  <form [formGroup]="contactForm" (ngSubmit)="onSubmitContactForm()">
    <!--Name input-->
    <div class="mb-6">
      <label for="fullName" class=" mb-0 max-w-[90%] pt-[0.37rem] leading-[1.6]" transloco="contact.form-fullName"></label>
      <input type="text" formControlName="fullName"
        class="{{contactForm.get('fullName')?.invalid && formSubmitted ? 'border-danger': 'border-greyDark'}} block min-h-[auto] w-full rounded border border-greyDark bg-transparent px-3 py-[0.32rem] leading-[1.6] outline-none focus:border-grey"
        id="fullName" />
    </div>

    <!--Email input-->
    <div class="mb-6">
      <label for="email" class="mb-0 max-w-[90%] pt-[0.37rem] leading-[1.6]" transloco="contact.form-email"></label>
      <input type="email" formControlName="email"
        class="{{contactForm.get('email')?.invalid && formSubmitted ? 'border-danger': 'border-greyDark'}} block min-h-[auto] w-full rounded border bg-transparent px-3 py-[0.32rem] leading-[1.6] outline-none focus:border-grey"
        id="email" />
    </div>

    <!--Phone input-->
    <div class="flex flex-col mb-16">
      <label for="phoneInput" class="mb-0 max-w-[90%] pt-[0.37rem] leading-[1.6]" transloco="contact.form-phone"></label>
      <input #phoneInput type="tel" id="phoneInput" formControlName="phone"
        class="{{contactForm.get('phoneInput')?.invalid && formSubmitted ? 'border-danger': 'border-greyDark'}} block min-h-[auto] w-full rounded border border-greyDark bg-transparent px-3 py-[0.32rem] leading-[1.6] outline-none focus:border-grey" />
    </div>

    <!--Message textarea-->
    <div class="mb-6">
      <label for="message" class="pointer-events-none mb-0 max-w-[90%] pt-[0.37rem] leading-[1.6]" transloco="contact.form-message"></label>
      <textarea formControlName="message"
        class="block min-h-[auto] w-full rounded border border-greyDark bg-transparent px-3 py-[0.32rem] leading-[1.6] outline-none focus:border-grey"
        id="message" rows="3"></textarea>
    </div>

    <!--Submit button-->
    <button type="submit"
      class="{{ !contactForm.valid ? 'hover:bg-red opacity-50' : 'hover:scale-105'}} flex mx-auto rounded-full bg-orange px-8 pb-2 pt-2.5 text-xs font-medium leading-normal text-white shadow-2xl transition duration-150 ease-in-out"
      transloco="contact.form-btn-send">
    </button>
  </form>

  <app-pop-up *ngIf="showPopUp"
    [showPopUp]="showPopUp" 
    (closePopUpEvent)="onClosePopUp()" 
    [translate]="'contact'"></app-pop-up>
</div>



<!-- MESSAGE RECIVIED -->