<main id="getSimPage" class="p-10 mx-auto">
  <section
    class="bg-title-bg bg-no-repeat md:bg-[center_left_10rem] mlg:bg-[center_left_35rem] py-16 animate-fade-down">
    <h2 class="text-center text-4xl font-semibold" transloco="get-sim.title"></h2>
  </section>

  <section class="flex flex-col items-center">

    <p class="text-lg text-center mlg:w-3/4 animate-fade-down" transloco="get-sim.subtitle-1"></p>
    <p class="text-lg text-center mlg:w-3/4 animate-fade-down" transloco="get-sim.subtitle-2"></p>
    <div class="w-full md:w-3/4 mlg:w-2/4 max-w-lg animate-fade-up">
      <!-- <app-search-input></app-search-input> -->
      <div class=" w-full xl:w-[90%] mx-auto lg:mx-0">
        <div class="relative w-full pt-10 transition delay-150 duration-300 ease-in-out hover:scale-105 ">
          <input #inputSearch id="inputSearch" [placeholder]="'search-bar.placeholder' | transloco"
            (click)="showModal = true"
            class="w-full p-6 px-14 rounded-full border border-greyLight shadow-xl hover:shadow-2xl outline-none">
          <img src="assets/images/icons/location.svg" alt="location icon"
            class="absolute top-14 pt-2 ltr:left-4 rtl:right-4 w-[2rem] h-[2rem] ">
          <img src="assets/images/icons/search-icon.svg" alt="search icon"
            class="absolute top-12 pt-1 ltr:right-0 rtl:left-0 w-[3.5rem] h-[3.5rem] mx-2">
        </div>
      </div>
    </div>
  </section>

  <div *ngIf="showModal">
    <app-modal-search-input (closeModalEvent)="onCloseModal()" [showModal]="showModal"></app-modal-search-input>
  </div>

  <section class="pt-10 lg:py-10">
    <!-- MARKET CARDS SKELETON -->
    <div *ngIf="visibleCountries.length === 0"
      class="grid grid-cols-1 smd:grid-cols-2 mlg:grid-cols-3 xl:grid-cols-4 wide:grid-cols-5 gap-5 smd:px-8 xl:gap-8">
      <ng-container *ngFor="let box of skeletonBoxes">
        <div class="bg-greyLight animate-pulse w-full md:w-[280px] h-[110px] rounded-xl"></div>
      </ng-container>
    </div>
    <!-- MARKETS CARDS -->
    <div class="grid grid-cols-1 smd:grid-cols-2 mlg:grid-cols-3 xl:grid-cols-4 gap-5 smd:px-8 xl:gap-8">
      <ng-container *ngFor="let country of visibleCountries">
        <app-markets-card [country]="country"></app-markets-card>
      </ng-container>
    </div>

    <div *ngIf="!showAllCountries" class="flex justify-center py-12">
      <button type="button" (click)="showMoreMarkets()"
        class="bg-orange rounded-full px-12 py-4 w-4/5 md:w-2/5 text-white hover:scale-105"
        transloco="get-sim.btn-more"></button>
    </div>

    <div *ngIf="showAllCountries" class="flex justify-center py-12">
      <button type="button" (click)="showLessMarkets()"
        class="bg-orange rounded-full px-12 py-4 w-4/5 md:w-2/5 text-white hover:scale-105"
        transloco="get-sim.btn-less"></button>
    </div>
  </section>

  <!-- REGIONS PACKAGES -->
  <section class="lg:py-10 lg:px-10">
    <h2 class="text-center text-4xl font-semibold py-10" transloco="get-sim.second-title"></h2>

    <article class="flex flex-col-reverse items-center mlg:flex-row mlg:justify-center gap-5">
      <!-- REGION LIST DESCRIPTION -->
      <div class="bg-world-map bg-no-repeat bg-cover w-full mlg:w-1/2 p-6 rounded-3xl max-w-[780px]">
        <h4 class="text-xl font-semibold" transloco="get-sim.regions-info-title"></h4>
        <span class="text-sm" transloco="get-sim.regions-info-subtitle"></span>
        <ul class="list-none py-4">
          <li class="flex py-1.5 text-sm">
            <img src="assets/images/icons/tick2-icon.svg" alt="tick icon" class="mr-4 w-[1.25rem] h-[1.25rem]">
            <span transloco="get-sim.regions-info-item-list-1"></span>
          </li>
          <li class="flex py-1.5 text-sm">
            <img src="assets/images/icons/clock-icon.svg" alt="clock icon" class="mr-4 w-[1.25rem] h-[1.25rem]">
            <span transloco="get-sim.regions-info-item-list-2"></span>
          </li>
          <li class="flex py-1.5 text-sm">
            <img src="assets/images/icons/plus-icon.svg" alt="plus icon" class="mr-4 w-[1.25rem] h-[1.25rem]">
            <span transloco="get-sim.regions-info-item-list-3"></span>
          </li>
          <li class="flex py-1.5 text-sm">
            <img src="assets/images/icons/travel-icon.svg" alt="travel icon" class="mr-4 w-[1.25rem] h-[1.25rem]">
            <span transloco="get-sim.regions-info-item-list-4"></span>
          </li>
          <li class="flex py-1.5 text-sm">
            <img src="assets/images/icons/vpn-icon.svg" alt="vpn icon" class="mr-4 w-[1.25rem] h-[1.25rem]">
            <span transloco="get-sim.regions-info-item-list-5"></span>
          </li>
        </ul>
      </div>
      <!-- REGION CARDS -->
      <div class="w-full mlg:w-1/2 flex flex-col gap-5 max-w-[780px]">
        <ng-container *ngFor="let region of regions">
          <app-regions-card [region]="region"></app-regions-card>
        </ng-container>
      </div>
    </article>
  </section>

  <!-- CUSTOMER CAROUSEL -->
  <section class="w-full py-10 px-10 md:px-20 mlg:px-28 lg:px-40">
    <h2 class="text-center text-4xl font-semibold pb-16" transloco="home.customer-say-title"></h2>
    <app-customer-carousel></app-customer-carousel>
  </section>
</main>