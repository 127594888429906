<section class="pt-12 lg:pt-6 lg:px-12">
  <h3 class="text-center md:text-start font-bold text-2xl py-4" transloco="checkout.summary.title"></h3>
  <article class="flex border-b border-greyLight">

    <div class="flex flex-col items-center">
      <img [src]="market | appSquares" alt="country image" class="w-[5rem] h-[5rem] sm:w-[7rem] sm:h-[7rem] rounded-3xl">
      <p class="text-xs underline text-center cursor-pointer py-2" (click)="changePlan()"
        transloco="checkout.summary.change"></p>

    </div>
    <div class="flex flex-1 flex-col pl-4 rtl:pr-4">
      <h6 class="text-grey font-medium">
        <span transloco="checkout.summary.plan"></span>
        <span data-clarity-unmask="true" class="text-black font-semibold" [transloco]="'plan-page.plan-name-' + selectedPlan.simPlanId"></span>
      </h6>
      <ul class="text-grey font-medium text-sm divide-y border-y ">
        <li class="flex py-1">
          <p class="flex flex-1" transloco="checkout.choose-date.sim-quantity"></p>
          <strong class="text-black">{{ simQuantity }} </strong>
        </li>
        <li class="flex py-1">
          <p class="flex flex-1" transloco="checkout.summary.period"></p>
          <span class="relative cursor-pointer" (click)="showPeriodPopover()">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
            <path stroke-linecap="round" stroke-linejoin="round"
              d="m11.25 11.25.041-.02a.75.75 0 0 1 1.063.852l-.708 2.836a.75.75 0 0 0 1.063.853l.041-.021M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Zm-9-3.75h.008v.008H12V8.25Z" />
          </svg>
            <span *ngIf="showPopover" class="absolute right-1 -top-2 text-xs w-40 bg-gray-100 border border-gray-300 rounded shadow-lg p-2 -left-40" transloco="checkout.summary.period-info-tooltip">
            </span>
          </span>
          <strong class="text-black ml-2">{{ simsPerPeriod }}</strong>
        </li>
        <li class="flex py-1">
          <p class="flex flex-1" transloco="checkout.summary.dates"></p>
          <strong class="text-black text-xs">{{ dateForm.get('beginDate').value }} - {{ dateForm.get('endDate').value }}
          </strong>
        </li>
      </ul>
    </div>
  </article>

  <article class="pt-4 pb-2 border-b border-greyLight">
    <ul>
      <li class="flex py-1">
        <p class="flex flex-1 text-grey text-sm" transloco="checkout.summary.subtotal"></p><strong
          class="text-sm">${{selectedPlan?.price}}</strong>
      </li>
      <li class="flex py-1">
        <p class="flex flex-1 text-grey text-sm" transloco="checkout.summary.shipping"></p><strong class="text-sm">${{
          shippingPrice || 0 }} </strong>
      </li>
      <li class="{{ couponPrice === 0 ? 'hidden' : 'flex'}} py-1">
        <p class="flex flex-1 text-grey text-sm" transloco="checkout.summary.coupon"></p><strong class="text-sm">- ${{
          couponPrice }}</strong>
      </li>
      <hr class="pb-2 mt-2">
      <li *ngIf="simType === 'long-term' && simsPerPeriod > 1" class="flex py-1">
        <p class="flex flex-col sm:flex-row flex-1 sm:items-center text-base">
          <strong transloco="checkout.summary.long-term-today-payment"></strong>
          <span class="text-xs sm:px-4" transloco="checkout.summary.long-term-for-three-months"></span>
        </p>
        <strong class="text-base">${{ selectedPlan.price * 3 * simQuantity }}</strong>
      </li>
      <li class="flex py-1">
        <p class="flex flex-col sm:flex-row sm:items-center flex-1 text-xl">
          <strong transloco="checkout.summary.total"></strong>
          <span class="text-xs sm:px-4 " transloco="checkout.summary.price-include-taxes"></span>
        </p>
        <strong class="text-xl">${{ totalPrice() }}</strong>
      </li>
    </ul>
  </article>


</section>