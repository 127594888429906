<main class="mlg:ltr:bg-reload-bg mlg:rtl:bg-reload-bg-rtl bg-cover bg-no-repeat">
  <div class="py-10 px-6 md:px-20 mlg:px-40">
    <section class="bg-title-bg bg-no-repeat md:bg-[center_left_10rem] mlg:bg-[center_left_35rem] py-16 animate-fade-down">
      <h2 class="text-center text-4xl font-semibold" transloco="reload-esim.title"></h2>
    </section>
    
    <section class="w-full lg:w-1/2 py-10 animate-fade-up">
      <h2 class="text-3xl py-5" transloco="reload-esim.second-title"></h2>
      
      <div class="relative w-full py-10 max-w-[600px]">
        <form [formGroup]="reloadEsimForm" (ngSubmit)="onSubmitReload()">
          
          <input type="text" formControlName="phoneNumber" id="simSearchInput" [placeholder]="'reload-esim.input-number' | transloco"
          class="{{ reloadEsimForm.get('phoneNumber').invalid && formSubmitted ? 'border-red' : 'border-greyLight'}} text-lg w-full p-6 pl-9 rounded-full border shadow-xl hover:shadow-lg">
          
          <button type="submit"
          class="absolute top-12 right-0 mx-2 mt-1 flex bg-orange items-center text-white rounded-full px-6 py-3 w-max hover:scale-105 hover:shadow-xl">
          <span class="hidden sm:flex pr-2" transloco="reload-esim.btn"></span>
          <img src="assets/images/icons/arrow-icon.svg" alt="arrow icon" class="w-[2rem] h-[2rem]"></button>
        </form>
      </div>
      
      <article class="bg-[#FCFCFC] flex p-4 rounded-2xl border border-greyLight">
        <img src="assets/images/icons/world-icon.svg" alt="world icon" class="w-[3.5rem] h-[3.5rem]">
        <span class="text-base text-grey pl-4" transloco="reload-esim.info-bought-here"></span>
      </article>
    </section>
  </div>
  
  <app-pop-up *ngIf="showPopUp" [showPopUp]="showPopUp" (closePopUpEvent)="onClosePopUp()" [translate]="popUpMessage">
  </app-pop-up>
  </main>