import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CreditCardComponent } from '../credit-card/credit-card.component';

@Component({
  selector: 'app-profile-payment-method',
  standalone: true,
  imports: [CommonModule, CreditCardComponent],
  templateUrl: './profile-payment-method.component.html',
  styleUrls: ['./profile-payment-method.component.scss']
})
export class ProfilePaymentMethodComponent {
  showCreditCard: boolean = false

  onCloseModal() {
    this.showCreditCard = false
  }
}
