import { Pipe, type PipeTransform } from '@angular/core';

@Pipe({
  name: 'appSliders',
  standalone: true,
})
export class SlidersPipe implements PipeTransform {

  transform(value: string, ): string {

    if (value === 'Itrek' || value === 'Honeymoon' || value === 'Israel_Long_Term' || value === 'SKY2000' || value === 'NCSY' || value === 'Momentum') {
      return 'assets/images/sliders/israel.webp';
    }

    if (value === 'Hamaagal') {
      return 'assets/images/sliders/canada.webp';
    }

    if (value) {
      return 'assets/images/sliders/' + value.toLowerCase().replaceAll(" ", "-") + '.webp';
    } 
    
    return 'null'
  }

}
