import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SettingsService } from '../settings.service';
import { ControllerApiList } from '../models/controllers/ControllerList';
import { lastValueFrom } from 'rxjs';
import { ApiResponse } from '../models/base/ApiResponse';

@Injectable({
  providedIn: 'root'
})
export class ReloadEsimService {
  ticket: string = "";

  constructor(private http:HttpClient, private settings:SettingsService) { }

  async validateEsimPhone<T>(phoneNumber: string) {

    let params = new HttpParams().set('phone_number', phoneNumber);
    
    const res = this.http.get<ApiResponse<T>>(`${this.settings.getGlobusimBaseUrlApi}/${ControllerApiList.GlobusimApi.ValidateReloadPhone}`, { params: params })
    const data = await lastValueFrom(res)
    return data
  }

  async submitReloadOrder(order: any) {
    const headers = new HttpHeaders()
    .set('ticket', order.headers)
    .set('Content-Type', 'application/json;charset=UTF-8')
    .set('Accept', 'application/json, text/javascript, */*')

    const res = this.http.post<any>(`${this.settings.getBaseUrlApi}/${ControllerApiList.WebApi.SubmitReload}`, order.body, { 'headers': headers})
    const data = await lastValueFrom(res)

    return data
  }

  setReloadTicket(ticketValue: string) {
    this.ticket = ticketValue
  }

  getReloadTicket() {
    return this.ticket;
  }
}
