<div id="carouselExampleCaptions" class="relative w-full" data-te-carousel-init data-te-ride="carousel">
  <!--Carousel indicators-->
  <div class="absolute bottom-0 left-0 right-0 z-[2] mx-[15%] mb-4 mt-2 flex list-none justify-center p-0"
    data-te-carousel-indicators>
    <button type="button" 
      data-te-target="#carouselExampleCaptions" 
      data-te-slide-to="0" 
      data-te-carousel-active
      class="mx-[6px] w-6 h-6 sm:w-4 sm:h-4  rounded-full flex-initial cursor-pointer border-0 border-solid border-transparent bg-orange  bg-clip-padding p-0 -indent-[999px] opacity-50 transition-opacity duration-[600ms] ease-[cubic-bezier(0.25,0.1,0.25,1.0)] motion-reduce:transition-none"
      aria-current="true" 
      aria-label="Slide 1"></button>
    <button type="button" 
      data-te-target="#carouselExampleCaptions" 
      data-te-slide-to="1"
      class="mx-[6px] w-6 h-6 sm:w-4 sm:h-4  rounded-full flex-initial cursor-pointer border-0 border-solid border-transparent bg-orange bg-clip-padding p-0 -indent-[999px] opacity-50 transition-opacity duration-[600ms] ease-[cubic-bezier(0.25,0.1,0.25,1.0)] motion-reduce:transition-none"
      aria-label="Slide 2"></button>
    <button type="button" data-te-target="#carouselExampleCaptions" data-te-slide-to="2"
      class="mx-[6px] w-6 h-6 sm:w-4 sm:h-4  rounded-full flex-initial cursor-pointer border-0 border-solid border-transparent bg-orange bg-clip-padding p-0 -indent-[999px] opacity-50 transition-opacity duration-[600ms] ease-[cubic-bezier(0.25,0.1,0.25,1.0)] motion-reduce:transition-none"
      aria-label="Slide 3"></button>
  </div>

  <!--Carousel items-->
  <div class="relative w-full overflow-hidden after:clear-both after:block after:content-[''] mb-2">
    <!--First item-->
    <div
      class="relative float-left -mr-[100%] w-full transition-transform duration-[600ms] ease-in-out motion-reduce:transition-none"
      data-te-carousel-active
      data-te-carousel-fade
      data-te-carousel-item 
      style="backface-visibility: hidden">

      <div class="flex flex-col items-center lg:px-36 w-full">
        <img src="assets/images/flags/spain-flag.svg" class="block w-[6rem] h-[6rem]" alt="country flag icon" />
        <div class="p-6 ">
          <h3 class="text-2xl text-center py-4" transloco="home.customer1-name"></h3>
          <div class="bg-orangeLight text-xs rounded-lg text-orange px-4 py-2 w-max mx-auto"><span transloco="home.customer1-plan"></span></div>
          <p class="text-sm text-grey text-center py-4 mb-4" transloco="home.customer1-description"> </p>
        </div>
      </div>

    </div>
    <!--Second item-->
    <div
      class="relative float-left -mr-[100%] hidden w-full transition-transform duration-[600ms] ease-in-out motion-reduce:transition-none"
      data-te-carousel-fade
      data-te-carousel-item 
      style="backface-visibility: hidden">

      <div class="flex flex-col items-center lg:px-36 w-full">
        <img src="assets/images/flags/united-states-flag.svg" class="block w-[6rem] h-[6rem]" alt="country flag icon" />
        <div class="p-6 ">
          <h3 class="text-xl text-center py-4" transloco="home.customer2-name"></h3>
          <div class="bg-orangeLight text-xs rounded-lg text-orange px-4 py-2 w-max mx-auto"><span transloco="home.customer2-plan"></span></div>
          <p class="text-sm text-grey text-center py-4 mb-4" transloco="home.customer2-description"> </p>
        </div>
      </div>
    </div>
    <!--Third item-->
    <div
      class="relative float-left -mr-[100%] hidden w-full transition-transform duration-[600ms] ease-in-out motion-reduce:transition-none"
      data-te-carousel-fade
      data-te-carousel-item
      style="backface-visibility: hidden">

      <div class="flex flex-col items-center lg:px-36 w-full">
        <img src="assets/images/flags/peru-flag.svg" class="block w-[6rem] h-[6rem]" alt="country flag icon" />
        <div class="p-6 ">
          <h3 class="text-xl text-center py-4" transloco="home.customer3-name"></h3>
          <div class="bg-orangeLight text-xs rounded-lg text-orange px-4 py-2 w-max mx-auto"><span transloco="home.customer3-plan"></span></div>
          <p class="text-sm text-grey text-center py-4 mb-4" transloco="home.customer3-description"></p>
        </div>
      </div>
    </div>
  </div>

  <!--Carousel controls - prev item-->
  <button
    class="hidden sm:flex absolute bottom-0 left-0 top-0 z-[1] w-[15%] items-center justify-center border-0 bg-none p-0 text-center text-orange opacity-50 transition-opacity duration-150 ease-[cubic-bezier(0.25,0.1,0.25,1.0)] hover:text-coral hover:no-underline hover:opacity-90 hover:outline-none focus:text-coral focus:no-underline focus:opacity-90 focus:outline-none motion-reduce:transition-none"
    type="button" data-te-target="#carouselExampleCaptions" data-te-slide="prev">
    <span class="inline-block h-8 w-8">
      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor"
        class="h-12 w-12">
        <path stroke-linecap="round" stroke-linejoin="round" d="M15.75 19.5L8.25 12l7.5-7.5" />
      </svg>
    </span>
    <span
      class="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]">Previous</span>
  </button>
  <!--Carousel controls - next item-->
  <button
    class="hidden sm:flex absolute bottom-0 right-0 top-0 z-[1] w-[15%] items-center justify-center border-0 bg-none p-0 text-center text-orange opacity-50 transition-opacity duration-150 ease-[cubic-bezier(0.25,0.1,0.25,1.0)] hover:text-coral hover:no-underline hover:opacity-90 hover:outline-none focus:text-coral focus:no-underline focus:opacity-90 focus:outline-none motion-reduce:transition-none"
    type="button" data-te-target="#carouselExampleCaptions" data-te-slide="next">
    <span class="inline-block h-8 w-8">
      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor"
        class="h-12 w-12">
        <path stroke-linecap="round" stroke-linejoin="round" d="M8.25 4.5l7.5 7.5-7.5 7.5" />
      </svg>
    </span>
    <span
      class="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]">Next</span>
  </button>
</div>