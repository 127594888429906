import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Market } from 'src/app/services/models/markets.model';
import { Router } from '@angular/router';
import { FlagsPipe } from 'src/app/pipes/flags.pipe';
import { TranslocoModule } from '@ngneat/transloco';
import { MarketPlansService } from '../../../services/market-plans/market-plans.service';
import { GetSimPlansResult } from 'src/app/services/models/market-plans.model';

@Component({
  selector: 'app-regions-card',
  standalone: true,
  imports: [CommonModule, FlagsPipe, TranslocoModule],
  templateUrl: './regions-card.component.html',
  styleUrls: ['./regions-card.component.scss'],
})
export class RegionsCardComponent {
  @Input() region: Market | null = null;
  lowerPrice: number = 0;
  simType: string[] | undefined = [];

  constructor(private router: Router) {}

  ngOnInit(): void {
    this.simType = this.region?.planTypeInfo.map(t=>t.simTypeName) 
  }

  goToMarketPage(): void {
    const type = (this.simType && !this.simType.includes('esim')) ? this.simType[0] : 'esim';
    this.router.navigate(['/get-your-sim', this.region?.groupName.toLowerCase().replaceAll(' ', '-'), type]);
  }
}
