import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormGroup, ReactiveFormsModule, } from '@angular/forms';
import { TranslocoModule } from '@ngneat/transloco';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { ReloadEsimService } from 'src/app/services/reload-esim/reload-esim.service';
import { GetReloadPlanResult  } from '../../services/models/market-plans.model';
import { SubmitOrderService } from 'src/app/services/submit-order/submit-order.service';
import { ApiMessageCode } from 'src/app/services/models/base/ApiMessageCode';
import { PopUpComponent } from 'src/app/core/shared/pop-up/pop-up.component';
import { Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'app-reload-esim-page',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, TranslocoModule, RouterModule, PopUpComponent],
  templateUrl: './reload-esim-page.component.html',
  styleUrls: ['./reload-esim-page.component.scss']
})
export class ReloadEsimPageComponent {
  formSubmitted: boolean = false
  showPopUp: boolean = false
  popUpMessage: string = ""
  reloadEsimForm = this.submitOrderService.submitForm.get('reloadForm') as FormGroup;

  constructor(
    private reloadEsimService: ReloadEsimService,
    private router: Router,
    private route: ActivatedRoute,
    private submitOrderService: SubmitOrderService,
    private titleService: Title,
    private meta: Meta
  ) {}

  ngOnInit(): void {
    this.route.queryParamMap.forEach((e: any) => {
      const phoneNumber = e.params.phoneNumber;
      if (phoneNumber) this.validateEsimPhoneInStore(phoneNumber);
    });
    this.updateMetaTags(
      'Globusim | Reload you eSIM!',
      'esim, sim, data only, connectivity ',
      'Travel the world, stay connected'
    );
  }

  updateMetaTags(title: string, keywords: string, description: string) {
    this.titleService.setTitle(title);
    this.meta.updateTag({ name: 'keywords', content: keywords });
    this.meta.updateTag({ name: 'description', content: description });
    this.meta.updateTag({
      rel: 'canonical',
      href: 'https://globusim.com/reload-esim',
    });
  }
  
  onClosePopUp() {
    this.showPopUp = false;
  }

  onSubmitReload() {
    this.formSubmitted = true
    if (this.reloadEsimForm.valid) {
      this.formSubmitted = false
  
      const phoneNumber = this.reloadEsimForm.get('phoneNumber').value
      this.validateEsimPhoneInStore(phoneNumber);
    }
  }

  async validateEsimPhoneInStore(phoneNumber: string) {
    try {
      const res = await this.reloadEsimService.validateEsimPhone<GetReloadPlanResult>(phoneNumber)

      if (res.isSuccessful) {
        const marketSelected = res.result.groupName
        const types = res.result.plans.map(t=>t.simTypeName) 
        const simTypes = (types && !types.includes('esim')) ? types[0] : 'esim';
        this.router.navigate(['reload-esim', marketSelected, simTypes], {queryParams: {phoneNumber: phoneNumber}});
      } else if (!res.isError) {
        const message = res.messages ? res.messages[0].code : false
        const apiMessageCode = ApiMessageCode.MessageCode
    
        if (message === apiMessageCode.InvalidPhoneNumber || message === apiMessageCode.InvalidSerialNumber  ) {
          this.popUpMessage = "invalid-reload-number"
        } else if (message === apiMessageCode.NumberAlreadyExists) {
          this.formSubmitted = true
          this.popUpMessage = "alreadyInUse-reload-number"
        } else {
          this.popUpMessage = "unknown-problem"
        }
    
        this.showPopUp = true
        console.log(res.messages.join('\n'))
      } else {
        console.log(res.errorMessage)
      }
    } catch (error) {}
  }
}
