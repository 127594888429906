<main class="mlg:ltr:bg-activate-bg mlg:rtl:bg-activate-bg-rtl bg-cover bg-no-repeat">
  <div class="py-10 px-6 md:px-20 mlg:px-40"> 
    <section
      class="bg-title-bg bg-no-repeat md:bg-[center_left_10rem] mlg:bg-[center_left_35rem] py-16 animate-fade-down">
      <h2 class="text-center text-4xl font-semibold" transloco="activate-sim.title"></h2>
    </section>

    <section class="w-full lg:w-1/2 py-10 animate-fade-right">
      <h2 class="text-center sm:text-start text-3xl py-5" transloco="activate-sim.second-title"></h2>

      <div class="relative max-w-[560px] py-10">

        <form [formGroup]="inputForm" (ngSubmit)="onSubmitActivation()">
          <input type="text" formControlName="activateNumber" id="simSearchInput"
            [placeholder]="'activate-sim.input-number' | transloco"
            class="{{ inputForm.get('activateNumber').invalid && formSubmitted ? 'border-red' : 'border-greyLight'}} text-lg w-full py-5 px-6 rounded-full border shadow-xl hover:shadow-lg">

          <button type="submit"
            class="absolute top-11 ltr:right-0 rtl:left-0 mx-2 mt-1 flex rtl:flex-row-reverse bg-orange items-center text-white rounded-full px-6 py-3 w-max hover:scale-105 hover:shadow-xl">
            <span class="hidden sm:flex pr-2" transloco="activate-sim.btn"></span>
            <img src="assets/images/icons/arrow-icon.svg" alt="arrow icon" class="w-[2rem] h-fit">
          </button>
        </form>
      </div>

      <article class="flex flex-col">
        <p class="text-sm text-grey" transloco="activate-sim.info-for-american"></p>
        <p class="text-sm text-grey pt-6 pb-8" transloco="activate-sim.info-for-israeli"></p>
        <div class="bg-[#FCFCFC] flex flex-col sm:flex-row items-center p-4 rounded-2xl border border-greyLight">
          <img src="assets/images/icons/world-icon.svg" alt="world icon" class="w-[3.5rem] h-fit">
          <p class="text-center sm:text-start text-grey px-4 pt-4 sm:pt-0" transloco="activate-sim.info-bought-here">
          </p>
        </div>
      </article>
    </section>

  </div>
  <app-pop-up *ngIf="showPopUp" [showPopUp]="showPopUp" (closePopUpEvent)="onClosePopUp()" [translate]="popUpMessage">
  </app-pop-up>

</main>