import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, lastValueFrom, map } from 'rxjs';
import { ControllerApiList } from '../models/controllers/ControllerList';
import { SettingsService } from '../settings.service';
import { ApiResponse } from '../models/base/ApiResponse';

@Injectable({
  providedIn: 'root',
})
export class MarketService {
  
  constructor(private http: HttpClient, private settings: SettingsService) {}

  async getMarkets<T>(groupPrefix: string = '', lang: string = ''): Promise<ApiResponse<T>> {
    // Filter
    let params = new HttpParams()
      .set('groupPrefix', groupPrefix)
      .set('lang', lang);

    const response = this.http.get<ApiResponse<T>>(
      `${this.settings.getGlobusimBaseUrlApi}/${ControllerApiList.GlobusimApi.GetSimPlanMarket}`, { params: params,}
    );
    const data = await lastValueFrom(response);
    return data;
  }
}
