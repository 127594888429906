import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SettingsService } from '../settings.service';
import { lastValueFrom } from 'rxjs';
import { ControllerApiList } from '../models/controllers/ControllerList';
import { ShipElement, ShipOpt } from '../models/shipping-options.model';

@Injectable({
  providedIn: 'root'
})
export class ShippingOptionsService {

  constructor(private http:HttpClient, private settings:SettingsService) { }

  async getShippingOptions(shipCountry: string) {
    const res = this.http.post<ShipOpt>(`${this.settings.getBaseUrlApi}/${ControllerApiList.WebApi.GetShippingOptions}`, {ShipCountry: shipCountry})
    const data = await lastValueFrom(res)
    return data
  }
}

