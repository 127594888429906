import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Plan } from 'src/app/services/models/market-plans.model';
import { TranslocoModule } from '@ngneat/transloco';

@Component({
  selector: 'app-sims-buttons',
  standalone: true,
  imports: [CommonModule, TranslocoModule],
  templateUrl: './sims-buttons.component.html',
  styleUrls: ['./sims-buttons.component.scss'],
})
export class SimsButtonsComponent {
  @Input() activeButton: string | null = '';
  @Input() simButtonVisible: boolean = true;
  @Input() esimButtonVisible: boolean = true;
  @Input() dataButtonVisible: boolean = true;
  @Input() plans: Plan[] = [];

  @Output() buttonClick = new EventEmitter<string>();

  constructor() {}

  ngOnInit(): void { }
  
  emitButtonClick(buttonType: string): void {
    this.buttonClick.emit(buttonType);
  }
  
  getPlanTypes(): string[] {
    return this.plans ? this.plans.map(plan => plan.simTypeName) : [];
  }
  
  /**
   * @returns the simTypeName buttons available
   * If simTypeName is only one don't show the buttons
   */
  showButtons(): boolean {
    const planTypes = this.getPlanTypes();
    return planTypes.length > 1;
  }
}