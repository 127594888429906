import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SettingsService } from '../settings.service';
import { ApiResponse } from '../models/base/ApiResponse';
import { ControllerApiList } from '../models/controllers/ControllerList';
import { lastValueFrom } from 'rxjs';
import { ISimCustomer } from '../models/sim-customer.model';

@Injectable({
  providedIn: 'root'
})
export class GetSimCustomerService {

  constructor(private http: HttpClient, private settings: SettingsService) {}

  async getSimCustomer(param: any) {

    const response = this.http.post<ISimCustomer>(
      `${this.settings.getBaseUrlApi}/${ControllerApiList.WebApi.GetSimCustomer}`, param );
    const data = await lastValueFrom(response);
    return data;
  }
}
