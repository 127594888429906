<main>
  <section *ngIf="marketSelected" class="bg-cover bg-no-repeat flex flex-col items-center text-white py-6"
    [style.background-image]="'url(' + (marketSelected.groupName | appSliders) + ')'">
    <img [src]="marketSelected.groupName | appFlags" alt="country flag" [ngClass]="{
      'w-[10rem] h-[10rem]': isLargeSizeGroup(marketSelected.groupName),
      'w-[3rem] h-[3rem] rounded-full': !isLargeSizeGroup(marketSelected.groupName)
    }">
    <h2 class="text-center text-3xl font-semibold py-2"
      [transloco]="'markets.' + marketSelected.groupName.toLowerCase().replaceAll(' ', '-') "></h2>
    <!-- <div class="flex text-sm">
      <span class="" transloco="plan-page.subtitle"></span>&nbsp;
      <span class="" [transloco]="'markets.' + marketSelected.groupName.toLowerCase().replaceAll(' ', '-') "></span>
    </div> -->
  </section>

  <section id="plansView" class="w-full p-10 mx-auto text-center">
    <h2 class="text-2xl pb-16 font-semibold" [transloco]="'plan-page.plan-title-' + activeButton"> </h2>
    <!-- SIM TYPE BUTTONS -->
    <app-sims-buttons [activeButton]="activeButton" [plans]="plans" [dataButtonVisible]="dataButtonVisible"
      [esimButtonVisible]="esimButtonVisible" [simButtonVisible]="simButtonVisible"
      (buttonClick)="changeActiveButton($event)"></app-sims-buttons>
    <!-- SKELETON PLANS -->
    <article *ngIf="filteredPlans.length === 0" class="flex flex-wrap justify-center gap-5 ">
      <ng-container *ngFor="let box of skeletonBoxes">
        <div class="bg-greyLight pulse-animation-custom w-72 h-[410px] rounded-3xl">
          <img src="assets/images/logo-s.png" alt="logo small" class="p-12 opacity-60">
        </div>
      </ng-container>
    </article>
    <!-- PLANS -->
    <article *ngIf="filteredPlans.length !== 0" class="flex flex-wrap justify-center gap-5 ">
      <ng-container *ngFor="let plan of filteredPlans">
        <app-plan-card [plan]="plan"></app-plan-card>
      </ng-container>
    </article>

    <article class="bg-[#FCFCFC] flex flex-col sm:flex-row items-center p-4 mt-16 rounded-2xl border border-greyLight">
      <img src="assets/images/icons/info-orange-icon.svg" alt="world icon" class="w-[3rem] h-[3rem]">
      <ul class="text-start list-disc pl-8 rtl:pr-8">
        <li class="text-base text-grey" [innerHTML]="'plan-page.plan-info-first-' + activeButton | transloco"></li>
        <li class="text-base text-grey" [innerHTML]="'plan-page.plan-info-second-' + activeButton | transloco"></li>
        <li *ngIf="selectedCountry === 'united-states'" class="text-base text-grey"
          [innerHTML]="'plan-page.plan-info-third-usaonly' | transloco"></li>
        <li *ngIf="selectedCountry.toLowerCase() === 'europe'" class="text-base text-grey">
          <span [innerHTML]="'plan-page.plan-info-third-europe-' + activeButton | transloco"></span>&nbsp;
          <span transloco="plan-page.plan-info-europe-here-btn" class="text-orange underline cursor-pointer"
            (click)="openPopUp('europe')"></span>
        </li>
        <li *ngIf="selectedCountry.toLowerCase() === 'asia'" class="text-base text-grey">
          <span [innerHTML]="'plan-page.plan-info-third-asia-' + activeButton | transloco"></span>&nbsp;
          <span transloco="plan-page.plan-info-europe-here-btn" class="text-orange underline cursor-pointer"
            (click)="openPopUp('asia')"></span>
        </li>
      </ul>
    </article>
  </section>

  <!-- WHY GLOBUSIM -->
  <section class="w-full py-10 px-10 md:px-20 mlg:px-40 text-center">
    <h2 class="text-3xl pb-16 font-semibold" transloco="plan-page.second-title"> </h2>
    <article class="flex flex-col md:flex-row justify-center">
      <div class="flex flex-col items-center p-4 ">
        <img src="assets/images/icons/why-icon4.svg" alt="why-icon4" class="w-[8rem] md:w-[11rem] h-[11rem]">
        <span class="font-semibold sm:p-4 text-lg" transloco="plan-page.second-item-list-1"> </span>
      </div>
      <div class="flex flex-col items-center p-4">
        <img src="assets/images/icons/why-icon3.svg" alt="why-icon3" class="w-[8rem] md:w-[11rem] h-[11rem]">
        <span class="font-semibold sm:p-4 text-lg" transloco="plan-page.second-item-list-2"> </span>
      </div>
      <div class="flex flex-col items-center p-4">
        <img src="assets/images/icons/why-icon2.svg" alt="why-icon2" class="w-[8rem] md:w-[11rem] h-[11rem]">
        <span class="font-semibold sm:p-4 text-lg" transloco="plan-page.second-item-list-3"> </span>
      </div>
      <div class="flex flex-col items-center p-4">
        <img src="assets/images/icons/why-icon1.svg" alt="why-icon1" class="w-[8rem] md:w-[11rem] h-[11rem]">
        <span class="font-semibold sm:p-4 text-lg" transloco="plan-page.second-item-list-4"> </span>
      </div>
    </article>
  </section>

  <!-- SIMPLY AND FAST -->
  <section class="w-full py-10 px-10 md:px-20 mlg:px-40 text-center">
    <h2 class="text-3xl pb-16 font-semibold" transloco="plan-page.third-title"> </h2>
    <article class="flex flex-col md:flex-row justify-center">
      <div class="flex flex-col items-center p-4 md:w-1/4">
        <p class="w-12 h-12 bg-danger-100 rounded-full font-semibold pt-3">1</p>
        <span class="h-[105px] text-sm font-semibold py-8" transloco="plan-page.third-item-list-1"> </span>
        <img src="assets/images/layers/step1.webp" alt="step example 1" class="flex items-center w-[18rem] h-fit">
      </div>
      <div class="flex flex-col items-center p-4 md:w-1/4">
        <p class="w-12 h-12 bg-danger-100 rounded-full font-semibold pt-3">2</p>
        <span class="h-[105px] text-sm font-semibold py-8" transloco="plan-page.third-item-list-2"> </span>
        <img src="assets/images/layers/step2.webp" alt="step example 2" class="flex items-center w-[18rem] h-fit">
      </div>
      <div class="flex flex-col items-center p-4 md:w-1/4">
        <p class="w-12 h-12 bg-danger-100 rounded-full font-semibold pt-3">3</p>
        <span class="h-[105px] text-sm font-semibold py-8" transloco="plan-page.third-item-list-3"> </span>
        <img src="assets/images/layers/step3.webp" alt="step example 3" class="flex items-center w-[18rem] h-fit">
      </div>
      <div class="flex flex-col items-center p-4 md:w-1/4">
        <p class="w-12 h-12 bg-danger-100 rounded-full font-semibold pt-3">4</p>
        <span class="h-[105px] text-sm font-semibold py-8" transloco="plan-page.third-item-list-4"> </span>
        <img src="assets/images/layers/step4.webp" alt="step example 4" class="flex items-center w-[18rem] h-fit">
      </div>
    </article>
  </section>

  <!-- CUSTOMER SAY -->
  <section class="py-10 px-10 md:px-20 mlg:px-40 ">
    <h2 class="text-center text-3xl font-semibold pb-16" transloco="plan-page.fourth-title"></h2>
    <app-customer-carousel></app-customer-carousel>
  </section>

  <app-pop-up *ngIf="showPopUp" [showPopUp]="showPopUp" (closePopUpEvent)="onClosePopUp()" [translate]="popUpMessage">
  </app-pop-up>
</main>