import { Component, ElementRef, Renderer2, Signal, computed, signal } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CheckoutSummaryComponent } from 'src/app/core/shared/checkout-summary/checkout-summary.component';
import { ActivatedRoute } from '@angular/router';
import { TranslocoModule } from '@ngneat/transloco';
import { SubmitOrderService } from '../../services/submit-order/submit-order.service';
import { FormGroup } from '@angular/forms';
import { GetSimPlanResult, SimPlan } from 'src/app/services/models/market-plans.model';
import { MarketPlansService } from 'src/app/services/market-plans/market-plans.service';
import { SimPriceService } from '../../services/sim-price/sim-price.service';
import { SquaresPipe } from 'src/app/pipes/squares.pipe';
import { SpinnerComponent } from 'src/app/core/shared/spinner/spinner.component';
import { Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'app-order-page',
  standalone: true,
  imports: [CommonModule, CheckoutSummaryComponent, TranslocoModule, SquaresPipe, SpinnerComponent],
  templateUrl: './order-page.component.html',
  styleUrls: ['./order-page.component.scss'],
})
export class OrderPageComponent {
  simType: string = this.route.snapshot.params['simType'];
  confirmationID: number = this.route.snapshot.params['id'];
  shippingPrice: number = this.route.snapshot.params['shipPrice'];
  shipOption: number = this.route.snapshot.params['ship-id'];
  couponPrice: string = this.route.snapshot.params['couponPrice'];
  selectedPlan: SimPlan | undefined = undefined;
  selectedMarket: string = "";
  globalForm = this.submitOrderService.submitForm;
  dateForm = this.submitOrderService.submitForm.get('dateForm') as FormGroup;
  activateForm = this.submitOrderService.submitForm.get('activateForm') as FormGroup;
  totalPrice: Signal<number> = signal(0);
  maxShippingDate: string = ""
  minShippingDate: string = ""
  envelope: string | null = null;
  orderData = this.submitOrderService.getOrderData();

  constructor(
    private route: ActivatedRoute,
    private submitOrderService: SubmitOrderService,
    private marketPlansService: MarketPlansService,
    private simPriceService: SimPriceService,
    private titleService: Title,
    private renderer: Renderer2,
    private el: ElementRef,
    private meta: Meta,
    ) {}
    
    ngOnInit(): void {
      const planID = this.route.snapshot.params['plan-id'];
      this.getSimPlanById(planID);
      const orderPlan = this.submitOrderService.getOrderData()
      if (orderPlan.envelopes) this.envelope = orderPlan.envelopes 

      this.updateMetaTags(
        'Globusim | Thanks for your purchase!',
        'esim, sim, data only, connectivity ',
        'Travel the world, stay connected'
      );

      this.insertLeadDynoScript();
    }
  
    updateMetaTags(title: string, keywords: string, description: string) {
      this.titleService.setTitle(title);
      this.meta.updateTag({ name: 'keywords', content: keywords });
      this.meta.updateTag({ name: 'description', content: description });
      this.meta.updateTag({
        rel: 'canonical',
        href: 'https://globusim.com/order',
      });
    }

  ngOnDestroy(): void {
    this.globalForm.reset()
    this.submitOrderService.clearOrderData()
  }

  insertLeadDynoScript() {
    const mail = this.orderData.email;
    const totalAmount = this.totalPrice();
  
    const scriptContent = `
      LeadDyno.key = "0c4179a11316a1d1eadf5e93186b2aaa823370d2";
      LeadDyno.recordPurchase("${mail}", {
          purchase_amount: ${totalAmount}
      });
    `;
  
    const script = this.renderer.createElement('script');
    script.type = 'text/javascript';
    script.text = scriptContent;
    this.renderer.appendChild(this.el.nativeElement, script);
  }

  async getSimPlanById(simPlanId: number) {
    const res = await this.marketPlansService.getSimPlanById<GetSimPlanResult>(simPlanId);

    this.selectedPlan = res.result.plan;
    this.selectedMarket = res.result.groupName
    this.getSimPrice();
    
    this.totalPrice = computed(() => {
      const planPrice = this.selectedPlan?.price || 0;
      if (this.shippingPrice !== 0) {
        return (
          planPrice * this.dateForm.get('simsPerPeriod').value * this.dateForm.get('simQuantity').value +
          Number(this.shippingPrice) - Number(this.couponPrice));
        } else {
          return (
            planPrice * this.dateForm.get('simsPerPeriod').value * this.dateForm.get('simQuantity').value - Number(this.couponPrice)
            );
      }
    });

    
  }

  async getSimPrice() {
    const orderData = this.submitOrderService.getOrderData();

    const jsonData = {
      BeginDate: this.dateForm.get('beginDate').value,
      EndDate: this.dateForm.get('endDate').value,
      ShipOption: Number(this.shipOption),
      ShipCountry: orderData.country,
      Plan: this.selectedPlan.planName,
      Quantity: this.dateForm.get('simQuantity').value,
      simPhone: this.activateForm.get('phoneNumber').value,
    };

    const res = await this.simPriceService.getSimPrice(jsonData)

    this.maxShippingDate = res.ShippingMaxDate;
    this.minShippingDate = res.ShippingMinDate;
  }


}
