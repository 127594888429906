import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SettingsService } from '../settings.service';
import { ControllerApiList } from '../models/controllers/ControllerList';
import { lastValueFrom } from 'rxjs';
import { ApiResponse } from '../models/base/ApiResponse';

@Injectable({
  providedIn: 'root'
})
export class ActivateSimService {

  constructor(private http:HttpClient, private settings:SettingsService) { }

  async validateActivationPhone<T>(number: string) {

    let params: HttpParams;

    if (number.charAt(0) === '8') {
      params = new HttpParams().set('serial_number', number);
    } else {
      params = new HttpParams().set('phone_number', number);
    }

    const res = this.http.get<ApiResponse<T>>(`${this.settings.getGlobusimBaseUrlApi}/${ControllerApiList.GlobusimApi.ValidateActivationPhone}`, {params: params})

    const data = await lastValueFrom(res)
    return data
  }
}
