<main id="homePage" class="">
  
  <!-- SEARCH COUNTRY -->
  <section class="lg:flex pb-10">
    <article
    class="text-center lg:text-start  bg-dot-bg bg-no-repeat bg-[bottom_left_5rem] w-full lg:w-[45%] m-auto px-6 md:pl-20 mlg:pl-28 lg:pl-40 py-10 lg:py-0 animate-fade-right">
    <h1 class="text-4xl sm:text-[2.875rem] py-5 leading-[3.5rem]">
      <span transloco="home.welcome"></span><br>
      <strong transloco="home.welcome-title-strong"></strong>
    </h1>
    
    <span class=" text-grey" transloco="home.description"></span>
    
    <div class=" w-full xl:w-[90%] mx-auto lg:mx-0">
      <div class="relative w-full pt-10 transition delay-150 duration-300 ease-in-out hover:scale-105 ">
        <input #inputSearch id="inputSearch" [placeholder]="'search-bar.placeholder' | transloco"
        (click)="showModal = true"
        class="w-full p-6 px-14 rounded-full border border-greyLight shadow-xl hover:shadow-2xl outline-none">
        <img src="assets/images/icons/location.svg" alt="location icon"
        class="absolute top-14 pt-2 ltr:left-4 rtl:right-4 w-[2rem] h-[2rem] ">
        <img src="assets/images/icons/search-icon.svg" alt="search icon"
        class="absolute top-12 pt-1 ltr:right-0 rtl:left-0 w-[3.5rem] h-[3.5rem] mx-2">
      </div>
    </div>
  </article>
  
    <div *ngIf="showModal">
      <app-modal-search-input (closeModalEvent)="onCloseModal()" [showModal]="showModal"></app-modal-search-input>
    </div>

    <div class="w-full lg:w-[55%] animate-fade-left animate-duration-[1500ms]">
      <img [lazyLoad]="viewport > 480 ? image : imageMobile" alt="home background"
        class="w-full h-full rtl:scale-x-[-1] aspect-auto">     
    </div>
  </section>

  <!-- WHERE TO TRAVEL TO -->
  <app-home-where-to-travel [countries]="visibleCountries"></app-home-where-to-travel>

  <!-- WHY GLOBUSIM -->
  <app-home-why-globusim></app-home-why-globusim>

  <!-- HOW TO USE IT -->
  <app-home-how-to-use></app-home-how-to-use>

  <!-- CUSTOMER SAY -->
  <section class="w-full py-10 px-6 md:px-20 mlg:px-28 lg:px-40">
    <h2 class="text-center text-4xl font-semibold pb-16" transloco="home.customer-say-title"></h2>
    <app-customer-carousel></app-customer-carousel>
    <article class="hidden lg:flex justify-center w-full py-12">
      <div class="flex flex-col items-center">
        <p class="text-3xl font-semibold px-4" transloco="home.customers-count"></p>
        <span class="text-sm text-grey" transloco="home.customers-count-description"></span>
      </div>
    </article>
  </section>

  <!-- FAQ -->
  <section class="w-full py-10 px-6 md:px-20 mlg:px-28 lg:px-40">
    <h2 transloco="faq.faq-title"
      class="bg-title-bg bg-no-repeat md:bg-[center_left_10rem] mlg:bg-[center_left_35rem] text-center text-4xl font-semibold py-16"></h2>
    <article
      class="p-2 md:p-6 md:px-20 md:py-10 rounded-2xl shadow-xl border border-greyLight mt-10 scroll-driven-show">
      <app-faq-accordion faqSrc="Home"></app-faq-accordion>
    </article>
  </section>

  <!-- HOW ESIM WORKS -->
  <app-home-how-esim-work></app-home-how-esim-work>

</main>