import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslocoModule } from '@ngneat/transloco';
import { RouterModule } from '@angular/router';

@Component({
  selector: 'app-instructions-esim-android',
  standalone: true,
  imports: [CommonModule, TranslocoModule, RouterModule],
  templateUrl: './instructions-esim-android.component.html',
  styleUrls: ['./instructions-esim-android.component.scss']
})
export class InstructionsEsimAndroidComponent {

}
