<section class="lg:py-8 lg:px-14">
  <h3 class="text-center font-bold text-2xl py-4" transloco="checkout.payment.title-1"></h3>
  <!-- SELECT PAYMENT METHOD -->
  <article class="flex justify-center py-4 sm:gap-20 mb-8">
    <div class="">
      <label class="flex items-center checkbox-container mr-2 cursor-pointer">
        <input (change)="togglePaymentMethod('card')" [checked]="selectedMethod === 'card'"
          class="float-left mx-3 my-auto h-[1.125rem] w-[1.125rem] appearance-none rounded-full border-[0.125rem] border-solid border-neutral-300 outline-none before:pointer-events-none before:absolute before:h-[0.875rem] before:w-[0.875rem] before:scale-0 before:rounded-full before:bg-transparent before:opacity-0 before:shadow-[0px_0px_0px_13px_transparent] before:content-[''] checked:border-orange checked:bg-orange checked:before:opacity-[0.16] checked:after:absolute checked:after:-mt-px checked:after:ml-[0.25rem] checked:after:block checked:after:h-[0.8125rem] checked:after:w-[0.375rem] checked:after:rotate-45 checked:after:border-[0.125rem] checked:after:border-l-0 checked:after:border-t-0 checked:after:border-solid checked:after:border-white checked:after:bg-transparent checked:after:content-[''] hover:cursor-pointer hover:before:opacity-[0.04] hover:before:shadow-[0px_0px_0px_13px_rgba(0,0,0,0.6)] focus:shadow-none focus:transition-[border-color_0.2s] focus:before:scale-100 focus:before:opacity-[0.12] focus:before:shadow-[0px_0px_0px_13px_rgba(0,0,0,0.6)] focus:before:transition-[box-shadow_0.2s,transform_0.2s] focus:after:absolute focus:after:z-[1] focus:after:block focus:after:h-[0.875rem] focus:after:w-[0.875rem] focus:after:rounded-[0.125rem] focus:after:content-[''] checked:focus:before:scale-100 checked:focus:before:shadow-[0px_0px_0px_13px_#3b71ca] checked:focus:before:transition-[box-shadow_0.2s,transform_0.2s] checked:focus:after:-mt-px checked:focus:after:ml-[0.25rem] checked:focus:after:h-[0.8125rem] checked:focus:after:w-[0.375rem] checked:focus:after:rotate-45 checked:focus:after:rounded-none checked:focus:after:border-[0.125rem] checked:focus:after:border-l-0 checked:focus:after:border-t-0 checked:focus:after:border-solid checked:focus:after:border-white checked:focus:after:bg-transparent"
          type="checkbox" value="" id="card" />
        <img src="assets/images/icons/credit-cards.svg" alt="Credit Card" class="w-[2.5rem] h-fit">
      </label>
    </div>

    <div class="">
      <label class="flex items-center checkbox-container mr-2 cursor-pointer">
        <input (change)="togglePaymentMethod('paypal')" [checked]="selectedMethod === 'paypal'"
          class="float-left mx-3 my-auto h-[1.125rem] w-[1.125rem] appearance-none rounded-full border-[0.125rem] border-solid border-neutral-300 outline-none before:pointer-events-none before:absolute before:h-[0.875rem] before:w-[0.875rem] before:scale-0 before:rounded-full before:bg-transparent before:opacity-0 before:shadow-[0px_0px_0px_13px_transparent] before:content-[''] checked:border-orange checked:bg-orange checked:before:opacity-[0.16] checked:after:absolute checked:after:-mt-px checked:after:ml-[0.25rem] checked:after:block checked:after:h-[0.8125rem] checked:after:w-[0.375rem] checked:after:rotate-45 checked:after:border-[0.125rem] checked:after:border-l-0 checked:after:border-t-0 checked:after:border-solid checked:after:border-white checked:after:bg-transparent checked:after:content-[''] hover:cursor-pointer hover:before:opacity-[0.04] hover:before:shadow-[0px_0px_0px_13px_rgba(0,0,0,0.6)] focus:shadow-none focus:transition-[border-color_0.2s] focus:before:scale-100 focus:before:opacity-[0.12] focus:before:shadow-[0px_0px_0px_13px_rgba(0,0,0,0.6)] focus:before:transition-[box-shadow_0.2s,transform_0.2s] focus:after:absolute focus:after:z-[1] focus:after:block focus:after:h-[0.875rem] focus:after:w-[0.875rem] focus:after:rounded-[0.125rem] focus:after:content-[''] checked:focus:before:scale-100 checked:focus:before:shadow-[0px_0px_0px_13px_#3b71ca] checked:focus:before:transition-[box-shadow_0.2s,transform_0.2s] checked:focus:after:-mt-px checked:focus:after:ml-[0.25rem] checked:focus:after:h-[0.8125rem] checked:focus:after:w-[0.375rem] checked:focus:after:rotate-45 checked:focus:after:rounded-none checked:focus:after:border-[0.125rem] checked:focus:after:border-l-0 checked:focus:after:border-t-0 checked:focus:after:border-solid checked:focus:after:border-white checked:focus:after:bg-transparent"
          type="checkbox" value="" id="paypal" />
        <img src="assets/images/icons/pay-pal.svg" alt="PayPal" class="w-[6rem] h-fit">
      </label>
    </div>

  </article>

  <!-- CARD FORM -->
  <div *ngIf="selectedMethod === 'card'">
    <article class="py-4">
      <form [formGroup]="paymentForm" (ngSubmit)="onSubmitPayment()">
        <!--Name input-->
        <div class="mb-6">
          <label for="cardName" class=" mb-0 max-w-[90%] pt-[0.37rem] leading-[1.6] font-medium" transloco="checkout.payment.card-name"></label>
          <input type="text" formControlName="cardName"
            class="{{paymentForm.get('cardName')?.invalid && formSubmitted ? 'border-danger': 'border-greyDark'}} block min-h-[auto] w-full rounded border bg-transparent px-3 py-[0.32rem] leading-[1.6] outline-none focus:border-grey"
            id="cardName" placeholder="" />

        </div>

        <!--Number input-->
        <div class="mb-6">
          <label for="cardNumber" class=" mb-0 max-w-[90%] pt-[0.37rem] leading-[1.6] font-medium" transloco="checkout.payment.card-number"></label>
          <input type="text" formControlName="cardNumber" pattern="[0-9]{13,16}"
            class="{{paymentForm.get('cardNumber')?.invalid && formSubmitted ? 'border-danger': 'border-greyDark'}} block min-h-[auto] w-full rounded border bg-transparent px-3 py-[0.32rem] leading-[1.6] outline-none focus:border-grey"
            id="cardNumber" placeholder="XXXX-XXXX-XXXX-XXXX" />
        </div>

        <!-- validate & CVV -->
        <div class="flex gap-8">
          <div class="mb-6 w-2/3">
            <label for="cardExpiration"
              class="mb-0 max-w-[90%] pt-[0.37rem] leading-[1.6] font-medium" transloco="checkout.payment.card-exp-date"></label>
            <div class="flex gap-2">

              <select formControlName="cardExpirationMM" placeholder="MM" name='expireMM' id='expireMM'
                class="{{paymentForm.get('cardExpirationMM')?.invalid && formSubmitted ? 'border-danger': 'border-greyDark'}} block min-h-[auto] w-1/2 rounded border bg-transparent px-3 py-[0.32rem] leading-[1.6] outline-none focus:border-grey">
                <option value='' selected disabled class="text-greyDark" transloco="checkout.payment.card-exp-month"></option>
                <option *ngFor="let month of months" [value]="month">{{ month }}</option>
              </select>

              <select formControlName="cardExpirationYY" placeholder="YY" name='expireYY' id='expireYY'
                class="{{paymentForm.get('cardExpirationYY')?.invalid && formSubmitted ? 'border-danger': 'border-greyDark'}} block min-h-[auto] w-1/2 rounded border bg-transparent px-3 py-[0.32rem] leading-[1.6] outline-none focus:border-grey">
                <option value='' selected disabled class="text-greyDark" transloco="checkout.payment.card-exp-year"></option>
                <option *ngFor="let year of yearsToShow" [value]="year">{{ year }}</option>
              </select>
            </div>
          </div>

          <div class="mb-6 w-1/2">
            <label for="cardCVV"
              class="mb-0 max-w-[90%] pt-[0.37rem] leading-[1.6] font-medium" transloco="checkout.payment.card-cvv"></label>
            <input type="text" formControlName="cardCVV"
              class="{{paymentForm.get('cardCVV')?.invalid && formSubmitted ? 'border-danger': 'border-greyDark'}} block min-h-[auto] w-full rounded border bg-transparent px-3 py-[0.32rem] leading-[1.6] outline-none focus:border-grey"
              id="cardCVV" placeholder="" />
          </div>
        </div>
      </form>
    </article>

    <!-- SELECT DIFFERENT BILLING  -->
    <div *ngIf="!isEsim && !fxdCustomerPlan && !isReloadEsim" class="mb-6">
      <label class="flex items-center checkbox-container mr-2 text-sm cursor-pointer">
      <input
        class="float-left mr-3 my-auto h-[1.125rem] w-[1.125rem] appearance-none rounded-full border-[0.125rem] border-solid border-neutral-300 outline-none before:pointer-events-none before:absolute before:h-[0.875rem] before:w-[0.875rem] before:scale-0 before:rounded-full before:bg-transparent before:opacity-0 before:shadow-[0px_0px_0px_13px_transparent] before:content-[''] checked:border-orange checked:bg-orange checked:before:opacity-[0.16] checked:after:absolute checked:after:-mt-px checked:after:ml-[0.25rem] checked:after:block checked:after:h-[0.8125rem] checked:after:w-[0.375rem] checked:after:rotate-45 checked:after:border-[0.125rem] checked:after:border-l-0 checked:after:border-t-0 checked:after:border-solid checked:after:border-white checked:after:bg-transparent checked:after:content-[''] hover:cursor-pointer hover:before:opacity-[0.04] hover:before:shadow-[0px_0px_0px_13px_rgba(0,0,0,0.6)] focus:shadow-none focus:transition-[border-color_0.2s] focus:before:scale-100 focus:before:opacity-[0.12] focus:before:shadow-[0px_0px_0px_13px_rgba(0,0,0,0.6)] focus:before:transition-[box-shadow_0.2s,transform_0.2s] focus:after:absolute focus:after:z-[1] focus:after:block focus:after:h-[0.875rem] focus:after:w-[0.875rem] focus:after:rounded-[0.125rem] focus:after:content-[''] checked:focus:before:scale-100 checked:focus:before:shadow-[0px_0px_0px_13px_#3b71ca] checked:focus:before:transition-[box-shadow_0.2s,transform_0.2s] checked:focus:after:-mt-px checked:focus:after:ml-[0.25rem] checked:focus:after:h-[0.8125rem] checked:focus:after:w-[0.375rem] checked:focus:after:rotate-45 checked:focus:after:rounded-none checked:focus:after:border-[0.125rem] checked:focus:after:border-l-0 checked:focus:after:border-t-0 checked:focus:after:border-solid checked:focus:after:border-white checked:focus:after:bg-transparent"
        type="checkbox" [(ngModel)]="isBillingAddress" (change)="onBillingAddressChanged()" value="" id="card" />
        <span transloco="checkout.payment.different-address"></span>
      </label>
    </div>

    <!-- BILLING FORM -->
    <article class="py-4" *ngIf="isBillingAddress">
      <form [formGroup]="shipAddress" (ngSubmit)="onSubmitPayment()">
        <!--Email input-->
        <div class="mb-4">
          <label for="email" class="mb-0 max-w-[90%] pt-[0.37rem] leading-[1.6] font-medium" transloco="checkout.billing.form-email"></label>
          <input type="email" formControlName="email"
            class="{{shipAddress.get('email')?.invalid && formSubmitted ? 'border-danger': 'border-greyDark'}} block min-h-[auto] w-full rounded border bg-transparent px-3 py-[0.32rem] leading-[1.6] outline-none focus:border-grey"
            id="email" />
        </div>
        <!--Address input-->
        <div class="mb-4">
          <label for="address" class="mb-0 max-w-[90%] pt-[0.37rem] leading-[1.6] font-medium" transloco="checkout.billing.form-address"></label>
          <input type="text" formControlName="address"
            class="{{shipAddress.get('address')?.invalid && formSubmitted ? 'border-danger': 'border-greyDark'}} block min-h-[auto] w-full rounded border bg-transparent px-3 py-[0.32rem] leading-[1.6] outline-none focus:border-grey"
            id="address" />
        </div>

        <!-- Country & State inputs -->
        <div class="flex gap-8">
          <!-- COUNTRY FIELD -->
          <div class="mb-4 w-1/2">
            <label for="country" class="mb-0 max-w-[90%] pt-[0.37rem] leading-[1.6] font-medium" transloco="checkout.billing.form-country"></label>
            <select type="text" formControlName="country" (change)="onCountryChange($event)"
              class="{{shipAddress.get('country')?.invalid && formSubmitted ? 'border-danger': 'border-greyDark'}} block min-h-[auto] w-full rounded border bg-transparent px-3 py-[0.32rem] leading-[1.6] outline-none focus:border-grey"
              id="country">
              <ng-container *ngFor="let country of countries">
                <option class="" value={{country.countryID}}>{{country.countryName}}</option>
              </ng-container>
            </select>
          </div>
          <!-- STATE FIELD -->
          <div class="mb-4 w-1/2">
            <label for="state" class="mb-0 max-w-[90%] pt-[0.37rem] leading-[1.6] font-medium" transloco="checkout.billing.form-state"></label>
            <select type="text" formControlName="state"
              class="{{shipAddress.get('state')?.invalid && formSubmitted ? 'border-danger': 'border-greyDark'}} block min-h-[auto] w-full rounded border bg-transparent px-3 py-[0.32rem] leading-[1.6] outline-none focus:border-grey"
              id="state">
              <ng-container *ngFor="let state of states">
                <option class="" value={{state.stateID}}>{{state.state}}</option>
              </ng-container>
            </select>

          </div>
        </div>

        <!-- City & zip inputs -->
        <div class="flex gap-8">
          <!-- CITY FIELD -->
          <div class="mb-4 w-1/2">
            <label for="city" class="mb-0 max-w-[90%] pt-[0.37rem] leading-[1.6] font-medium" transloco="checkout.billing.form-city"></label>
            <input type="text" formControlName="city" placeholder=""
              class="{{shipAddress.get('city')?.invalid && formSubmitted ? 'border-danger': 'border-greyDark'}} block min-h-[auto] w-full rounded border bg-transparent px-3 py-[0.32rem] leading-[1.6] outline-none focus:border-grey"
              id="city" />
          </div>
          <!-- ZIP CODE FIELD -->
          <div class="mb-4 w-1/2">
            <label for="zipCode" class="mb-0 max-w-[90%] pt-[0.37rem] leading-[1.6] font-medium" transloco="checkout.billing.form-zip-code"></label>
            <input type="text" formControlName="zipCode"
              class="{{shipAddress.get('zipCode')?.invalid && formSubmitted ? 'border-danger': 'border-greyDark'}} block min-h-[auto] w-full rounded border bg-transparent px-3 py-[0.32rem] leading-[1.6] outline-none focus:border-grey"
              id="zipCode" />
          </div>
        </div>
      </form>
    </article>

  </div>

  <!-- PAY PAL BUTTON -->
  <div *ngIf="selectedMethod === 'paypal'">
    <ngx-paypal [config]="payPalConfig"></ngx-paypal>
  </div>


</section>