<main class="instructions py-10 px-6 md:px-16 mlg:px-36">
  <section class="flex flex-col items-center py-8">
    <h1 class="text-4xl font-semibold" transloco="instructions.title.android"></h1>
    <span class="text-xl" transloco="instructions.subtitle"></span>
  </section>

  <section [innerHTML]="'instructions.steps.activate-android' | transloco">

  </section>
  <section [innerHTML]="'instructions.steps.access-android' | transloco">

  </section>
  <section [innerHTML]="'instructions.steps.setup-android' | transloco">

  </section>

  <section [innerHTML]="'instructions.note-android' | transloco">

  </section>

  <button>
    <span transloco="instructions.link-to-iphone" routerLink="/instructions-esim-iphone" >Link to iphone instructions</span>
  </button>
</main>