import { HttpClient } from "@angular/common/http";
import { inject, Injectable } from "@angular/core";
import { Translation, TranslocoLoader } from "@ngneat/transloco";
import { SettingsService } from './services/settings.service';
import { catchError, of } from "rxjs";

@Injectable({ providedIn: "root" })
export class TranslocoHttpLoader implements TranslocoLoader {
  private http = inject(HttpClient);

  constructor(private settings: SettingsService) {}

  getTranslation(lang: string) {
    return this.http.get<Translation>(`${this.settings.getGlobusimBaseUrlApi}/api/globusim/get-translation/${lang}`).pipe(
      catchError(error => {
        console.error('Error getting the translation:', error);
        // Return English JSON in case of error
        return this.http.get<Translation>(`./assets/i18n/en.json`);
      })
    );
  }
}
