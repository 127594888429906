<div
  class="fixed inset-0 bg-white bg-opacity-70 flex justify-center pt-20 sm:pt-[130px] z-[1000] transition-opacity duration-500 ease-in-out">

  <div class="mx-8 animate-fade">
    <section class="relative bg-white rounded-3xl border border-greyDark p-6 sm:p-12 shadow-box mlg:min-w-[440px]">
      <span (click)="closeModal()" class="cursor-pointer">
        <svg xmlns="http://www.w3.org/2000/svg" fill="orange" viewBox="0 0 24 24" stroke-width="1.5" stroke="white"
          class="w-10 h-10 absolute top-0 right-0">
          <path stroke-linecap="round" stroke-linejoin="round"
            d="m9.75 9.75 4.5 4.5m0-4.5-4.5 4.5M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
        </svg>
      </span>
      <img src="assets/images/logo.webp" alt="logo globusim" class="w-24 sm:w-32 mx-auto sm:pb-8">

      <h2 class="font-bold text-blue text-center text-xl sm:text-3xl pt-6 border-b border-blue" [transloco]="'pickup.store_name_' + storeData.locationID"></h2>
      <article class="py-8 flex flex-col items-center">
      <div class="flex flex-col md:flex-row">
        <div class="pb-4 sm:pb-8 sm:px-6">
          <h4 class="font-semibold text-center sm:text-xl" transloco="pickup.open-hours"></h4>
            <p class="flex flex-col text-center text-sm " [transloco]="'pickup.store_day1_hours_' + storeData.locationID"></p>
            <p class="flex flex-col text-center text-sm " [transloco]="'pickup.store_day2_hours_' + storeData.locationID"></p>
            <p class="flex flex-col text-center text-sm " [transloco]="'pickup.store_day3_hours_' + storeData.locationID"></p>
            <p class="flex flex-col text-center text-sm " [transloco]="'pickup.store_day4_hours_' + storeData.locationID"></p>
            <p class="flex flex-col text-center text-sm " [transloco]="'pickup.store_day5_hours_' + storeData.locationID"></p>
            <p class="flex flex-col text-center text-sm " [transloco]="'pickup.store_day6_hours_' + storeData.locationID"></p>
            <p class="flex flex-col text-center text-sm " [transloco]="'pickup.store_day7_hours_' + storeData.locationID"></p>

        </div>
        <div class="pb-4 sm:pb-8 sm:px-6">
          <h4 class="font-semibold text-center sm:text-xl" transloco="pickup.address"></h4>
          <p class="text-center text-sm" [transloco]="'pickup.store_address_' + storeData.locationID"></p>
        </div>
        <div class="pb-4 sm:pb-8 sm:px-6">
          <h4 class="font-semibold text-center sm:text-xl" transloco="pickup.phone"></h4>
            <p class="text-center text-sm sm:text-base" [transloco]="'pickup.store_phone_' + storeData.locationID"></p>
        </div>
      </div>
        <div class="">
            <p class="text-center text-sm sm:text-base" [transloco]="'pickup.store_comment_' + storeData.locationID">
            </p> 
        </div>
      </article>

      <div class="flex justify-center">
        <ng-container *ngIf="addressForm.get('country').value === '101'">
          <button (click)="getEnvelope(storeData.locationID)"
            class="bg-orange py-3 px-12 rounded-full text-white uppercase font-semibold hover:scale-105 animate-fade-up"
            transloco="pickup.confirm-btn"></button>
        </ng-container>
        <ng-container *ngIf="addressForm.get('country').value !== '101'">
          <button (click)="goToAmericanSubmit()"
          class="bg-orange py-3 px-12 rounded-full text-white uppercase font-semibold hover:scale-105 animate-fade-up"
          transloco="pickup.confirm-btn"></button>
        </ng-container>
        </div>
    </section>
  </div>
</div>