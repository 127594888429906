import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { Router } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { Market } from 'src/app/services/models/markets.model';
import { MarketService } from 'src/app/services/markets/markets.service';
import { FlagsPipe } from 'src/app/pipes/flags.pipe';
import { TranslocoModule, TranslocoService } from '@ngneat/transloco';

@Component({
  selector: 'app-modal-search-input',
  standalone: true,
  imports: [CommonModule, FormsModule, FlagsPipe, TranslocoModule],
  templateUrl: './modal-search-input.component.html',
  styleUrls: ['./modal-search-input.component.scss'],
})
export class ModalSearchInputComponent {
  @ViewChild('simSearchInput') simSearchInput!: ElementRef;
  @Input() showModal: boolean = false;
  @Output() closeModalEvent: EventEmitter<void> = new EventEmitter<void>();
  regions: Market[] = [];
  countries: Market[] = [];
  searchValue: string = '';
  currentLang: string = "";

  constructor(
    private router: Router,
    private marketService: MarketService,
    private translocoService: TranslocoService
  ) {}

  ngOnInit(): void {
    this.currentLang = this.translocoService.getActiveLang()
  }

  async getMarkets(market: string, lang: string) {

    try {
      const res = await this.marketService.getMarkets<Market[]>(market, lang)
      const uniqueCountries = new Set<Market>();
      const uniqueRegions = new Set<Market>();


      res.result.forEach((item) => {
        if (item.activeInSearch) {
          if (item.groupLevel === 2) uniqueCountries.add(item);         
          if (item.groupLevel === 1) uniqueRegions.add(item);
        }
      });

      this.countries = Array.from(uniqueCountries);
      this.regions = Array.from(uniqueRegions);
    } catch (err: any) {
      console.error('ERROR: ', err);
    }
  }
  
  searchInputEvent(event: Event) {
    this.countries = []
    this.regions = []
    this.getMarkets(this.searchValue, this.currentLang)
  }
  
  closeModal() {
    this.showModal = false;
    this.closeModalEvent.emit();
  }
  
  ngAfterViewInit() {
    this.simSearchInput.nativeElement.focus();
  }
  
  goToMarketPage(market: Market) {
    const type = market?.planTypeInfo.map(t=>t.simTypeName)
    const simType = (type && !type.includes('esim')) ? type[0] : 'esim';
    this.router.navigate(['/get-your-sim', market.groupName.toLowerCase().replaceAll(" ", "-"), simType]);
  }

  @HostListener('document:click', ['$event'])
  onClickOutside(event: any) {
    if (event?.target?.id !== 'inputSearch') {
      this.closeModal();
    }
  }

  @HostListener('document:keydown', ['$event'])
  onKeyDown(event: KeyboardEvent) {
    if (event.key === 'Escape') {
      this.closeModal();
    }
  }

}
