<section
  class="bg-white lg:bg-why-bg bg-no-repeat bg-cover flex flex-col lg:flex-row w-full py-10 px-6 md:px-20 mlg:px-28 lg:px-40">
  <article class="text-center lg:text-start flex flex-col w-full lg:w-1/3 pb-8 my-auto scroll-driven-show">
    <h2 class="text-4xl sm:text-[2.875rem] font-semibold leading-[3.5rem]" transloco="home.why-choose-title"></h2>
    <span class="py-12 text-grey" transloco="home.why-choose-subtitle"></span>
  </article>

  <article class="flex flex-col md:flex-row w-full lg:justify-center">
    <div class="flex flex-col gap-8 md:mt-16 mx-auto lg:mx-4">
      <div
        class="bg-white flex flex-col items-center shadow-xl border border-greyLight rounded-[40px] p-6 xl:p-12 md:w-80 max-w-[320px] scroll-driven-l-to-r">
        <img src="assets/images/icons/why-icon4.svg" alt="icon sim" class="w-[7rem] h-[7rem]" loading="lazy"/>
        <p class="font-semibold text-lg text-center py-8" transloco="home.card-sim-title"></p>
        <span class="text-sm text-grey text-center" transloco="home.card-sim-description"></span>
      </div>

      <div
        class="bg-white flex flex-col items-center shadow-xl border border-greyLight rounded-[40px] p-6 xl:p-12 md:w-80 max-w-[320px] scroll-driven-l-to-r">
        <img src="assets/images/icons/why-icon2.svg" alt="icon sim" class="w-[7rem] h-[7rem]" loading="lazy"/>
        <p class="font-semibold text-lg text-center py-8" transloco="home.card-24/7-title"></p>
        <span class="text-sm text-grey text-center" transloco="home.card-24/7-description"></span>
      </div>
    </div>

    <div class="flex flex-col gap-8 mt-8 md:mt-0 md:ml-8 mx-auto lg:mx-4">
      <div
        class="bg-white flex flex-col items-center shadow-xl border border-greyLight rounded-[40px] p-6 xl:p-12 md:w-80 max-w-[320px] scroll-driven-r-to-l">
        <img src="assets/images/icons/why-icon3.svg" alt="icon sim" class="w-[7rem] h-[7rem]" loading="lazy"/>
        <p class="font-semibold text-lg text-center py-8" transloco="home.card-wallet-title"></p>
        <span class="text-sm text-grey text-center" transloco="home.card-wallet-description"></span>
      </div>

      <div
        class="bg-white flex flex-col items-center shadow-xl border border-greyLight rounded-[40px] p-6 xl:p-12 md:w-80 max-w-[320px] scroll-driven-r-to-l">
        <img src="assets/images/icons/why-icon1.svg" alt="icon sim" class="w-[7rem] h-[7rem]" loading="lazy"/>
        <p class="font-semibold text-lg text-center py-8" transloco="home.card-signal-title"></p>
        <span class="text-sm text-grey text-center" transloco="home.card-signal-description"></span>
      </div>
    </div>
  </article>
</section>