<section class="flex mx-auto justify-around flex-wrap w-full max-w-[1500px]">

  <!-- STEP 1 -->
  <article class="{{ currentStep === 1 ? 'flex' : 'hidden sm:flex'}} sm:pb-4 flex-col lg:flex-row items-center xl:py-8 xl:px-8">  
    <ng-container *ngIf="currentStep === 1">
      <img src="assets/images/icons/info-orange-icon.svg" alt="info icon orange" class="w-[2.5] h-fit mlg:w-[4rem]">
    </ng-container>
    <ng-container *ngIf="currentStep > 1">
      <button (click)="onPrevStep()">
        <img src="assets/images/icons/info-green-icon.svg" alt="info icon green" class="w-[2.5] h-fit mlg:w-[4rem]">
      </button>
    </ng-container>

    <div class="text-center lg:text-start p-4">
      <span class="text-sm lg:text-base uppercase" transloco="checkout.step-one"></span>
      <p class="font-bold" transloco="checkout.choose-date.step-name"></p>
    </div>
  </article>

  <!-- STEP 2 -->
  <article class="{{ currentStep === 2 ? 'flex' : 'hidden sm:flex'}} sm:pb-4 flex-col lg:flex-row items-center xl:py-8 xl:px-8 ">
    <ng-container *ngIf="currentStep === 2">
      <img src="assets/images/icons/billing-orange-icon.svg" alt="billing icon" class="w-[2.5] h-fit mlg:w-[4rem]">
    </ng-container>
    <ng-container *ngIf="currentStep < 2">
      <button (click)="onPrevStep()">
        <img src="assets/images/icons/billing-grey-icon.svg" alt="billing icon" class="w-[2.5] h-fit mlg:w-[4rem]">
      </button>
    </ng-container>
    <ng-container *ngIf="currentStep > 2">
      <button (click)="onPrevStep()">
        <img src="assets/images/icons/billing-green-icon.svg" alt="billing icon" class="w-[2.5] h-fit mlg:w-[4rem]">
      </button>
    </ng-container>

    <div class="text-center lg:text-start p-4">
      <span class="text-sm lg:text-base uppercase" transloco="checkout.step-two"></span>
      <p class="font-bold" transloco="checkout.billing.step-name"></p>
    </div>
  </article>

  <!-- STEP 3 -->
  <article class="{{ currentStep === 3 ? 'flex' : 'hidden sm:flex'}} sm:pb-4 flex-col lg:flex-row items-center xl:py-8 xl:px-8">
    <ng-container *ngIf="currentStep === 3">
      <img src="assets/images/icons/payment-orange-icon.svg" alt="payment icon" class="w-[2.5] h-fit mlg:w-[4rem]">
    </ng-container>
    <ng-container *ngIf="currentStep < 3">
      <img src="assets/images/icons/payment-grey-icon.svg" alt="payment icon" class="w-[2.5] h-fit mlg:w-[4rem]">
    </ng-container>

    <div class="text-center lg:text-start p-4">
      <span class="text-sm lg:text-base uppercase" transloco="checkout.step-three"></span>
      <p class="font-bold" transloco="checkout.payment.step-name"></p>
    </div>
  </article>
</section>