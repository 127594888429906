import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Router, RouterModule } from '@angular/router';
import { Market } from 'src/app/services/models/markets.model';
import { TranslocoModule } from '@ngneat/transloco';
import { MarketPlansService } from '../../../services/market-plans/market-plans.service';
import { GetSimPlansResult } from 'src/app/services/models/market-plans.model';
import { FlagsPipe } from 'src/app/pipes/flags.pipe';

@Component({
  selector: 'app-markets-card',
  standalone: true,
  imports: [CommonModule, RouterModule, TranslocoModule, FlagsPipe],
  templateUrl: './markets-card.component.html',
  styleUrls: ['./markets-card.component.scss']
})
export class MarketsCardComponent {
  @Input() country: Market | null = null;
  simType: string[] | undefined = [];
  lowerPrice: number = 0;

  constructor(private router: Router, private marketPlansService: MarketPlansService) {}

  ngOnInit(): void {
      this.simType = this.country?.planTypeInfo.map(t=>t.simTypeName) 
  }

  goToMarketPage() {
    const type = (this.simType && !this.simType.includes('esim')) ? this.simType[0] : 'esim';
    this.router.navigate(['/get-your-sim', this.country?.groupName.toLowerCase().replaceAll(' ', '-'), type]);
  }


}
