import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Router } from '@angular/router';
import { BlogPost } from 'src/app/services/models/blog-model';
import { TranslocoModule, TranslocoService } from '@ngneat/transloco';

@Component({
  selector: 'app-blog-card',
  standalone: true,
  imports: [CommonModule, TranslocoModule],
  templateUrl: './blog-card.component.html',
  styleUrls: ['./blog-card.component.scss']
})
export class BlogCardComponent {
  @Input() article: BlogPost;
  imageUrl: string;

  constructor(private router: Router, private translocoService: TranslocoService) {}

  ngOnInit(): void {}

  goToPost(article: BlogPost) {
    const activeLang = this.translocoService.getActiveLang();
    this.translocoService
      .selectTranslate(article.title, {}, activeLang)
      .subscribe((translatedTitle: string) => {
        this.router.navigate([
          'blog',
          activeLang,
          article.id,
          translatedTitle.toLowerCase().replace(/ /g, '-'),
        ]);
      });
  }
}
