<section class="lg:py-6 lg:px-14">
  <h3 class="text-center md:text-start font-bold text-2xl py-4" transloco="checkout.choose-date.title-1"></h3>

  <article class="">
    <h6 class="text-grey text-sm px-4 xl:px-12  py-4 border rounded-lg mb-6" transloco="checkout.choose-date.subtitle">
    </h6>
    <form [formGroup]="dateForm" (ngSubmit)="onSubmitDateForm()">
      <div class="sm:flex gap-5" >
        <!-- BEGIN DATE INPUT -->
        <div class="relative mb-4 sm:w-1/2" id="start-datepicker-with-limits" data-te-input-wrapper-init
          data-te-inline="true" data-te-format="mm/dd/yyyy">
          <label for="beginDate" class="mb-0 max-w-[90%] pt-[0.37rem] leading-[1.6] font-medium"
            transloco="checkout.choose-date.begin-date"></label>
          <input data-clarity-unmask="true" formControlName="beginDate" id="beginDate" type="text" data-te-datepicker-toggle-ref data-te-datepicker-toggle-button-ref
            placeholder="{{'checkout.choose-date.format-date' | transloco}}" autocomplete="off" onfocus="this.blur()"
            class="{{ formSubmitted && dateForm.get('beginDate').invalid ? 'border-red' : 'border-greyDark' }} block min-h-[auto] w-full rounded border border-greyDark bg-transparent px-3 rtl:pr-12 py-[0.32rem] leading-[1.6] outline-none focus:border-grey" />
          <button data-te-datepicker-toggle-ref data-te-datepicker-toggle-button-ref
            class="justify-content-center absolute right-2.5 top-1/2 pt-0.5 flex items-center border-none bg-transparent outline-none hover:text-orange focus:text-orange [&>svg]:h-5 [&>svg]:w-5">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor">
              <path fill-rule="evenodd"
                d="M6.75 2.25A.75.75 0 017.5 3v1.5h9V3A.75.75 0 0118 3v1.5h.75a3 3 0 013 3v11.25a3 3 0 01-3 3H5.25a3 3 0 01-3-3V7.5a3 3 0 013-3H6V3a.75.75 0 01.75-.75zm13.5 9a1.5 1.5 0 00-1.5-1.5H5.25a1.5 1.5 0 00-1.5 1.5v7.5a1.5 1.5 0 001.5 1.5h13.5a1.5 1.5 0 001.5-1.5v-7.5z"
                clip-rule="evenodd"></path>
            </svg>
          </button>
        </div>

        <!-- END DATE INPUT  -->
        <div [hidden]="simType === 'long-term' || simType === 'data'"
          class="{{ dateForm.get('beginDate').invalid ? 'pointer-events-none' : ''}} relative mb-4 sm:w-1/2"
          id="datepicker-with-limits" data-te-input-wrapper-init data-te-inline="true" data-te-format="mm/dd/yyyy">
          <label for="endDate" class="mb-0 max-w-[90%] pt-[0.37rem] leading-[1.6] font-medium"
            transloco="checkout.choose-date.end-date"></label>
          <input data-clarity-unmask="true" formControlName="endDate" id="endDate" type="text" data-te-datepicker-toggle-ref data-te-datepicker-toggle-button-ref
            placeholder="{{ 'checkout.choose-date.format-date' | transloco}}" autocomplete="off" onfocus="this.blur()"
            class="{{ formSubmitted && dateForm.get('endDate').invalid ? 'border-red' : 'border-greyDark' }} block min-h-[auto] w-full rounded border border-greyDark bg-transparent px-3 rtl:pr-12  py-[0.32rem] leading-[1.6] outline-none focus:border-grey" />
          <button data-te-datepicker-toggle-ref data-te-datepicker-toggle-button-ref
            class="justify-content-center absolute right-2.5 top-1/2 pt-0.5 flex items-center border-none bg-transparent outline-none hover:text-orange focus:text-orange [&>svg]:h-5 [&>svg]:w-5">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor">
              <path fill-rule="evenodd"
                d="M6.75 2.25A.75.75 0 017.5 3v1.5h9V3A.75.75 0 0118 3v1.5h.75a3 3 0 013 3v11.25a3 3 0 01-3 3H5.25a3 3 0 01-3-3V7.5a3 3 0 013-3H6V3a.75.75 0 01.75-.75zm13.5 9a1.5 1.5 0 00-1.5-1.5H5.25a1.5 1.5 0 00-1.5 1.5v7.5a1.5 1.5 0 001.5 1.5h13.5a1.5 1.5 0 001.5-1.5v-7.5z"
                clip-rule="evenodd"></path>
            </svg>
          </button>
        </div>
        <!-- END DATE FOR ESIM DATA ONLY -->
        <div [hidden]="simType !== 'data'" class="relative mb-4 sm:w-1/2" id="datepicker-with-limits"
          data-te-input-wrapper-init data-te-inline="true" data-te-format="mm/dd/yyyy">
          <label for="endDate" class="mb-0 max-w-[90%] pt-[0.37rem] leading-[1.6] font-medium"
            transloco="checkout.choose-date.end-date"></label>
          <input data-clarity-unmask="true" formControlName="endDate" id="endDate" type="text"
            placeholder="{{ dateForm.get('beginDate').invalid ? ('checkout.choose-date.format-date' | transloco) : dateForm.get('endDate').value}}"
            class="pointer-events-none block min-h-[auto] w-full rounded border border-greyDark bg-transparent px-3 rtl:pr-12  py-[0.32rem] leading-[1.6] outline-none focus:border-grey" />
        </div>

        <!-- END DATE FOR LONG TERM -->
        <div [hidden]="simType !== 'long-term'" class="relative mb-4 sm:w-1/2">
          <label for="endDate" class="mb-0 max-w-[90%] pt-[0.37rem] leading-[1.6] font-medium"
            transloco="checkout.choose-date.end-date"></label>
          <div class="flex items-center gap-5 min-h-[auto] rounded border w-fit bg-transparent">
            <!-- one month less button -->
            <button type="button" (click)="calculateEndDateForLongTerm('', false)"
              class="p-2 {{ longTermMonths === 3 ? 'pointer-events-none' : ''}}">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="grey"
                class="w-6 h-6">
                <path stroke-linecap="round" stroke-linejoin="round" d="M15 12H9m12 0a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
              </svg>
            </button>
            <!-- number of months for long term -->
            <div data-clarity-unmask="true" class="flex items-center flex-1"><strong class="px-2 text-lg"> {{
                longTermMonths }} </strong> Months
            </div>
            <!-- one month more button -->
            <button type="button" (click)="calculateEndDateForLongTerm('', true)" class=" p-2 ">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="grey"
                class="w-6 h-6">
                <path stroke-linecap="round" stroke-linejoin="round"
                  d="M12 9v6m3-3H9m12 0a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
              </svg>
            </button>
          </div>
        </div>
      </div>

      <!-- CHANGE SIM QUANTITY INPUT -->
      <div class="flex flex-col-reverse sm:flex-row gap-4">
        <div>
          <label for="" class="mb-0 max-w-[90%] pt-[0.37rem] leading-[1.6] font-medium"
            transloco="checkout.choose-date.sim-quantity"></label>
          <div class="flex items-center gap-5 min-h-[auto] rounded border w-fit bg-transparent">
            <button type="button" (click)="changeSimQty(false)" class="rounded border p-2 hover:scale-110">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="grey"
                class="w-6 h-6">
                <path stroke-linecap="round" stroke-linejoin="round" d="M15 12H9m12 0a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
              </svg>
            </button>

            <div class="flex items-center flex-1">
              <strong id="sim-quantity" data-clarity-unmask="true" class="px-2 text-lg"> {{ simQuantity }} </strong>
              <span transloco="checkout.choose-date.sim-card"></span>
            </div>

            <button type="button" (click)="changeSimQty(true)" class="rounded border p-2 hover:scale-110">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="grey"
                class="w-6 h-6">
                <path stroke-linecap="round" stroke-linejoin="round"
                  d="M12 9v6m3-3H9m12 0a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
              </svg>
            </button>
          </div>

        </div>
        <div class="flex flex-1 sm:justify-end mt-auto">
          
        </div>
      </div>

      <p *ngIf="selectedPlan.maxDaysPerActivation === 7" class="pt-6 text-xs" transloco="checkout.choose-date.plan-price-valid-7"></p>
      <p *ngIf="selectedPlan.maxDaysPerActivation >= 30" class="pt-6 text-xs" transloco="checkout.choose-date.plan-price-valid-30"></p>
      <p *ngIf="selectedPlan.maxDaysPerActivation === 15" class="pt-6 text-xs" transloco="checkout.choose-date.plan-price-valid-15"></p>
      
      <p *ngIf="totalDays" class="pt-2 text-xs text-center sm:text-start">
        <span transloco="checkout.choose-date.you-selected"></span>&nbsp;
        <span>{{ totalDays }}</span>&nbsp;
        <span transloco="checkout.choose-date.days"></span>
      </p>

      <button type="submit"
        class="{{ !dateForm.valid ? 'hover:bg-red-500 opacity-50' : 'hover:scale-105'}} inline-block w-full rounded-full bg-orange px-7 pb-2.5 pt-3 mt-6 text-sm font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out"
        transloco="checkout.billing.form-btn-send">
      </button>
    </form>
  </article>
</section>