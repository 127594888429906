import { Component, Input, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MarkdownModule } from 'ngx-markdown';
import { filter, Subscription } from 'rxjs';
import { TranslocoService } from '@ngneat/transloco';

@Component({
  selector: 'app-blog-article-content',
  standalone: true,
  imports: [CommonModule, MarkdownModule],
  templateUrl: './blog-article-content.component.html',
  encapsulation: ViewEncapsulation.ShadowDom,
  styleUrls: ['./blog-article-content.component.scss']
})
export class BlogArticleContentComponent {
  @Input() translatedMarkdown: string;
}
