import { Component, Input, Signal, WritableSignal, signal } from '@angular/core';
import { CommonModule, Location } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { TranslocoModule, TranslocoService } from '@ngneat/transloco';
import { PlanTypeInfo } from 'src/app/services/models/markets.model';
import { Plan, SimPlan } from 'src/app/services/models/market-plans.model';
import { SubmitOrderService } from '../../../services/submit-order/submit-order.service';
import { FormGroup } from '@angular/forms';
import { SquaresPipe } from 'src/app/pipes/squares.pipe';

@Component({
  selector: 'app-checkout-summary',
  standalone: true,
  imports: [CommonModule, TranslocoModule, SquaresPipe],
  templateUrl: './checkout-summary.component.html',
  styleUrls: ['./checkout-summary.component.scss'],
})
export class CheckoutSummaryComponent {
  @Input() selectedPlan: SimPlan | undefined = undefined;
  @Input() totalPrice: Signal<number>
  @Input() shippingPrice: number = 0;
  @Input() simQuantity: number = 1;
  @Input() simsPerPeriod: number = 1;
  @Input() couponPrice: number = 0;
  simType: string = this.route.snapshot.params['type'];
  market: string = this.route.snapshot.params['market'];
  dateForm = this.submitOrderService.submitForm.get('dateForm') as FormGroup;
  showPopover: boolean = false;

  constructor(
    private route: ActivatedRoute,
    private location: Location,
    private submitOrderService: SubmitOrderService,
    private translocoService: TranslocoService
    ) {}
    
    ngOnInit(): void {}

  getTranslatedMarketName(): string {
    const countryName = this.route.snapshot.params['market'];
    return countryName
      ? this.translocoService.translate(`markets.${countryName}`)
      : 'Activation';
  }

  changePlan() {
    this.location.back();
  }

  showPeriodPopover() {
    this.showPopover = true
    setTimeout(() => {
      this.showPopover = false
    }, 2500);
  }
}
