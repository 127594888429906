<article class="flex flex-col items-center py-4" >
  <form [formGroup]="couponForm" (ngSubmit)="onSubmitCouponCode()">

    <label for="couponCode" class="text-sm" transloco="checkout.coupon-have"></label>
    <div class="flex flex-col sm:flex-row">
      <input type="text" id="couponCode" formControlName="couponCode"
      class="{{ couponForm.get('couponCode')?.invalid && codeSubmitted ? 'border-red' : 'border-greyDark' && couponPrice > 0 ? 'pointer-events-none bg-greyDark' : ' bg-transparent'}} block min-h-[auto] rounded border mb-2 sm:mb-0 px-3 py-[0.32rem] leading-[1.6] outline-none focus:border-grey peer-invalid:border-red" />
      <button *ngIf="couponPrice === 0"
      class="rounded-full px-12 py-2 border-2 border-orange text-orange text-sm font-semibold uppercase ml-2 hover:text-white hover:bg-orange" transloco="checkout.coupon-apply"></button>
      <button *ngIf="couponPrice > 0" (click)="removeCoupon()"
      class="rounded-full px-12 border-2 border-orange text-orange text-sm font-semibold uppercase ml-2 hover:text-white hover:bg-orange" transloco="checkout.coupon-remove"></button>
    </div>
  </form>
</article>