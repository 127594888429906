<div class="relative">
  <!-- MOBILE PROMTION -->
  <div *ngIf="selectedMarket === 'hamaagal'" class="md:hidden text-center text-xs pb-6">
    <p class="px-8" transloco="promo.use-coupon"></p>
    <img src="assets/images/coupon-hamaagal.svg" alt="" class="w-60 mx-auto"> 
    <p class="px-8">
      <span transloco="promo.optional"></span><br>
      <strong transloco="promo.keep-isr-number"></strong>
    </p>
      <img src="assets/images/011Logo.png" alt="a" class="w-24 mx-auto">
  </div>
  <!-- --------------- -->

  <section class="{{ selectedSimType === 'data' ? 'h-[350px]' : 'h-[490px]'}} relative border border-greyLight hover:border-greyDark hover:shadow-lg shadow-2xl px-6 py-6 rounded-3xl w-72  flex flex-col justify-between">
    <!-- PROMOTIONS -->
    <img *ngIf="selectedMarket === 'hamaagal'" src="assets/images/coupon-gs.svg" alt="" class="w-24 md:w-[200px] absolute left-[203px] md:left-[-168px] top-12">
    <div *ngIf="selectedMarket === 'hamaagal'" class="hidden md:block md:absolute md:right-[-209px] md:top-12 text-start text-xs">
      <p class="px-8" transloco="promo.use-coupon"></p>
      <img src="assets/images/coupon-hamaagal.svg" alt="" class="w-60"> 
      <p class="px-8"> 
        <span transloco="promo.optional"></span><br>
        <strong transloco="promo.keep-isr-number"></strong>
      </p>
        <img src="assets/images/011Logo.png" alt="a" class="w-24 pl-8">
    </div>
    <!-- --------------------- -->

    <div>
      <div class="flex items-center">
        <img src="assets/images/icons/circle-icon.svg" alt="circle icon" class="w-[3rem] h-[3rem]">
        <div class="flex flex-col items-start text-start px-4">
          <span class="text-sm text-grey" transloco="plan-page.card-name"></span>
          <h6 class="font-semibold h-5" [transloco]="'plan-page.plan-name-' + plan.simPlanId"></h6>
        </div>
      </div>
  
      <h4 data-clarity-unmask="true" class="flex flex-col text-center text-2xl font-semibold py-3">
        <span *ngIf="selectedMarket === 'hamaagal'" class="text-center text-xl font-semibold pt-3 lineThrough">USD 65</span>
        <span *ngIf="selectedMarket === 'hamaagal'" class="text-center text-2xl font-semibold pt-3">USD 52</span>
        <span *ngIf="selectedMarket !== 'hamaagal'">USD {{plan?.price * plan.quantity}}</span>
      </h4>

      <ul data-clarity-unmask="true" class="text-grey px-6 text-start text-sm list-disc" [innerHTML]="'plan-page.card-list-' + plan.simPlanId | transloco"></ul>
    </div>
  
    <div class="flex flex-col items-stretch">
      <button (click)="selectPlan(plan?.simPlanId)" type="button" class="bg-orange text-white rounded-full px-8 py-3 transition delay-75 duration-100 ease-in-out hover:scale-105 my-4" 
        transloco="plan-page.get-your-sim-btn"></button>
  
      <!-- LEARN MORE BUTTON -->
      <div class="flex items-center justify-center cursor-pointer" (click)="showMoreDetails()">
        <button class="flex items-center text-sm" transloco="plan-page.learn-more-btn"></button>
        &nbsp;
        <span class="{{showDetails ? 'origin-center rotate-180 transition-all ease-linear' : ''}}">
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4">
            <path stroke-linecap="round" stroke-linejoin="round" d="m19.5 8.25-7.5 7.5-7.5-7.5" />
          </svg>
        </span>
      </div>
    </div>


  </section>

  <!-- LEARN MORE DETAILS -->
  <div *ngIf="showDetails" 
    class="absolute left-4 right-4 z-[9996] border bg-white border-greyLight hover:border-greyDark hover:shadow-lg shadow-2xl px-4 py-6 mx-auto rounded-b-3xl rounded-t-xl w-60 animate-fade-down">
    <ul class="transition-[max-height] duration-500 ease-in text-xs text-start text-grey list-disc ltr:pl-4 rtl:pr-4 learn-more-list"
      [innerHTML]="'plan-page.learn-more-list-' + plan.simPlanId | transloco "></ul>
  </div>
</div>