<div class="flex justify-center pb-12" *ngIf="showButtons()">
  <div class="rounded-full bg-white border border-greyLight shadow-xl p-1">
    <button
      *ngFor="let planType of getPlanTypes()"
      [class.active]="activeButton === planType"
      (click)="emitButtonClick(planType)"
      [transloco]="'plan-page.sim-btn-' + planType"
      class="min-w-[90px] sm:min-w-[135px] py-3 text-sm sm:text-base rounded-full text-grey font-semibold {{ activeButton === planType ? 'bg-orange text-white' : 'hover:bg-orangeLight' }}">
    </button>
  </div>
</div>
