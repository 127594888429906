import { Injectable } from '@angular/core';
import { SimPlan } from '../models/market-plans.model';
import { ShipElement } from '../models/shipping-options.model';

declare global {
  // Declare a global interface to extend the Window type
  interface Window {
    dataLayer: any[]; // Define dataLayer as an array of any type
  }
}

@Injectable({
  providedIn: 'root',
})
export class GoogleTagManagerService {
  constructor() {}
  // Method to send events to DataLayer
  sendEventToDataLayer(evento: any) {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({ ecommerce: null });
    window.dataLayer.push(evento);
  }

  addToCart(selectedPlan: SimPlan, variant: string) {
    this.sendEventToDataLayer({
      event: 'add_to_cart',
      ecommerce: {
        currency: 'USD',
        value: selectedPlan.price * selectedPlan.quantity,
        items: [{
          item_id: selectedPlan.simPlanId,
          item_name: selectedPlan.planName,
          item_brand: 'Globusim',
          item_category: selectedPlan.simPlanMarketsSimTypeName,
          item_variant: variant, // Activation | Reload | Normal
          price: selectedPlan.price,
          quantity: selectedPlan.quantity,
          index: 1,
        }],
      },
    });
  }

  // Method to send addToCart event to DataLayer
  beginCheckout(selectedPlan: SimPlan, simQty: number, variant) {
    this.sendEventToDataLayer({
      event: 'begin_checkout',
      ecommerce: {
        currency: 'USD',
        value: selectedPlan.price * simQty,
        items: [
          {
            item_id: selectedPlan.simPlanId,
            item_name: selectedPlan.planName,
            item_brand: 'Globusim',
            item_category: selectedPlan.simPlanMarketsSimTypeName,
            item_variant: variant, // Activation | Normal
            price: selectedPlan.price,
            quantity: simQty,
            index: 1,
          },
        ],
      },
    });
  }

  // Method to send addShippingOptions event to DataLayer
  addShippingOptions(selectedPlan: SimPlan, simQty: number, shippingInfo: ShipElement, variant: string) {
    this.sendEventToDataLayer({
      event: 'add_shipping_info',
      ecommerce: {
        currency: 'USD',
        value: selectedPlan.price * simQty + (shippingInfo ? shippingInfo.Fee : 0),
        items: [
          {
            item_id: selectedPlan.simPlanId,
            item_name: selectedPlan.planName,
            item_brand: 'Globusim',
            item_category: selectedPlan.simPlanMarketsSimTypeName,
            item_variant: variant, // Activation | Normal
            price: selectedPlan.price,
            quantity: simQty,
            shipping_price: shippingInfo ? shippingInfo.Fee : 0,
            index: 1,
          },
        ],
      },
    });
  }

  // Method to send Purchase event to DataLayer
  purchase(selectedPlan: SimPlan, confirmationId: string, shippingInfo: ShipElement, simQty: number, couponCodeVal: string, finalPrice: number, discount: number, variant) {

    this.sendEventToDataLayer({
      event: "purchase",
      ecommerce: {
          transaction_id: `T${confirmationId}`,
          value: finalPrice,
          shippingId: shippingInfo ? shippingInfo.ShippingTypeID : 3,
          shipping: shippingInfo ? shippingInfo.Fee : 0,
          currency: "USD",
          coupon: couponCodeVal,
          items: [{
            item_id: selectedPlan.simPlanId,
              item_name: selectedPlan.planName,
              price: selectedPlan.price,
              item_brand: "Globusim",
              item_category: selectedPlan.simPlanMarketsSimTypeName,
              item_variant: variant, // Activation | Reload | Normal
              quantity: simQty,
              discount: discount
          }]
      }
    });
  }
}
