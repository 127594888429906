import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BlogService } from '../../services/blog/blog.service';
import { BlogCardComponent } from 'src/app/core/shared/blog-card/blog-card.component';
import { TranslocoModule, TranslocoService } from '@ngneat/transloco';
import { Router } from '@angular/router';
import { BlogPost } from 'src/app/services/models/blog-model';
import { SpinnerComponent } from '../../core/shared/spinner/spinner.component';
import { SafePipe } from 'src/app/pipes/sanitize.pipe';
import { DomSanitizer, Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'app-blog-page',
  standalone: true,
  imports: [
    CommonModule,
    BlogCardComponent,
    TranslocoModule,
    SpinnerComponent,
    SafePipe,
  ],
  templateUrl: './blog-page.component.html',
  styleUrls: ['./blog-page.component.scss'],
})
export class BlogPageComponent {
  articles: BlogPost[];
  latest: BlogPost;
  isLoading: boolean = false;
  imageUrl: any;

  constructor(
    private blogService: BlogService,
    private router: Router,
    private translocoService: TranslocoService,
    private sanitizer: DomSanitizer,
    private titleService: Title,
    private meta: Meta
  ) {}

  ngOnInit(): void {
    this.isLoading = true;
    this.getBlogArticles();

    this.updateMetaTags(
      'Globusim | Blog',
      'esim, sim, data only, connectivity, stay connected, ',
      'Blog'
    );
  }

  updateMetaTags(title: string, keywords: string, description: string) {
    this.titleService.setTitle(title);
    this.meta.updateTag({ name: 'keywords', content: keywords });
    this.meta.updateTag({ name: 'description', content: description });
    this.meta.updateTag({ rel: 'canonical', href:  "https://globusim.com/blog" });
  }


  async getBlogArticles() {
    try {
      const res = await this.blogService.getAllBlogArticles<BlogPost[]>();

      if (res.isSuccessful) {
        res.result.sort(
          (a, b) => new Date(b.date).getTime() - new Date(a.date).getTime()
        );

        this.latest = res.result.shift();
        this.imageUrl = this.sanitizer.bypassSecurityTrustStyle(
          `url("${this.latest.imageBlog}")`
        );
        this.articles = res.result;

        setTimeout(() => {
          this.isLoading = false;
        }, 1000);
      }
    } catch (error) {
      console.log(error);
    }
  }

  goToPost(article: BlogPost) {
    const activeLang = this.translocoService.getActiveLang();
    this.translocoService
      .selectTranslate(article.title, {}, activeLang)
      .subscribe((translatedTitle: string) => {
        this.router.navigate([
          'blog',
          activeLang,
          article.id,
          translatedTitle.toLowerCase().replace(/ /g, '-'),
        ]);
      });
  }
}
