import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProfileSidebarComponent } from 'src/app/core/shared/profile-sidebar/profile-sidebar.component';
import { RouterModule } from '@angular/router';

@Component({
  standalone: true,
  imports: [CommonModule, ProfileSidebarComponent, RouterModule],
  templateUrl: './profile-page.component.html',
  styleUrls: ['./profile-page.component.scss']
})
export class ProfilePageComponent {
  ngOnInit(): void {}
}
