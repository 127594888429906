<div
  class="fixed inset-0 bg-black bg-opacity-50 flex justify-center pt-[150px] px-10 z-[1000] transition-opacity duration-500 ease-in-out">
  
  <div class="relative pt-10 z-50 w-[32rem] h-fit">
    <input data-clarity-unmask="true" #simSearchInput type="text" name="simPlanMarketSearchInput" [(ngModel)]="searchValue" autocomplete="off"
      (ngModelChange)="searchInputEvent($event)" placeholder="{{'search-bar.placeholder' | transloco}}"
      (click)="$event.stopPropagation();"
      class="w-full p-6 px-14 rounded-full border border-greyLight shadow-xl hover:shadow-2xl outline-none">
    <img src="assets/images/icons/location.svg" alt="location icon"
      class="absolute top-14 pt-2 ltr:left-4 rtl:right-4 w-[2rem] h-[2rem] ">
    <img src="assets/images/icons/search-icon.svg" alt="search icon"
      class="absolute top-12 pt-1 ltr:right-0 rtl:left-0 w-[3.5rem] h-[3.5rem] mx-2">
  
    <div *ngIf="searchValue"
      class="absolute top-full z-50 bg-white mt-3 w-full overflow-y-auto rounded-2xl shadow-lg ring-1 ring-greyDark max-h-[300px] animate-fade">
      <div class="w-full py-6 items-center">
        <p class="text-sm px-4 uppercase "><span class="px-4 border border-greyLight rounded" transloco="search-bar.local"></span></p>
  
        <ul *ngFor="let item of countries" class="text-xl px-4 sm:px-8 cursor-pointer">
          <li (click)="goToMarketPage(item)"
            class="flex items-baseline py-3 transition delay-75 duration-100 hover:scale-105 hover:font-semibold capitalize border-b border-greyLight">
            <img [src]="(item.groupName | appFlags)" [alt]="item.groupName + 'flag'" class="w-[3rem] h-[1.5rem] rounded-3xl sm:mr-3" />
            <p class="flex flex-1 text-sm sm:text-base mt-auto" [transloco]="'markets.' + item.groupName.toLowerCase().replaceAll(' ', '-')"></p>
            <span class="text-xs" transloco="search-bar.since"></span>&nbsp;
            <span class="text-xs" [transloco]="'markets.lower-price.' + item.groupName.toLowerCase().replaceAll(' ', '-')"></span>
          </li>
        </ul>
  
      </div>
      <div *ngIf="regions.length > 0" class="w-full pb-6">
        <p class="text-sm px-4 uppercase"><span class="px-4 border border-greyLight rounded" transloco="search-bar.region"></span></p>
  
        <ul *ngFor="let item of regions" class="text-xl px-8 cursor-pointer">
          <li (click)="goToMarketPage(item)"
          class="flex items-baseline py-3 transition delay-75 duration-100 hover:scale-105 hover:font-semibold capitalize">
            <img [src]="(item.groupName | appFlags)" [alt]="item.groupName + 'flag'" class="w-[3rem] h-[1.5rem] rounded-3xl sm:mr-3"/>
            <p class="flex flex-1 text-sm sm:text-base mt-auto" [transloco]="'markets.' + item.groupName.toLowerCase()"></p>
          </li>
        </ul>
        
      </div>
    </div>
  </div>

</div>