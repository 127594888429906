<section class="pb-10 border-b border-greyLight">
  <h3 class="font-bold text-xl">Compras Activas</h3>
  <p class="text-grey text-sm py-6">En esta sección, podrás acceder a toda la información relevante relacionada con tus
    compras de
    servicios de eSIM y SIM.</p>

  <article class="flex flex-col mlg:flex-row rounded-2xl shadow-xl p-4 border border-greyLight">
    <div class="flex flex-col items-center mlg:items-start flex-1 p-4">
      <div class="flex">
        <img src="assets/images/icons/circle-icon.svg" alt="circle icon">
        <div class="px-4">
          <h6 class="text-grey">Plan</h6>
          <span class="text-md font-semibold">5 GB / 15 días</span>
        </div>
      </div>
      <p class="text-xs text-grey py-6">
        Vencimiento del plan 30/11/2023
      </p>
      <button type="button" class="bg-orange text-white font-semibold px-10 py-2 rounded-full w-3/5">Hacer recarga</button>
    </div>

    <div class="pr-4">
      <div class="flex pb-4">
        <img src="assets/images/loader-green.png" alt="loader icon" class="w-[120px] mr-4">
        <img src="assets/images/loader-orange.png" alt="loader icon" class="w-[120px]">
      </div>
      <span class="text-grey">Te quedan: <strong class="text-black">3.7 GB y 300 min.</strong></span>
    </div>
  </article>

</section>

<section class="py-8 border-">
  <h3 class="font-bold text-xl">Historial de compras</h3>
  <p class="text-grey text-sm py-6">En esta sección, podrás consultar todo el historial de tus compras, tanto de eSIM como de SIM.</p>
</section>