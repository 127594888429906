import { Component, Signal, ViewChild, computed, signal } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CheckoutStepsComponent } from 'src/app/core/shared/checkout-steps/checkout-steps.component';
import { CheckoutSummaryComponent } from 'src/app/core/shared/checkout-summary/checkout-summary.component';
import { CheckoutBillingComponent } from 'src/app/core/shared/checkout-billing/checkout-billing.component';
import { CheckoutPaymentComponent } from 'src/app/core/shared/checkout-payment/checkout-payment.component';
import { CheckoutDateStepComponent } from 'src/app/core/shared/checkout-date-step/checkout-date-step.component';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { TranslocoModule, TranslocoService } from '@ngneat/transloco';
import { MarketPlansService } from '../../services/market-plans/market-plans.service';
import {
  SimPlan,
  GetSimPlanResult,
  FixedCustomerPlan,
} from 'src/app/services/models/market-plans.model';
import { SubmitOrderService } from 'src/app/services/submit-order/submit-order.service';
import { ShipElement } from 'src/app/services/models/shipping-options.model';
import { GetSimCustomerService } from 'src/app/services/sim-customer/get-sim-customer.service';
import { SpinnerComponent } from 'src/app/core/shared/spinner/spinner.component';
import { PopUpComponent } from 'src/app/core/shared/pop-up/pop-up.component';
import { CouponCodeService } from '../../services/coupon-code/coupon-code.service';
import { CouponCodeComponent } from 'src/app/core/shared/coupon-code/coupon-code.component';
import { ReloadEsimService } from '../../services/reload-esim/reload-esim.service';
import { ModalStoreInfoComponent } from '../../core/shared/modal-store-info/modal-store-info.component';
import { SlidersPipe } from 'src/app/pipes/sliders.pipe';
import { IEnvelopeObj } from 'src/app/services/models/pickup-store.model';
import { GoogleTagManagerService } from 'src/app/services/google-tag-manager/google-tag-manager.service';
import { RecaptchaService } from '../../services/recatpcha/recaptcha.service';

@Component({
  standalone: true,
  imports: [
    CommonModule,
    CheckoutStepsComponent,
    CheckoutSummaryComponent,
    CheckoutBillingComponent,
    CheckoutDateStepComponent,
    CheckoutPaymentComponent,
    RouterModule,
    SpinnerComponent,
    TranslocoModule,
    PopUpComponent,
    CouponCodeComponent,
    ModalStoreInfoComponent,
    SlidersPipe,
  ],
  templateUrl: './checkout-page.component.html',
  styleUrls: ['./checkout-page.component.scss'],
})
export class CheckoutPageComponent {
  @ViewChild('paymentForm') paymentForm: CheckoutPaymentComponent;
  currentStep: number = 1;
  selectedPlan: SimPlan | undefined = undefined;
  shippingOption: ShipElement | null;
  shippingID: number = 1;
  simQuantity: number = 1;
  simsPerPeriod: number = 1;
  totalPrice: Signal<number> = signal(0);
  globalForm = this.submitOrderService.submitForm;
  couponCode: string = '';
  couponPrice: number = 0;
  envelopeObj: IEnvelopeObj | null = null;
  userID: string = '';
  isLoading: boolean = false;
  fxdCustomerPlan: FixedCustomerPlan | null = null;
  showPopUp: boolean = false;
  popUpMessage: string = '';
  showStoreInfo: boolean = false;
  paypalDetails: any;
  selectedPaymentMethod: string | null = 'card';
  isDifferentBillingAddress: boolean = false;
  isReloadEsim = this.router.url.includes('/reload-esim/');
  isActivation = this.router.url.includes('/activate-sim/');
  currentLanguage: string = '';
  selectedSimType: string = this.route.snapshot.params['type'];
  selectedMarket: string = this.route.snapshot.params['market'];
  isEsim: boolean =
    this.selectedSimType === 'esim' || this.selectedSimType === 'data'
      ? true
      : false;

  constructor(
    private marketPlansService: MarketPlansService,
    private route: ActivatedRoute,
    private router: Router,
    private submitOrderService: SubmitOrderService,
    private getSimCustomerService: GetSimCustomerService,
    private couponCodeService: CouponCodeService,
    private reloadEsimService: ReloadEsimService,
    private translocoService: TranslocoService,
    private gtmService: GoogleTagManagerService,
    private recaptchaService: RecaptchaService
  ) {
    this.currentLanguage = this.translocoService.getActiveLang();
  }

  ngOnInit(): void {
    this.globalForm.get('dateForm').setValue({
      beginDate: '',
      endDate: '',
      simQuantity: 1,
      simsPerPeriod: 1,
    });

    this.selectedPlan ? (this.isLoading = false) : (this.isLoading = true);
    const planId = this.route.snapshot.params['plan-id'];
    this.getSimPlanById(planId);

    if (this.isReloadEsim) {
      this.currentStep = 3;
      this.userID = this.reloadEsimService.getReloadTicket();
    }

    if (this.isEsim || this.isActivation) {
      this.shippingID = 3;
    }
  }

  ngOnDestroy(): void {
    this.shippingID = 1;
  }

  addToCartGoogleTagManager() {
    const variant = this.isActivation
      ? 'Activation'
      : this.isReloadEsim
      ? 'Reload'
      : 'Normal';
    this.gtmService.addToCart(this.selectedPlan, variant);
  }

  purchaseGoogleTagManager(confirmationId, finalPrice, discount, variant) {
    this.gtmService.purchase(
      this.selectedPlan,
      confirmationId,
      this.shippingOption,
      this.simQuantity,
      this.couponCode,
      finalPrice,
      discount,
      variant
    );
  }

  nextStep() {
    if (this.fxdCustomerPlan && this.currentStep === 1) {
      const fxdAddress = this.fxdCustomerPlan.address;

      this.globalForm.get('addressForm').setValue({
        fullName: `${this.fxdCustomerPlan.firstName} ${this.fxdCustomerPlan.lastName}`,
        address: fxdAddress.address,
        country: '',
        state: '',
        city: fxdAddress.city,
        zipCode: fxdAddress.zipCode,
        email: this.fxdCustomerPlan.email,
        confirmEmail: this.fxdCustomerPlan.email,
        phone: this.fxdCustomerPlan.phone,
      });
      this.currentStep = 3;
    }

    if (this.currentStep < 3) {
      this.currentStep++;
    }
  }

  prevStep() {
    if (this.currentStep > 1) {
      this.currentStep--;
    }
  }

  onChangePeriod(period: number) {
    this.simsPerPeriod = period;

    this.totalPrice = computed(() => {
      const planPrice = this.selectedPlan?.price || 0;
      if (this.shippingOption) {
        return (
          planPrice * period * this.simQuantity +
          Number(this.shippingOption?.Fee)
        );
      } else {
        return planPrice * period * this.simQuantity;
      }
    });
  }

  onNewSimQuantity(quantity: number) {
    this.simQuantity = quantity;

    this.totalPrice = computed(() => {
      const planPrice = this.selectedPlan?.price || 0;
      if (this.shippingOption) {
        return (
          planPrice * this.simsPerPeriod * quantity +
          Number(this.shippingOption?.Fee)
        );
      } else {
        return planPrice * this.simsPerPeriod * quantity;
      }
    });
  }

  updateTotalPrice(newTotalPrice: Signal<number>) {
    this.totalPrice = newTotalPrice;
    this.shippingOption = null;
  }

  onClosePopUp() {
    this.showPopUp = false;
  }

  onShippingOptionsChange(option: ShipElement) {
    this.shippingOption = option;

    if (option !== null && option !== undefined) {
      this.shippingID = option.ShippingTypeID;
    } else if (this.isActivation) {
      this.shippingID = 3;
    } else {
      this.shippingID = 17;
    }

    this.totalPrice = computed(() => {
      if (option === null || option === undefined) {
        return this.selectedPlan?.price * this.simsPerPeriod * this.simQuantity;
      } else {
        return (
          this.selectedPlan?.price * this.simsPerPeriod * this.simQuantity +
          this.shippingOption.Fee
        );
      }
    });
  }

  onShowStoreData(visible: boolean) {
    this.showStoreInfo = visible;
  }

  closeStoreModal() {
    this.showStoreInfo = false;
  }

  onCaptureEnvelope(envelopeData: IEnvelopeObj) {
    this.envelopeObj = {
      envelopes: envelopeData.envelopes,
      PromoCode: '',
      locationID: envelopeData.locationID,
    };

    console.log(this.envelopeObj);
  }

  onPaymentFormMethodSelected(method: string) {
    this.selectedPaymentMethod = method;
  }

  onFillPayPalDetails(details: any) {
    this.paypalDetails = details;
  }

  onBillingAddressChanged(isBillingAddress: boolean) {
    this.isDifferentBillingAddress = isBillingAddress;
  }

  onGetSimCustomer(ticket: string) {
    this.userID = ticket;
  }

  getSimCustomerData(customerData: any) {
    const currentMarketID = this.currentLanguage === 'pt' ? 3 : 1;
    const phoneNumber: string = `+${customerData.phone}`;
    const prefixRegex: RegExp = /^\+\d{1,3}/; // Regular expression for prefix search (format: +followed by 1 to 3 digits)
    const matchPrefix = phoneNumber.match(prefixRegex);
    const prefix = matchPrefix ? matchPrefix[0] : '';

    const data = {
      AgentID: 146,
      firstName: customerData.fullName,
      prefix: prefix,
      phone: phoneNumber.replace(prefix, ''),
      email: customerData.email,
      numItems: this.simQuantity.toString(),
      market: currentMarketID, //SimTypePromotion == 1 ? 7 : 3,*********************** 5 == Activate
    };

    this.getSimCustomer(data);
  }

  onPaymentFormSubmitted() {
    const paymentForm = this.globalForm.get('paymentForm');
    const shipAddressForm = this.globalForm.get('shipAddressForm');

    this.paymentForm.onSubmitPayment();

    if (paymentForm.valid) {
      if (this.isEsim && !this.isReloadEsim && !this.fxdCustomerPlan) {
        this.isLoading = true;
        this.finalizePurchase();
      } else if (!this.fxdCustomerPlan && !this.isReloadEsim) {
        if (!this.isDifferentBillingAddress || shipAddressForm.valid) {
          this.isLoading = true;
          this.finalizePurchase();
        }
      }

      if (this.isReloadEsim || this.fxdCustomerPlan) {
        if (shipAddressForm.valid) {
          this.isLoading = true;
          if (this.isReloadEsim) {
            this.finalizeReloadPurchase();
          } else {
            this.finalizePurchase();
          }
        }
      }
    }
  }

  onCouponCodeSubmit(event: string) {
    this.couponCode = event;
    if (this.couponCode !== '') {
      this.validateCouponCode();
    }
  }

  onRemoveCouponCode() {
    this.couponCode = '';
    this.couponPrice = 0;

    this.totalPrice = computed(() => {
      const planPrice = this.selectedPlan?.price || 0;
      if (this.shippingOption) {
        return (
          planPrice * this.simsPerPeriod * this.simQuantity +
          Number(this.shippingOption?.Fee)
        );
      } else {
        return planPrice * this.simsPerPeriod * this.simQuantity;
      }
    });

    this.envelopeObj.PromoCode = '';
  }

  async getSimCustomer(simCustomer) {
    const res = await this.getSimCustomerService.getSimCustomer(simCustomer);

    if (res.response_code === 200) {
      this.userID = res.ticket;
      this.finalizePurchase();
    }
  }

  async getSimPlanById(simPlanId: number) {
    const res = await this.marketPlansService.getSimPlanById<GetSimPlanResult>(
      simPlanId
    );

    this.selectedPlan = res.result.plan;
    this.isLoading = false;

    this.simQuantity = this.selectedPlan.quantity;
    this.totalPrice = computed(() => {
      const planPrice = this.selectedPlan?.price || 0;
      if (this.shippingOption) {
        return (
          planPrice * this.simsPerPeriod * this.simQuantity +
          Number(this.shippingOption?.Fee)
        );
      } else {
        return planPrice * this.simsPerPeriod * this.simQuantity;
      }
    });

    this.addToCartGoogleTagManager();
    this.fxdCustomerPlan = this.selectedPlan.fixedCustomerPlan;
  }

  async validateCouponCode() {
    const dateForm = this.globalForm.get('dateForm');
    const activateForm = this.globalForm.get('activateForm');
    
    const jsonData = {
      BeginDate: dateForm.get('beginDate').value,
      EndDate: dateForm.get('endDate').value,
      PromoCode: this.couponCode,
      ShipOption: 1,
      Plan: this.selectedPlan.planName,
      Quantity: this.simQuantity,
      simPhone: activateForm.get('phoneNumber').value,
        simSerial: activateForm.get('serialNumber').value,
      PromoCodeParams: {
        simPhone: activateForm.get('phoneNumber').value,
        simSerial: activateForm.get('serialNumber').value,
        plan: this.selectedPlan.planName,
      },
    };

    const res = await this.couponCodeService.validateCouponCode(jsonData);
    try {
      if (res.response_code === 200) {
        if (res.discount === 0) {
          this.showPopUp = true;
          this.popUpMessage = 'code-not-valid';
        } else {
          this.totalPrice = computed(() => {
            const planPrice = this.selectedPlan?.price || 0;
            if (this.shippingOption) {
              return (
                planPrice * this.simsPerPeriod * this.simQuantity +
                Number(this.shippingOption?.Fee) -
                res.discount
              );
            } else {
              return (
                planPrice * this.simsPerPeriod * this.simQuantity - res.discount
              );
            }
          });
        }
        this.couponPrice = res.discount;
        this.envelopeObj.PromoCode = this.couponCode;
      }
    } catch (error) {}
  }

  async finalizePurchase() {
    const activateForm = this.globalForm.get('activateForm');
    const shipAddresForm = this.globalForm.get('shipAddressForm');
    const orderData = this.submitOrderService.getOrderData();
    let stateID;
    let jsonData;

    if (this.fxdCustomerPlan) {
      let fxdAddress = this.fxdCustomerPlan.address;
      stateID = fxdAddress.stateID;
    } else {
      stateID = this.isDifferentBillingAddress
        ? shipAddresForm.get('state').value
        : orderData.state;
    }

    const headers = this.fxdCustomerPlan
      ? this.fxdCustomerPlan.ticket
      : this.userID;

    const token = await this.recaptchaService.executeRecaptcha(
      'GlobusimPurchase'
    );

    if (this.selectedPaymentMethod === 'card') {
      jsonData = {
        Address: this.isDifferentBillingAddress
          ? shipAddresForm.get('address').value
          : orderData.address,
        // 'Affiliate': finalAff,
        AgentID: 146,
        BeginDate: orderData.beginDate,
        BillCountry: this.isDifferentBillingAddress
          ? shipAddresForm.get('country').value
          : orderData.country,
        CardHolder: orderData.cardName,
        City: this.isDifferentBillingAddress
          ? shipAddresForm.get('city').value
          : orderData.city,
        ContactEmail: this.isDifferentBillingAddress
          ? shipAddresForm.get('email').value
          : orderData.email,
        ContactPhoneNumber: this.fxdCustomerPlan
          ? this.fxdCustomerPlan.phone
          : orderData.phone.replace('+', ''),
        CreditCardNumber: orderData.cardNumber,
        EndDate: orderData.endDate,
        ExpirationMonth: orderData.cardExpirationMM,
        ExpirationYear: orderData.cardExpirationYY,
        GroupOrganization: {},
        IsSaveCard: this.selectedSimType === 'long-term' ? 1 : 0,
        ...(this.envelopeObj ? { PickupEnvelope: this.envelopeObj } : {}),
        Plan: this.selectedPlan?.planName,
        PromoCode: this.couponCode,
        PromoCodeParams: {
          simPhone: activateForm.get('phoneNumber').value,
          simSerial: activateForm.get('serialNumber').value,
          plan: this.selectedPlan?.planName,
        },
        Quantity: this.simQuantity,
        RecipientName: orderData.fullName,
        SecurityCode: orderData.cardCVV,
        ShipAddress: orderData.address,
        ShipCity: this.isDifferentBillingAddress
          ? shipAddresForm.get('city').value
          : orderData.city,
        ShipCountry: this.isDifferentBillingAddress
          ? shipAddresForm.get('country').value
          : orderData.country,
        ShipOption: this.shippingID,
        ShipState: stateID.toString(),
        ShipZip: this.isDifferentBillingAddress
          ? shipAddresForm.get('zipCode').value
          : orderData.zipCode,
        simPhone: activateForm.get('phoneNumber').value,
        simSerial: activateForm.get('serialNumber').value,
        State: this.isDifferentBillingAddress
          ? shipAddresForm.get('state').value
          : orderData.state.toString(),
        Zip: orderData.zipCode,
        recaptcha: token,
      };
    } else {
      jsonData = {
        recaptcha: token,
        Paypal: this.paypalDetails.id,
        Address: this.isDifferentBillingAddress
          ? shipAddresForm.get('address').value
          : orderData.address,
        // Affiliate: finalAff,
        AgentID: 146,
        BeginDate: orderData.beginDate,
        BillCountry: this.isDifferentBillingAddress
          ? shipAddresForm.get('country').value
          : orderData.country,
        City: this.isDifferentBillingAddress
          ? shipAddresForm.get('city').value
          : orderData.city,
        ContactEmail: this.isDifferentBillingAddress
          ? shipAddresForm.get('email').value
          : orderData.email,
        ContactPhoneNumber: this.fxdCustomerPlan
          ? this.fxdCustomerPlan.phone
          : orderData.phone.replace('+', ''),
        EndDate: orderData.endDate,
        GroupOrganization: {},
        ...(this.envelopeObj ? { PickupEnvelope: this.envelopeObj } : {}),
        simPhone: activateForm.get('phoneNumber').value,
        simSerial: activateForm.get('serialNumber').value,
        ShipOption: this.shippingID,
        Plan: this.selectedPlan?.planName,
        PromoCode: this.couponCode,
        PromoCodeParams: {
          simPhone: activateForm.get('phoneNumber').value,
          simSerial: activateForm.get('serialNumber').value,
          plan: this.selectedPlan?.planName,
        },
        Quantity: this.simQuantity,
        RecipientName: orderData.fullName,
        ShipAddress: orderData.address,
        ShipCity: this.isDifferentBillingAddress
          ? shipAddresForm.get('city').value
          : orderData.city,
        ShipCountry: this.isDifferentBillingAddress
          ? shipAddresForm.get('country').value
          : orderData.country,
        ShipState: stateID.toString(),
        ShipZip: this.isDifferentBillingAddress
          ? shipAddresForm.get('zipCode').value
          : orderData.zipCode,
        State: this.isDifferentBillingAddress
          ? shipAddresForm.get('state').value
          : orderData.state.toString(),
        Zip: orderData.zipCode,
      };
    }

    const requestOptions = {
      headers: headers,
      body: JSON.stringify(jsonData),
    };

    try {
      const res = await this.submitOrderService.submitOrder(requestOptions);

      if (res.response_code === 200) {
        this.isLoading = false;

        if (this.envelopeObj) {
          this.submitOrderService.setOrderData(this.envelopeObj);
        }

        this.purchaseGoogleTagManager(
          res.confirmationId,
          res.totalAmount,
          res.discount,
          this.isActivation ? 'Activation' : 'Normal'
        );

        this.router.navigate([
          '/order',
          this.selectedSimType,
          res.confirmationId,
          this.selectedPlan.simPlanId,
          this.shippingID,
          this.shippingOption?.Fee || 0,
          this.couponPrice,
        ]);
      } else if (res.response_code === 999) {
        this.isLoading = false;
        this.showPopUp = true;
        this.popUpMessage = 'server-error';
      } else {
        this.isLoading = false;
        this.showPopUp = true;
        this.popUpMessage = res.response_message;
      }
    } catch (error) {
      console.log('CATCH ERROR: ', error);
    }
  }

  async finalizeReloadPurchase() {
    const shipAddresForm = this.globalForm.get('shipAddressForm');
    const reloadForm = this.globalForm.get('reloadForm');
    const orderData = this.submitOrderService.getOrderData();
    let jsonData;

    const headers = this.userID;

    const token = await this.recaptchaService.executeRecaptcha(
      'GlobusimReloadPurchase'
    );
    if (this.selectedPaymentMethod === 'card') {
      jsonData = {
        recaptcha: token,
        AgentID: 146,
        Address: shipAddresForm.get('address').value,
        BillCountry: shipAddresForm.get('country').value,
        CardHolder: orderData.cardName,
        City: shipAddresForm.get('city').value,
        PhoneNumber: reloadForm.get('phoneNumber').value,
        CreditCardNumber: orderData.cardNumber,
        ExpirationMonth: orderData.cardExpirationMM,
        ExpirationYear: orderData.cardExpirationYY,
        Plan: this.selectedPlan?.planName,
        SecurityCode: orderData.cardCVV,
        State: shipAddresForm.get('state').value,
        Zip: shipAddresForm.get('zipCode').value,
      };
    } else {
      jsonData = {
        recaptcha: token,
        Address: orderData.address,
        AgentID: 146,
        BillCountry: orderData.country,
        City: orderData.city,
        Paypal: this.paypalDetails.id,
        PhoneNumber: reloadForm.get('phoneNumber').value,
        Plan: this.selectedPlan?.planName,
        State: orderData.state,
        Zip: orderData.zipCode,
      };
    }

    const requestOptions = {
      headers: headers,
      body: JSON.stringify(jsonData),
    };

    try {
      const res = await this.reloadEsimService.submitReloadOrder(
        requestOptions
      );

      if (res.response_code === 200) {
        this.isLoading = false;
        this.purchaseGoogleTagManager(
          res.confirmationId,
          res.totalAmount,
          res.discount,
          'Reload'
        );

        this.router.navigate([
          '/order',
          'reload',
          res.confirmationId,
          this.selectedPlan.simPlanId,
          0,
          0,
          this.couponPrice,
        ]);
        // this.submitOrderService.clearOrderData();
      } else if (res.response_code === 999) {
        this.isLoading = false;
        this.showPopUp = true;
        this.popUpMessage = 'server-error';
      } else {
        this.isLoading = false;
        this.showPopUp = true;
        this.popUpMessage = res.response_message;
      }
    } catch (error) {
      console.log('CATCH ERROR: ', error);
    }
  }
}
