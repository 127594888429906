import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { TranslocoModule } from '@ngneat/transloco';

@Component({
  selector: 'app-destinations-buttons',
  standalone: true,
  imports: [CommonModule, RouterModule, TranslocoModule],
  templateUrl: './destinations-buttons.component.html',
  styleUrls: ['./destinations-buttons.component.scss']
})
export class DestinationsButtonsComponent {

}
