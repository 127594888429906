import { provideHttpClient } from '@angular/common/http';
import {
  ApplicationConfig,
  importProvidersFrom,
  isDevMode,
} from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { provideRouter } from '@angular/router';
import { routes } from './app.routes';
import { provideTransloco } from '@ngneat/transloco';
import { provideTranslocoLocale } from '@ngneat/transloco-locale';
import { provideTranslocoMessageformat } from '@ngneat/transloco-messageformat';
import { TranslocoHttpLoader } from './transloco-loader';
import {
  provideTranslocoPersistLang,
  cookiesStorage,
} from '@ngneat/transloco-persist-lang';
import { SettingsService } from './services/settings.service';
import { MarkdownModule } from 'ngx-markdown';

export const appConfig: ApplicationConfig = {
  providers: [
    importProvidersFrom(BrowserModule),
    provideHttpClient(),
    provideRouter(routes),
    importProvidersFrom(MarkdownModule.forRoot()),
    provideTransloco({
      loader: TranslocoHttpLoader,
      config: {
        availableLangs: [
          { id: 'en', label: 'English' },
          { id: 'es', label: 'Español' },
          { id: 'he', label: 'עברית' },
          { id: 'pt', label: 'Português' },
        ],
        reRenderOnLangChange: true,
        fallbackLang: 'en',
        defaultLang: 'en',
        missingHandler: {
          useFallbackTranslation: true,
          allowEmpty: !isDevMode(),
          logMissingKey: true,
        },
        // interpolation: ['<<<', '>>>']
      },
    }),
    provideTranslocoMessageformat(),
    provideTranslocoLocale({
      langToLocaleMapping: {
        en: 'en-US',
        es: 'es-ES',
        he: 'he-IL',
        pt: 'pt-BR',
      },
    }),
    provideTranslocoPersistLang({
      // storage: {
      //   useValue: cookiesStorage(),
      // },
      storage: {
        useValue: localStorage
      },
    }),
    SettingsService,
  ],
};
