<main class="px-6 sm:px-10 mlg:px-0 mpy-10 relative">
  <div class="mlg:ltr:bg-about-bg mlg:rtl:bg-about-bg-rtl 2xl:bg-cover bg-no-repeat py-10">
    <!-- Banner - Title -->
    <section
      class="bg-title-bg bg-no-repeat md:bg-[center_left_12.5rem] mlg:bg-[center_left_45rem] py-16 animate-fade-down">
      <h2 class="text-center text-4xl font-semibold" transloco="about.title"></h2>
    </section>

    <section class=" lg:py-28 sm:p-10 md:px-20 mlg:px-40">
      <article class="w-full mlg:w-1/2 text-grey animate-fade-right">
        <p class="" transloco="about.first-paragraph"></p>
        <ul class="flex flex-col py-12 list-image-[url(assets/images/icons/check.svg)] pl-6">
          <li class="pb-2 mb-2" transloco="about.first-bullet"></li>
          <li class="pb-2 mb-2" transloco="about.second-bullet"></li>
          <li class="pb-2 mb-2" transloco="about.third-bullet"></li>
        </ul>
        <p class="pb-16" transloco="about.second-paragraph"><br>
          <strong transloco="about.second-paragraph-bold"></strong>
        </p>
      </article>

      <article
        class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 bg-white rounded-2xl border border-greyLight py-10 w_[80%] xl:w-[60%] animate-jump-in">
        <div class="flex flex-col flex-1 items-center lg:border-r-2 border-dashed border-greyLight py-4 lg:py-0">
          <img src="assets/images/icons/face-icon.svg" alt="face icon" class="w-[3.5rem] h-[3.5rem]">
          <h4 class="font-semibold text-2xl py-4" transloco="about.card1-title"></h4>
          <span class="text-grey text-center text-sm px-4" transloco="about.card1-subtitle"></span>
        </div>
        <div class="flex flex-col flex-1 items-center lg:border-r-2 border-dashed border-greyLight py-4 lg:py-0">
          <img src="assets/images/icons/world-icon.svg" alt="world icon" class="w-[3.5rem] h-[3.5rem]">
          <h4 class="font-semibold text-2xl py-4" transloco="about.card2-title"></h4>
          <span class="text-grey text-center text-sm px-4" transloco="about.card2-subtitle"></span>
        </div>
        <div class="flex flex-col flex-1 items-center lg:border-r-2 border-dashed border-greyLight py-4 lg:py-0">
          <img src="assets/images/icons/tick-icon.svg" alt="tick icon" class="w-[3.5rem] h-[3.5rem]">
          <h4 class="font-semibold text-2xl py-4" transloco="about.card3-title"></h4>
          <span class="text-grey text-center text-sm px-4" transloco="about.card3-subtitle"></span>
        </div>
        <div class="flex flex-col flex-1 items-center pt-4 lg:pt-0">
          <img src="assets/images/icons/sim-icon.svg" alt="sim icon" class="w-[3.5rem] h-[3.5rem]">
          <h4 class="font-semibold text-2xl py-4" transloco="about.card4-title"></h4>
          <span class="text-grey text-center text-sm px-4" transloco="about.card4-subtitle"></span>
        </div>
      </article>
    </section>
  </div>
  
  <section class="hidden md:block 2xl:py-20 rounded-2xl animate-jump-in scroll-driven-show">
    <h4 class="bg-about-banner bg-no-repeat bg-center p-20 text-white text-3xl text-center">
      <span transloco="about.long-card-first"></span><br>
      <strong transloco="about.long-card-second"></strong><br>
      <span transloco="about.long-card-third"></span>
    </h4>
  </section>

  <!-- WE OFFER YOU -->
  <section class="w-full py-10 md:px-20 mlg:px-40 animate-fade-down scroll-driven-show">
    <h2 transloco="about.second-title"
      class="bg-title-bg bg-no-repeat md:bg-[center_left_10rem] mlg:bg-[center_left_35rem] text-center text-4xl font-semibold py-16">
    </h2>
    <article class="flex flex-col lg:flex-row lg:gap-10 pt-16 sm:pt-0">

      <ul class="list-image-[url(assets/images/icons/check.svg)] pl-6 text-grey mlg:w-[50%]">
        <li class="p-2" transloco="about.we-offer-bullet1"></li>
        <li class="p-2" transloco="about.we-offer-bullet2"></li>
        <li class="p-2" transloco="about.we-offer-bullet3"></li>
        <li class="p-2" transloco="about.we-offer-bullet4"></li>
      </ul>
      
      <ul class="list-image-[url(assets/images/icons/check.svg)] pl-6 text-grey mlg:w-[50%]">
        <li class="p-2" transloco="about.we-offer-bullet6"></li>
        <li class="p-2" transloco="about.we-offer-bullet7"></li>
        <li class="p-2" transloco="about.we-offer-bullet8"></li>
        <li class="p-2" transloco="about.we-offer-bullet5"></li>
      </ul>

    </article>
  </section>
</main>