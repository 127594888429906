import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ShipElement } from 'src/app/services/models/shipping-options.model';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslocoModule, TranslocoService } from '@ngneat/transloco';

@Component({
  selector: 'app-shipping-options',
  standalone: true,
  imports: [CommonModule, FormsModule, TranslocoModule, ReactiveFormsModule],
  templateUrl: './shipping-options.component.html',
  styleUrls: ['./shipping-options.component.scss'],
})
export class ShippingOptionsComponent {
  @Output() optionSelected: EventEmitter<string> = new EventEmitter<string>();
  @Output() pickupOption = new EventEmitter<boolean>();

  @Input() shippingOptions: ShipElement[] = [];
  @Input() isPickUpSelected: boolean = false;
  @Input() showPickUpOption: boolean = false;

  selectedShippingOption: ShipElement | undefined = undefined;
  selectedCountryId: number = 0;
  selectedOptionCheck: { [key: string]: boolean } = {};
  showPopover: boolean = false;

  constructor(private translocoService: TranslocoService) {}

  ngOnInit(): void {}

  onOptionChange(optionName: string) {

    if (optionName !== 'pickup') {
      if (this.selectedOptionCheck[optionName]) return;
      this.selectedOptionCheck = {};
      this.selectedOptionCheck[optionName] = true;
      this.isPickUpSelected = false;
      this.pickupOption.emit(false)
    } else {
      if (this.isPickUpSelected) return;
      this.selectedOptionCheck = {};
      this.pickupOption.emit(true)
      this.isPickUpSelected = true;
    }
    this.optionSelected.emit(optionName);
  }

  showPeriodPopover() {
    this.showPopover = true
    setTimeout(() => {
      this.showPopover = false
    }, 2500);
  }
  
  getTranslatedShippingOption(name: string): string {
    return this.translocoService.translate(`checkout.shipping.${name}`);
  }
}
