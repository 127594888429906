import { Component } from '@angular/core';
import { CommonModule, ViewportScroller } from '@angular/common';
import { TranslocoModule } from '@ngneat/transloco';
import { SearchInputComponent } from 'src/app/core/shared/search-input/search-input.component';
import { CustomerCarouselComponent } from 'src/app/core/shared/customer-carousel/customer-carousel.component';
import { FaqAccordionComponent } from 'src/app/core/shared/faq-accordion/faq-accordion.component';
import { ModalSearchInputComponent } from 'src/app/core/shared/modal-search-input/modal-search-input.component';
import { Router, RouterModule } from '@angular/router';
import { MarketService } from 'src/app/services/markets/markets.service';
import { Market } from 'src/app/services/models/markets.model';
import { HomeHowEsimWorkComponent } from 'src/app/core/shared/home-how-esim-work/home-how-esim-work.component';
import { HomeWhereToTravelComponent } from 'src/app/core/shared/home-where-to-travel/home-where-to-travel.component';
import { HomeWhyGlobusimComponent } from 'src/app/core/shared/home-why-globusim/home-why-globusim.component';
import { HomeHowToUseComponent } from 'src/app/core/shared/home-how-to-use/home-how-to-use.component';
import { LazyLoadImageModule } from 'ng-lazyload-image';
import { Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'app-home-page',
  standalone: true,
  imports: [
    CommonModule,
    RouterModule,
    TranslocoModule,
    SearchInputComponent,
    CustomerCarouselComponent,
    ModalSearchInputComponent,
    FaqAccordionComponent,
    HomeHowEsimWorkComponent,
    HomeWhereToTravelComponent,
    HomeWhyGlobusimComponent,
    HomeHowToUseComponent,
    LazyLoadImageModule
  ],
  templateUrl: './home-page.component.html',
  styleUrls: ['./home-page.component.scss'],
})
export class HomePageComponent {
  showModal: boolean = false;
  countries: Market[] = [];
  regions: Market[] = [];
  visibleCountries: Market[] = [];

  image = 'assets/images/backgrounds/home-bg.webp';
  imageMobile = 'assets/images/backgrounds/home-bg-mobile.webp';
  viewport = document.documentElement.clientWidth
  
  constructor(
    private marketService: MarketService,
    private router: Router,
    private scroller: ViewportScroller,
    private titleService: Title,
    private meta: Meta
  ) {}

  ngOnInit(): void {
    this.getMarkets();

    const currentUrl = this.router.url;

    if (currentUrl.includes('SimActivation=')) {
      this.router.navigate(['/activate-sim']);
    } else if (currentUrl.includes('United-StatesPlan=')) {
      this.router.navigate(['/get-your-sim/united-states/esim']);
    } else if (currentUrl.includes('EuropePlan=')) {
      this.router.navigate(['/get-your-sim/europe/data']);
    }
    
    this.updateMetaTags(
      'Globusim | Travel the world, stay connected',
      'esim, sim, data only, connectivity ',
      'Travel the world, stay connected'
    );
  }

  updateMetaTags(title: string, keywords: string, description: string) {
    this.titleService.setTitle(title);
    this.meta.updateTag({ name: 'keywords', content: keywords });
    this.meta.updateTag({ name: 'description', content: description });
    this.meta.updateTag({
      rel: 'canonical',
      href: 'https://globusim.com',
    });
  }

  ngAfterViewInit(): void {
    this.scroller.scrollToPosition([0, 0]);
  }

  onCloseModal() {
    this.showModal = false;
  }

  async getMarkets() {
    const data = await this.marketService.getMarkets<Market[]>();

    try {
      const uniqueCountries = new Set<Market>();
      const uniqueRegions = new Set<Market>();

      data.result.forEach((item) => {
        if (item.activeInSearch) {
          if (item.groupLevel === 2) {
            uniqueCountries.add(item);
          } else if (item.groupLevel === 1) {
            uniqueRegions.add(item);
          }
        }
      });

      this.countries = Array.from(uniqueCountries).slice(0, 6);
      this.regions = Array.from(uniqueRegions);
      this.visibleCountries = this.countries.slice(0, 12);
    } catch (err: any) {
      console.error('ERROR: ', err);
    }
  }
}
