<main class="p-10 md:px-16 mlg:px-36">
  <section class="flex items-center py-16 px-12">

    <div class="bg-icon-bg bg-no-repeat px-12 w-2/3 ml-16 animate-fade-down">
      <h2 class="text-[100px] font-semibold text-orange px-16">404</h2>
      <div class="flex flex-col items-center">
        <p class="text-center text-xl pb-6">Sorry, this page doesn't exist</p>
        <button routerLink="" routerLinkActive="router-link-active"  class="bg-orange px-8 py-3 rounded-full font-semibold text-white">Go back</button>
      </div>
    </div>

    <img src="assets/images/layers/Layer-1.svg" alt="layer 1" class="w-[18rem] h-full animate-fade-up">

  </section>
</main>