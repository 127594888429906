<aside class="rounded-xl shadow-lg border border-greyLight p-6 w-full mb-8">
  <ul class="flex flex-col gap-5">
    <li [class.router-link-active]="isActive('/profile/my-sim')">
      <a routerLink="/profile/my-sim" routerLinkActive="bg-orange text-white hover:bg-orange"
        class="flex px-2 py-4 hover:bg-greyLight rounded-xl">
        <svg class="w-6 h-6 mr-3" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g clip-path="url(#clip0_1101_6741)">
            <path
              d="M8.88211 0.644531H17.0156C17.7276 0.644531 18.3047 1.22164 18.3047 1.93359V20.0664C18.3047 20.7784 17.7276 21.3555 17.0156 21.3555H4.98438C4.27243 21.3555 3.69531 20.7784 3.69531 20.0664V5.83133C3.69531 5.26152 3.92167 4.71505 4.32459 4.31217L7.36295 1.27381C7.76583 0.870891 8.3123 0.644531 8.88211 0.644531Z"
              [ngStyle]="{'stroke': isActive('/profile/my-sim') ? '#fff' : '#9C9CA4'}" stroke-width="1.5"
              stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
            <path
              d="M7.5625 8.03516H14.4375C14.9121 8.03516 15.2969 8.4199 15.2969 8.89453V17.4883C15.2969 17.9629 14.9121 18.3477 14.4375 18.3477H7.5625C7.08787 18.3477 6.70312 17.9629 6.70312 17.4883V8.89453C6.70312 8.4199 7.08787 8.03516 7.5625 8.03516Z"
              [ngStyle]="{'stroke': isActive('/profile/my-sim') ? '#fff' : '#9C9CA4'}" stroke="#9C9CA4"
              stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M15.2969 15.7695H12.2891V18.3477" stroke="#9C9CA4" stroke-miterlimit="10" stroke-linecap="round"
              [ngStyle]="{'stroke': isActive('/profile/my-sim') ? '#fff' : '#9C9CA4'}" stroke-linejoin="round" />
            <path d="M6.70312 15.7695H9.71094V18.3477" stroke="#9C9CA4" stroke-miterlimit="10" stroke-linecap="round"
              [ngStyle]="{'stroke': isActive('/profile/my-sim') ? '#fff' : '#9C9CA4'}" stroke-linejoin="round" />
            <path d="M15.2969 10.6133H12.2891V8.03516" stroke="#9C9CA4" stroke-miterlimit="10" stroke-linecap="round"
              [ngStyle]="{'stroke': isActive('/profile/my-sim') ? '#fff' : '#9C9CA4'}" stroke-linejoin="round" />
            <path d="M6.70312 10.6133H9.71094V8.03516" stroke="#9C9CA4" stroke-miterlimit="10" stroke-linecap="round"
              [ngStyle]="{'stroke': isActive('/profile/my-sim') ? '#fff' : '#9C9CA4'}" stroke-linejoin="round" />
            <path d="M15.2969 13.1914H13.5781" stroke="#9C9CA4" stroke-miterlimit="10" stroke-linecap="round"
              [ngStyle]="{'stroke': isActive('/profile/my-sim') ? '#fff' : '#9C9CA4'}" stroke-linejoin="round" />
            <path d="M9.71094 15.7695V13.1914H6.70312" stroke="#9C9CA4" stroke-miterlimit="10" stroke-linecap="round"
              [ngStyle]="{'stroke': isActive('/profile/my-sim') ? '#fff' : '#9C9CA4'}" stroke-linejoin="round" />
          </g>
          <defs>
            <clipPath id="clip0_1101_6741">
              <rect width="22" height="22" fill="white" />
            </clipPath>
          </defs>
        </svg>
        Mi eSIM/SIM
      </a>
    </li>

    <li [class.router-link-active]="isActive('/profile/info')">
      <a routerLink="/profile/info" routerLinkActive="bg-orange text-white hover:bg-orange"
        class="flex px-2 py-4 hover:bg-greyLight rounded-xl">
        <ng-container *ngIf="isActive('/profile/info'); else elseTemplate">
          <img src="assets/images/icons/user-w.svg" alt="user icon" class="mr-3">
        </ng-container>
        <ng-template #elseTemplate>
          <img src="assets/images/icons/user.svg" alt="user icon" class="mr-3">
        </ng-template>
        Información de la cuenta
      </a>
    </li>

    <li [class.router-link-active]="isActive('/profile/notifications')">
      <a routerLink="/profile/notifications" routerLinkActive="bg-orange text-white hover:bg-orange"
        class="flex px-2 py-4 hover:bg-greyLight rounded-xl">
        <ng-container *ngIf="isActive('/profile/notifications'); else elseTemplate">
          <img src="assets/images/icons/notification-w.svg" alt="notification icon" class="mr-3" />
        </ng-container>
        <ng-template #elseTemplate>
          <img src="assets/images/icons/notification.svg" alt="notification icon" class="mr-3" />
        </ng-template>
        Notificaciones
      </a>
    </li>

    <li [class.router-link-active]="isActive('/profile/payment-methods')">
      <a routerLink="/profile/payment-methods" routerLinkActive="bg-orange text-white hover:bg-orange"
        class="flex px-2 py-4 hover:bg-greyLight rounded-xl">
        <ng-container *ngIf="isActive('/profile/payment-methods'); else elseTemplate">
          <img src="assets/images/icons/card-w.svg" alt="notification icon" class="mr-3" />
        </ng-container>
        <ng-template #elseTemplate>
          <img src="assets/images/icons/card.svg" alt="notification icon" class="mr-3" />
        </ng-template>
        Métodos de pago
      </a>
    </li>

    <li [class.router-link-active]="isActive('/profile/help')">
      <a routerLink="/profile/help" routerLinkActive="bg-orange text-white hover:bg-orange"
        class="flex px-2 py-4 hover:bg-greyLight rounded-xl">
        <svg class="w-6 h-6 mr-3" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M17 18.43H13L8.54999 21.39C7.88999 21.83 7 21.36 7 20.56V18.43C4 18.43 2 16.43 2 13.43V7.42993C2 4.42993 4 2.42993 7 2.42993H17C20 2.42993 22 4.42993 22 7.42993V13.43C22 16.43 20 18.43 17 18.43Z" [ngStyle]="{'stroke': isActive('/profile/help') ? '#fff' : '#9C9CA4'}" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M11.9998 11.36V11.15C11.9998 10.47 12.4198 10.11 12.8398 9.82001C13.2498 9.54001 13.6598 9.18002 13.6598 8.52002C13.6598 7.60002 12.9198 6.85999 11.9998 6.85999C11.0798 6.85999 10.3398 7.60002 10.3398 8.52002" [ngStyle]="{'stroke': isActive('/profile/help') ? '#fff' : '#9C9CA4'}" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M11.9955 13.75H12.0045" [ngStyle]="{'stroke': isActive('/profile/help') ? '#fff' : '#9C9CA4'}" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
          
        Centro de ayuda
      </a>
    </li>
  </ul>
</aside>