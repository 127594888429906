<main class="p-10 md:px-20 mlg:px-40">
  <section class="flex flex-col mlg:flex-row">

    <article class="w-full mlg:w-96 mlg:mx-auto animate-fade-right animate-duration-700">
      <app-profile-sidebar></app-profile-sidebar>
      <button class="hidden mlg:flex items-center border border-greyDark rounded-full px-28 py-2 mx-auto hover:border-grey">
        Cerrar Sesión
      </button>
    </article>

    <article class="mlg:px-8 py-4 w-full mlg:w-2/3 animate-fade-left animate-duration-700">
      <router-outlet></router-outlet>
    </article>
  </section>
</main>