<main class="py-10 pb-0 px-6 md:px-20 mlg:px-40">
  <!-- Banner - Title -->
  <section class="bg-title-bg bg-no-repeat md:bg-[center_left_10rem] mlg:bg-[center_left_35rem] py-16 animate-fade-down">
    <h2 class="text-center text-4xl font-semibold" transloco="faq.faq-title"></h2>
  </section>

  <section class="py-10 animate-fade-up">
    <app-faq-accordion></app-faq-accordion>
  </section>

  <img src="assets/images/layers/Layer-faq.png" alt="Faq image" class="mx-auto w-[11rem] h-fit">
</main>