import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DestinationsButtonsComponent } from '../destinations-buttons/destinations-buttons.component';
import { MarketsCardComponent } from '../markets-card/markets-card.component';
import { Market } from 'src/app/services/models/markets.model';
import { TranslocoModule } from '@ngneat/transloco';

@Component({
  selector: 'app-home-where-to-travel',
  standalone: true,
  imports: [CommonModule, DestinationsButtonsComponent, MarketsCardComponent, TranslocoModule],
  templateUrl: './home-where-to-travel.component.html',
  styleUrls: ['./home-where-to-travel.component.scss']
})
export class HomeWhereToTravelComponent {
  @Input() countries: Market[] = [];
  skeletonBoxes: number[] = [1, 2, 3, 4, 5, 6];
}
