<nav class="border-b border-greyLight">
  <div class="container mx-auto flex items-center justify-around">    
    <!-- Hamburger Menu -->
    <button aria-label="mobile menu" class="lg:hidden text-black pl-3 lg:pl-0" (click)="toggleMenu()">
      <svg xmlns="http://www.w3.org/2000/svg" class="w-8 h-8" fill="none" viewBox="0 0 24 24" stroke="currentColor">
        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6h16M4 12h16M4 18h16"></path>
      </svg>
    </button>

    <!-- Logo -->
    <div class="flex items-center">
      <a routerLink="" class="text-black font-semibold">
        <img src="assets/images/logo.webp" alt="Globusim Logo Desktop" class="hidden sm:block w-[9rem] sm:w-[13rem] h-full my-5" loading="lazy">
        <img src="assets/images/logo-mobile.webp" alt="Globusim Logo Mobile" class="sm:hidden w-[9rem] sm:w-[13rem] h-full my-5" loading="lazy">
      </a>
    </div>

    <!-- Navigation Links -->
    <div class="hidden lg:flex justify-center">
      <app-nav-links></app-nav-links>
    </div>
    
    <!-- Language Dropdown -->
    <div class="flex justify-end">
      <app-language-dropdown></app-language-dropdown>
    </div>
    
    <!-- Profile Picture & Dropdown -->
    <!-- <app-profile-dropdown></app-profile-dropdown> -->
    
  </div>

    <!-- Responsive Navigation Links (Initially Hidden on Larger Screens) -->
    <div class="lg:hidden shadow-2xl">
      <app-nav-links *ngIf="showMenu" (linkClick)="closeMenu()"></app-nav-links>
    </div>
</nav>
