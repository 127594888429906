import { Component, EventEmitter, Output, Input, Signal } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslocoModule } from '@ngneat/transloco';
import { SubmitOrderService } from '../../../services/submit-order/submit-order.service';
import {
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { ICountries } from 'src/app/services/models/countries.model';
import { CountryState, IState } from 'src/app/services/models/states.model';
import { CountriesService } from 'src/app/services/countries/countries.service';
import { StatesService } from 'src/app/services/states/states.service';
import {
  ICreateOrderRequest,
  IPayPalConfig,
  NgxPayPalModule,
} from 'ngx-paypal';
import { FixedCustomerPlan } from 'src/app/services/models/market-plans.model';

@Component({
  selector: 'app-checkout-payment',
  standalone: true,
  imports: [
    CommonModule,
    TranslocoModule,
    ReactiveFormsModule,
    FormsModule,
    NgxPayPalModule,
  ],
  templateUrl: './checkout-payment.component.html',
  styleUrls: ['./checkout-payment.component.scss'],
})
export class CheckoutPaymentComponent {
  @Output() completed = new EventEmitter<void>();
  @Output() finalizeOrder = new EventEmitter<void>();
  @Output() finalizeReloadOrder = new EventEmitter<void>();
  @Output() fillPayPalDetails = new EventEmitter<any>();
  @Output() changeMethod = new EventEmitter<string>();
  @Output() billingAddressChanged = new EventEmitter<boolean>();
  @Input() selectedMethod: string | null = 'card';
  @Input() isEsim: boolean = false;
  @Input() fxdCustomerPlan: FixedCustomerPlan = null;
  @Input() isReloadEsim: boolean = false;
  @Input() totalPrice: Signal<number>;

  isBillingAddress: boolean = false;
  paymentForm = this.submitOrderService.submitForm.get(
    'paymentForm'
  ) as FormGroup;
  shipAddress = this.submitOrderService.submitForm.get(
    'shipAddressForm'
  ) as FormGroup;
  formSubmitted: boolean = false;
  currentYear: number = new Date().getFullYear();
  yearsToShow: number[] = [];
  months: string[] = Array.from({ length: 12 }, (_, i) =>
    this.formatNumberOfMonth(i + 1)
  );
  countries: ICountries[] = [];
  states: IState[] = [];
  selectedCountryId: number = 0;
  selectedState: string = '';
  payPalConfig: IPayPalConfig;

  constructor(
    private submitOrderService: SubmitOrderService,
    private route: ActivatedRoute,
    private countriesService: CountriesService,
    private statesService: StatesService
  ) {
    this.paymentForm.get('cardNumber')
      ?.setValidators([Validators.required, this.creditCardValidator]);
    this.generateYearsOption();
  }

  ngOnInit(): void {
    // this.paymentForm.setValue({
    //   cardName: 'John Doe',
    //   cardNumber: '4242424242424242',
    //   cardExpirationMM: '12',
    //   cardExpirationYY: '2027',
    //   cardCVV: '123',
    // });
    
    if (this.fxdCustomerPlan || this.isReloadEsim) {
      this.isBillingAddress = true;
      this.billingAddressChanged.emit(this.isBillingAddress);
    }

    this.getCountries();
    this.shipAddress
      .get('country')
      ?.valueChanges.subscribe((selectedCountry: number) => {
        this.selectedCountryId = selectedCountry;
      });

    this.initPayPalConfig();
  }

  formatNumberOfMonth(num: number): string {
    return num < 10 ? `0${num}` : `${num}`;
  }

  generateYearsOption(): void {
    const yearsCount = 10;
    for (let i = 0; i < yearsCount; i++) {
      this.yearsToShow.push(this.currentYear + i);
    }
  }

  creditCardValidator(control: FormControl) {
    const cleanedNumber = control.value.replace(/\s+/g, '').replace(/-/g, '');

    if (cleanedNumber) {
      const digits = cleanedNumber.split('').map(Number); // Convert the number into an array of digits
      let sum = 0;
      let double = false;

      for (let i = digits.length - 1; i >= 0; i--) {
        // Start from the last digit
        let digit = digits[i];

        if (double) {
          digit *= 2;
          if (digit > 9) digit -= 9;
        }

        sum += digit;
        double = !double;
      }

      if (sum % 10 !== 0) return { invalidCreditCard: true };
    }

    return null;
  }

  togglePaymentMethod(method: string) {
    this.changeMethod.emit(method);
    if (this.selectedMethod === method) {
      this.selectedMethod = null;
    } else {
      this.selectedMethod = method;
    }
  }

  onCountryChange(event: Event) {
    const selectedCountryCode = Number(
      (event.target as HTMLSelectElement).value
    );
    this.getStates(selectedCountryCode);
  }

  initPayPalConfig(): void {
    this.payPalConfig = {
      currency: 'USD',
      // clientId:
      //   'AbwNcAvP35F7FpQCz0gy47nkxqiYj7a4NNkPQNPgUm-jsm4Wkvz61MOKC7kBJT5PIBMo5UwpaF_iuHPX',
      clientId: 'AZw8QTEtfs_zvsF9eGMe54vk_J8A-uLQx0ymiFm8bNZUCpofGDEsZq560FU9mjF1CQu8qP6QTjEVd3k2',
      createOrderOnClient: (data) =>
        <ICreateOrderRequest>{
          intent: 'CAPTURE',
          purchase_units: [
            {
              amount: { currency_code: 'USD', value: this.totalPrice().toString() },
              soft_descriptor: 'GLOBUSIM',
            },
          ],
          application_context: {
            brand_name: 'GLOBUSIM',
            landing_page: 'BILLING',
            user_action: 'PAY_NOW',
          },
        },
      advanced: {
        commit: 'true',
        extraQueryParams: [ { name: "disable-funding", value:"credit,card"} ]
      },
      style: {
        shape: 'rect',
        color: 'gold',
        layout: 'horizontal',
        label: 'paypal',
        height: 37,
      },
      onApprove: (data, actions) => {
        // console.log(
        //   'onApprove - transaction was approved, but not authorized',
        //   data,
        //   actions
        // );
        actions.order.get().then((details) => {
          console.log(
            'onApprove - you can get full order details inside onApprove: ',
            details
          );
          this.fillPayPalDetails.emit(details)
          this.isReloadEsim ? this.finalizeReloadOrder.emit() : this.finalizeOrder.emit()
        });
      },
      onClientAuthorization: (data) => {
        // console.log(
        //   'onClientAuthorization - you should probably inform your server about completed transaction at this point',
        //   data
        // );
        // this.showSuccess = true;
      },
      onCancel: (data, actions) => {
        console.log('OnCancel', data, actions);
        // this.showCancel = true;
      },
      onError: (err) => {
        console.log('OnError', err);
        // this.showError = true;
      },
      onClick: (data, actions) => {
        // console.log('onClick', data, actions);
        // this.resetStatus();
      },
    };
  }

  onBillingAddressChanged() {
    this.billingAddressChanged.emit(this.isBillingAddress);
  }

  onSubmitPayment() {
    this.formSubmitted = true;

    if (this.paymentForm.valid) {
      if (this.isBillingAddress && this.shipAddress.valid) {
        this.submitOrderService.setOrderData(this.shipAddress.value);
      }
      this.submitOrderService.setOrderData(this.paymentForm.value);
      this.formSubmitted = false;
    }
  }

  async getCountries() {
    const res = await this.countriesService.getCountries<ICountries[]>();

    if (res.isError) {
      console.error('ERROR: ', res.errorMessage);
      return;
    }

    this.countries = res.result;

    if (!this.isEsim) {
      const validCountryNumber = [101, 29, 35, 218];
      this.countries = this.countries.filter((country) =>
        validCountryNumber.includes(country.countryID)
      );
    }

    if (this.countries.length > 0) {
      this.selectedCountryId = 218;
      this.shipAddress
        .get('country')
        ?.setValue(this.selectedCountryId.toString());

      this.getStates(this.selectedCountryId);
    }
  }

  async getStates(countryId: number) {
    const res = await this.statesService.getStates<IState[]>(countryId);

    if (res.isSuccessful) {
      this.states = res.result;
    }
  }
}
