<section>
  <h3 class="font-bold text-xl">Método de pago asociado</h3>
  <p class="text-grey text-sm py-6">Tienes 1 tarjeta de crédito en tu cuenta.</p>

  <div (click)="showCreditCard = true" class="flex items-center rounded-2xl shadow-xl px-2 py-4 border border-dashed border-greyDark mb-12 w-max hover:scale-105 hover:shadow-md cursor-pointer">
    <div class="flex px-4">
      <img src="assets/images/Visa.png" alt="visa image" class="w-12 h-9 my-auto">
      <div class="px-4">
        <h6 class="font-semibold">Visa •••• 5078</h6>
        <span class="text-sm text-grey">Vence el 12/28</span>
      </div>
      <svg class="w-5 h-3 mx-1 my-auto" viewBox="0 0 11 7" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M5.10376 5.98301C5.21265 6.09392 5.35631 6.15 5.50002 6.15C5.64373 6.15 5.78739 6.09392 5.89628 5.98301L9.98715 1.8164C9.98716 1.81638 9.98717 1.81637 9.98718 1.81636C10.2044 1.59523 10.2041 1.23813 9.98722 1.01705C9.76865 0.794296 9.41329 0.794346 9.19466 1.01702L5.50002 4.78007L1.80541 1.01705C1.8054 1.01704 1.80539 1.01703 1.80538 1.01702C1.58676 0.794252 1.23145 0.794517 1.01289 1.01699C0.795686 1.23807 0.795733 1.59522 1.01285 1.81636L5.10376 5.98301Z" fill="#757575" stroke="#757575" stroke-width="0.3"/>
      </svg>
    </div>
  </div>
</section>

<section class="py-12 border-t border-greyLight">
  <button type="button" class="bg-orange text-white font-semibold px-16 py-3 rounded-full hover:scale-105">Agregar nuevo método de pago</button>
</section>

<ng-container *ngIf="showCreditCard">
  <app-credit-card [showCreditCard]="showCreditCard" (closeModalEvent)="onCloseModal()"></app-credit-card>
</ng-container>
