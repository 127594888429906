import { Component, EventEmitter, Output, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SubmitOrderService } from '../../../services/submit-order/submit-order.service';
import { TranslocoModule } from '@ngneat/transloco';
import { Datepicker, initTE } from 'tw-elements';
import { SimPlan } from 'src/app/services/models/market-plans.model';
import { ActivatedRoute } from '@angular/router';
import { GoogleTagManagerService } from 'src/app/services/google-tag-manager/google-tag-manager.service';
import { differenceInCalendarDays } from 'date-fns';

@Component({
  selector: 'app-checkout-date-step',
  standalone: true,
  imports: [CommonModule, FormsModule, ReactiveFormsModule, TranslocoModule],
  templateUrl: './checkout-date-step.component.html',
  styleUrls: ['./checkout-date-step.component.scss'],
})
export class CheckoutDateStepComponent {
  @Output() completed = new EventEmitter<void>();
  @Output() newSimQuantity = new EventEmitter<number>();
  @Output() changePeriod = new EventEmitter<number>();

  @Input() simQuantity: number = 1;
  @Input() simsPerPeriod: number = 1;
  @Input() selectedPlan: SimPlan;
  @Input() isActivation: boolean = false;

  formSubmitted: boolean = false;
  minStartDate: string = '';
  minEndDate: string = '';
  maxEndDate: string = '';
  totalDays: number | null = null;
  dateForm = this.submitOrderService.submitForm.get('dateForm') as FormGroup;
  simType: string = this.route.snapshot.params['type'];
  longTermMonths: number = 3;

  datePickerStart: any;
  datePickerEnd: any;

  constructor(
    private submitOrderService: SubmitOrderService,
    private route: ActivatedRoute,
    private gtmService: GoogleTagManagerService
  ) {
    const today = new Date();
    const year = today.getFullYear();
    const month = (today.getMonth() + 1).toString().padStart(2, '0');
    const day = today.getDate().toString().padStart(2, '0');
    this.minStartDate = `${month}/${day}/${year}`;
    this.minEndDate = this.calculateMinEndDate(this.minStartDate);
  }

  ngOnInit(): void {
    this.handleStartDateChange();
    this.handleEndDateChange();

    initTE({ Datepicker, Input });
    const datepickStart = document.getElementById(
      'start-datepicker-with-limits'
    );
    const datepickEnd = document.getElementById('datepicker-with-limits');

    this.datePickerStart = new Datepicker(datepickStart, {
      min: this.minStartDate,
    });
    this.datePickerEnd = new Datepicker(datepickEnd);
  }

  beginCheckoutGoogleTagManager() {
    const variant = this.isActivation ? "Activation" : "Normal";
    this.gtmService.beginCheckout(this.selectedPlan, this.simQuantity, variant);
  }

  handleStartDateChange() {
    this.dateForm.get('beginDate').valueChanges.subscribe((value) => {
      const selectedStartDate = value;
      const currentEndDate = this.dateForm.get('endDate').value;

      if (currentEndDate && selectedStartDate >= currentEndDate) {
        this.dateForm.patchValue({ endDate: '' }); // If the start date is after the current end date, empty the end date.
      }

      if (selectedStartDate) {
        const maxDaysPerActivation = this.selectedPlan.maxDaysPerActivation;
        const endDateExceedsLimit = this.calculateEndDateExceedsLimit(
          selectedStartDate,
          currentEndDate,
          maxDaysPerActivation
        );
        if (endDateExceedsLimit) {
          this.dateForm.patchValue({ endDate: '' });
        }

        // Reset end date if limit is exceeded
        if (endDateExceedsLimit) {
          this.minEndDate = this.calculateMinEndDate(selectedStartDate);
          this.maxEndDate = this.calculateMaxEndDate(
            selectedStartDate,
            maxDaysPerActivation
          );
          this.datePickerEnd.options.min = new Date(this.minEndDate);
          this.datePickerEnd.options.max = new Date(this.maxEndDate);
        }

        if (this.simType === 'data') {
          const endDate = this.calculateEndDateForData(selectedStartDate, maxDaysPerActivation);
          this.dateForm.patchValue({ endDate: endDate });
        } else if (this.simType === 'long-term') {
          // If it's a "long-term" plan, calculate the end date
          this.longTermMonths = 3;
          this.calculateEndDateForLongTerm(selectedStartDate);
        } else {
          this.minEndDate = this.calculateMinEndDate(selectedStartDate);
          const maxDaysPerActivation = this.selectedPlan.maxDaysPerActivation;
          this.maxEndDate = this.calculateMaxEndDate(
            selectedStartDate,
            maxDaysPerActivation
          );

          this.datePickerEnd.options.min = new Date(this.minEndDate);
          this.datePickerEnd.options.max = new Date(this.maxEndDate);
        }
      }
    });
  }

  handleEndDateChange() {
    this.dateForm.get('endDate').valueChanges.subscribe((value) => {
      const selectedEndDate = value;
      const selectedStartDate = this.dateForm.get('beginDate')?.value;

      if (selectedEndDate) {
        if (selectedStartDate) {
          this.calculateTotalDays();
        }
      }
    });
  }

  calculateMinEndDate(startDate: string): string {
    const start = new Date(startDate);
    start.setDate(start.getDate() + 1);

    const month = start.getMonth() + 1;
    const day = start.getDate();
    const year = start.getFullYear();
    const formattedDate = `${month.toString().padStart(2, '0')}/${day
      .toString()
      .padStart(2, '0')}/${year}`;

    return formattedDate;
  }

  calculateMaxEndDate(startDate: string, maxDaysPerActivation: number): string {
    const start = new Date(startDate);
    start.setDate(start.getDate() + (maxDaysPerActivation - 1));

    const month = start.getMonth() + 1;
    const day = start.getDate();
    const year = start.getFullYear();
    const formattedDate = `${month.toString().padStart(2, '0')}/${day
      .toString()
      .padStart(2, '0')}/${year}`;
    return formattedDate;
  }

  calculateEndDateForLongTerm(startDate, calculation = false) {
    const startD = this.dateForm.get('beginDate').value;
    const endD = this.dateForm.get('endDate').value;
    const start = new Date(startD);
    const end = new Date(endD);
  
    // Cuando se ejecuta por primera vez, agrega 90 días a la fecha de inicio
    if (startDate !== '') {
      start.setDate(start.getDate() + 89); // Agregar 90 días
      this.setFormattedDate(start);
    }
  
    // Calcular 30 días menos a la fecha final
    if (startDate === '' && !calculation && this.longTermMonths > 3) {
      this.longTermMonths--;
      end.setUTCDate(end.getUTCDate() - 30); // Restar 30 días
      this.setFormattedDate(end);
    }
  
    // Calcular 30 días más a la fecha final
    if (startDate === '' && calculation && startD) {
      this.longTermMonths++;
      end.setUTCDate(end.getUTCDate() + 30); // Agregar 30 días
      this.setFormattedDate(end);
    }
  }
  
  setFormattedDate(date) {
    const month = date.getUTCMonth() + 1;
    const day = date.getUTCDate();
    const year = date.getUTCFullYear();
    const formattedDate = `${month.toString().padStart(2, '0')}/${day.toString().padStart(2, '0')}/${year}`;
    this.dateForm.get('endDate').setValue(formattedDate);
  }

  calculateEndDateForData(startDate: string, maxDaysPerActivation: number): string {
    const start = new Date(startDate);
    start.setDate(start.getDate() + (maxDaysPerActivation - 1));

    const month = start.getMonth() + 1;
    const day = start.getDate();
    const year = start.getFullYear();
    const formattedDate = `${month.toString().padStart(2, '0')}/${day
      .toString()
      .padStart(2, '0')}/${year}`;
    return formattedDate;
  }

  calculateTotalDays() {
    const beginDate = this.dateForm.get('beginDate')?.value;
    const endDate = this.dateForm.get('endDate')?.value;

    if (beginDate && endDate) {
        const start = new Date(beginDate);
        const end = new Date(endDate);
        
        // Calcula la diferencia en días calendario
        const daysDifference = differenceInCalendarDays(end, start) + 1;

        this.totalDays = daysDifference;
        this.simsPerPeriod = Math.ceil(this.totalDays / 30);
        this.changePeriod.emit(this.simsPerPeriod);
        this.dateForm.patchValue({ simsPerPeriod: this.simsPerPeriod });
    } else {
        this.totalDays = null; // Si una de las fechas está vacía, el número total de días será null.
    }
}

  calculateEndDateExceedsLimit(
    selectedStartDate: string,
    currentEndDate: string,
    maxDaysPerActivation: number
  ): boolean {
    if (!currentEndDate) return false;

    const start = new Date(selectedStartDate);
    const end = new Date(currentEndDate);
    const timeDifference = end.getTime() - start.getTime();
    const daysDifference = timeDifference / (1000 * 3600 * 24); // Convert difference into days
    return daysDifference > maxDaysPerActivation;
  }

  changeSimQty(action: boolean) {
    if (action) this.simQuantity += this.selectedPlan.quantity; // Addition
    if (!action && this.simQuantity > this.selectedPlan.quantity)
      this.simQuantity -= this.selectedPlan.quantity; //  Substraction

    this.newSimQuantity.emit(this.simQuantity);
    this.dateForm.patchValue({ simQuantity: this.simQuantity });
  }

  onSubmitDateForm() {
    this.formSubmitted = true;

    if (this.dateForm.valid) {
      this.completed.emit();
      this.formSubmitted = false;

      this.submitOrderService.setOrderData(this.dateForm.value);

      this.beginCheckoutGoogleTagManager();
    }
  }
}
