import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslocoModule, TranslocoService } from '@ngneat/transloco';
import { BlogService } from 'src/app/services/blog/blog.service';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { BlogPost } from 'src/app/services/models/blog-model';
import { MarkdownModule } from 'ngx-markdown';
import { Subscription } from 'rxjs';
import { BlogArticleContentComponent } from 'src/app/core/shared/blog-article-content/blog-article-content.component';
import { SpinnerComponent } from 'src/app/core/shared/spinner/spinner.component';
import { LazyLoadImageModule } from 'ng-lazyload-image';
import { Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'app-blog-article-page',
  standalone: true,
  imports: [
    CommonModule,
    TranslocoModule,
    MarkdownModule,
    BlogArticleContentComponent,
    SpinnerComponent,
    LazyLoadImageModule,
    RouterModule
  ],
  templateUrl: './blog-article-page.component.html',

  styleUrls: ['./blog-article-page.component.scss'],
})
export class BlogArticlePageComponent {
  article: BlogPost;
  articleId: number;
  translatedMarkdown: string;
  isLoading: boolean = false;
  viewport = document.documentElement.clientWidth;
  langChangeSubscription: Subscription;

  constructor(
    private translocoService: TranslocoService,
    private blogService: BlogService,
    private route: ActivatedRoute,
    private titleService: Title,
    private router: Router,
    private meta: Meta
  ) {}

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      const lang = params['lang'];
      this.translocoService.setActiveLang(lang);
      this.articleId = Number(params['id']);
      this.loadArticle(this.articleId);
    });

    this.langChangeSubscription = this.translocoService.langChanges$
      .subscribe(() => {
        console.log("langChangeSubscription");
        this.translateContent();
        this.updateUrl();
      });

    this.updateMetaTags(
      `Globusim | Blog | Post`,
      'esim, sim, data only, connectivity ',
      'Travel the world, stay connected'
    );
  }

  ngOnDestroy(): void {
    if (this.langChangeSubscription) {
      this.langChangeSubscription.unsubscribe();
    }
  }

  private async loadArticle(id: number) {
    this.isLoading = true;
    try {
      const res = await this.blogService.getArticleById<BlogPost>(id);
      if (res.isSuccessful) {
        this.article = res.result;
        this.translateContent();
        this.updateUrl();
      }
    } catch (error) {
      console.log(error);
    } finally {
      this.isLoading = false;
    }
  }

  private translateContent() {
    if (this.article) {
      const activeLang = this.translocoService.getActiveLang();
      this.translocoService.selectTranslate(this.article.content, {}, activeLang)
        .subscribe((translatedContent: string) => {
          this.translatedMarkdown = translatedContent;
        });
    }
  }

  private updateUrl() {
    if (this.article) {
      const activeLang = this.translocoService.getActiveLang();
      this.translocoService.selectTranslate(this.article.title, {}, activeLang).subscribe((translatedTitle: string) => {
        const titleSlug = translatedTitle.toLowerCase().replace(/\s+/g, '-').replace(/[^a-z0-9-]/g, '');
        this.router.navigate(['blog', activeLang, this.articleId, titleSlug]);
      });
    }
  }

  updateMetaTags(title: string, keywords: string, description: string) {
    this.titleService.setTitle(title);
    this.meta.updateTag({ name: 'keywords', content: keywords });
    this.meta.updateTag({ name: 'description', content: description });
    this.meta.updateTag({
      rel: 'canonical',
      href: 'https://globusim.com/blog',
    });
  }
}
