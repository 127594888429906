import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ContactFormComponent } from 'src/app/core/shared/contact-form/contact-form.component';
import { TranslocoModule } from '@ngneat/transloco';
import { Meta, Title } from '@angular/platform-browser';
@Component({
  selector: 'app-contact-page',
  standalone: true,
  imports: [CommonModule, ContactFormComponent, TranslocoModule],
  templateUrl: './contact-page.component.html',
  styleUrls: ['./contact-page.component.scss']
})
export class ContactPageComponent {

  constructor(
    private titleService: Title,
    private meta: Meta
  ) {}

  ngOnInit(): void {
    this.updateMetaTags(
      'Globusim | Contact Us',
      'esim, sim, data only, connectivity ',
      'Travel the world, stay connected'
    );
  }

  updateMetaTags(title: string, keywords: string, description: string) {
    this.titleService.setTitle(title);
    this.meta.updateTag({ name: 'keywords', content: keywords });
    this.meta.updateTag({ name: 'description', content: description });
    this.meta.updateTag({
      rel: 'canonical',
      href: 'https://globusim.com/contact',
    });
  }
}
