
export const ControllerApiList = {
    WebApi: {
      IpLocation: "api/IpLocation/GetIpLocation",
      States: "api/Country/GetStatesByContryID",
      GetShippingOptions: "api/simorder/GetSimShippingOptions",
      GetSimPrice: "api/simorder/GetSimPrices",
      PromoCodeValidation: "api/SimOrder/ValidateCoupon",
      SubmitOrder: "api/SimOrder/DoValidatedSimOrder",
      SubmitReload: "api/SimOrder/DoValidatedReloadData",
      ValidateEsim: "api/SimOrder/ValidateESimPhone",
      ActivateSIM: "api/SimOrder/ValidatePhoneInStore",
      ContactUsInquiry: "api/Inquiry/CreateNonCustomerLead",
      GetPickUpStoreLocations: "api/simorder/GetPickUpStoreLocations",
      GetEnvelopeStores: "api/simorder/GetEnvelopePickupStores",
      CaptureEnvelope: "api/simorder/CaptureEnvelope",
      GetSimCustomer: "api/simorder/GetSimCustomer",
      SimPlans: "api/simorder/getsimplans",   //migrado  
      Countries: "api/country/GetCountries",   //migrado
      GetSimPlanMarket: "api/Globusim/GetSimPlansMarket",   //migrado
    },
    GlobusimApi: {
      GetSimPlanMarket: "api/globusim/sim-plan-market",
      GetSimPlans: "api/globusim/sim-plans",
      ValidateActivationPhone: "api/globusim/plans-for-activation",
      ValidateReloadPhone: "api/globusim/plans-for-reload",
      GetPickUpStores: "api/globusim/envelope-pickup-stores",
      GetCountries: "api/globalhub/countries",
      GetStates: "api/globalhub/countries",
      GetBlog: "api/globusim/posts/articles"
    }
}

