<div id="faq">
  <div *ngFor="let item of faqItems | slice:0:(faqSrc === 'Home' ? 7 : 15)"
    class="border-b border-greyLight">
    <h2 class="mb-0" [id]="'heading' + item.h">
      <button
        class="group relative flex w-full items-center bg-white px-5 py-8 text-left text-sm transition [&:not([data-te-collapse-collapsed])]:text-orange"
        type="button"
        data-te-collapse-init
        data-te-collapse-collapsed
        [attr.data-te-target]="'#collapse' + item.h"
        aria-expanded="false"
        [attr.aria-controls]="'collapse' + item.h">
        <strong class="rtl:ml-auto" [transloco]="item.q"></strong>
        <span
          class="ltr:-mr-1 ltr:ml-auto rtl: h-5 w-5 shrink-0 rotate-[-180deg] transition-transform duration-200 ease-in-out group-[[data-te-collapse-collapsed]]:mr-0 group-[[data-te-collapse-collapsed]]:rotate-0 group-[[data-te-collapse-collapsed]]:fill-[#212529] motion-reduce:transition-none">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            class="h-6 w-6">
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
          </svg>
        </span>
      </button>
    </h2>
    <div
      [id]="'collapse' + item.h"
      class="!visible hidden"
      data-te-collapse-item
      [attr.aria-labelledby]="'heading' + item.h"
      data-te-parent="#faq">
      <div class="px-5 pb-8" [innerHTML]="item.a | transloco">
      </div>
    </div>
  </div>

  <div
    class="border-b border-greyLight hidden">
    <h2 class="mb-0" id="headingTwo">
      <button
        class="group relative flex w-full items-center bg-white px-5 py-4 text-left text-sm transition [&:not([data-te-collapse-collapsed])]:text-orange"
        type="button"
        data-te-collapse-init
        data-te-collapse-collapsed
        data-te-target="#collapseTwo"
        aria-expanded="false"
        aria-controls="collapseTwo">
        <strong>Accordion de coso </strong>
        <span
          class="-mr-1 ml-auto h-5 w-5 shrink-0 rotate-[-180deg] transition-transform duration-200 ease-in-out group-[[data-te-collapse-collapsed]]:mr-0 group-[[data-te-collapse-collapsed]]:rotate-0 group-[[data-te-collapse-collapsed]]:fill-[#212529] motion-reduce:transition-none">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            class="h-6 w-6">
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
          </svg>
        </span>
      </button>
    </h2>
    <div
      id="collapseTwo"
      class="!visible hidden"
      data-te-collapse-item
      aria-labelledby="headingTwo"
      data-te-parent="#faq">
      <div class="px-5 py-4">
        colapsado de coso
      </div>
    </div>
  </div>

</div>