<button type="button"
  class="scroll-driven-show-cards flex p-4 xl:p-6 w-full md:w-[280px] h-[110px] min-w-[260px] shadow-2xl hover:shadow-lg rounded-xl border border-greyDark transition delay-75 duration-100 ease-in-out hover:scale-105 mx-auto"
  (click)="goToMarketPage()">
  <img [src]="country?.groupName?.toLowerCase() | appFlags" [alt]="country.groupName + ' flag'"
    class="w-[3.5rem] h-[3.5rem] rounded-full my-auto" />
  <div class="flex flex-col items-start my-auto ltr:pl-4 rtl:pr-4">
    <h3 class="font-semibold text-base capitalize" [transloco]="'markets.' + country.groupName.toLowerCase().replaceAll(' ', '-')"></h3>
    <p class="flex text-sm text-grey">
      <span transloco="search-bar.since"></span>&nbsp;
      <span [transloco]="'markets.lower-price.' + country.groupName.toLowerCase().replaceAll(' ', '-')"></span>
    </p>
  </div>
</button>
