<div class="bg-no-repeat p-6 bg-cover rounded-3xl" [style.background-image]="'url(assets/images/' + (region?.groupName.toLowerCase() || 'default') + '-map.png)'">
  <div class="flex pb-4">
    <img [src]="'assets/images/flags/' + region?.groupName?.toLowerCase().replaceAll(' ', '-') + '-flag.svg'" [alt]="region.groupName + ' flag'" class="w-[3.5rem] h-[3.5rem] rounded-full my-auto" />  
    <div class="px-4">
      <h5 class="text-lg font-semibold" [transloco]="'markets.' + region.groupName.toLowerCase().replaceAll(' ', '-')"></h5>
      <p class="text-xs text-grey">
        <span class="text-orange" transloco="search-bar.since"></span>&nbsp;
        <span class="text-orange" [transloco]="'markets.lower-price.' + region.groupName.toLowerCase().replaceAll(' ', '-')"></span>
        
      </p>
    </div>
  </div>
  <button (click)="goToMarketPage()" class="bg-orange rounded-full px-12 py-3 text-white" transloco="btn.hire"></button>
</div>