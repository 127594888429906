<div class="relative pr-4">
  <button class="flex flex-1 items-center text-black" (click)="dropdownVisible = !dropdownVisible">
    <span class="mr-1 sm:mr-2 pr-1 sm:pr-2 text-xs font-medium border-r-2 border-greyDark rtl:border-none ml-2"> {{activeLang}} </span>
    <img [src]="'assets/images/lang/' + activeLang + '.svg'" alt="Language flag" class="w-[1.5rem] h-[1.5rem] sm:w-[2rem] sm:h-[2rem] rounded-full">
    <span class="{{ dropdownVisible ? 'origin-center rotate-180 transition-all ease-linear' : ''}}">
      <svg class="w-4 h-2 mx-1" viewBox="0 0 11 7" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M5.10376 5.98301C5.21265 6.09392 5.35631 6.15 5.50002 6.15C5.64373 6.15 5.78739 6.09392 5.89628 5.98301L9.98715 1.8164C9.98716 1.81638 9.98717 1.81637 9.98718 1.81636C10.2044 1.59523 10.2041 1.23813 9.98722 1.01705C9.76865 0.794296 9.41329 0.794346 9.19466 1.01702L5.50002 4.78007L1.80541 1.01705C1.8054 1.01704 1.80539 1.01703 1.80538 1.01702C1.58676 0.794252 1.23145 0.794517 1.01289 1.01699C0.795686 1.23807 0.795733 1.59522 1.01285 1.81636L5.10376 5.98301Z" fill="#757575" stroke="#757575" stroke-width="0.3"/>
      </svg>
    </span>
  </button>
  <ul *ngIf="dropdownVisible" class="absolute right-0 mt-2 bg-white p-2 rounded shadow w-max">
    <li *ngFor="let lang of availableLangs;" (click)="changeLang(lang.id)" class="flex p-2 hover:bg-greyLight rounded-sm {{ activeLang === lang.id ? 'border-l-2 border-orange' : ''}}">
      <img [src]="'assets/images/lang/' + lang.id + '.svg'" alt="Language flag" class="w-[1.5rem] h-[1.5rem] rounded-full">
      <span class="block text-gray-800 px-2 cursor-pointer">{{lang.label}}</span>
    </li>
  </ul>
</div>