<section class="lg:py-6 lg:px-14">
  <h3 class="text-center md:text-start font-bold text-2xl py-4" transloco="checkout.billing.title-1"></h3>

  <article class="">
    <form [formGroup]="addressForm" (ngSubmit)="onSubmitAddressForm()">
      <!--Full Name input-->
      <div class="mb-4">
        <label for="fullName" class=" mb-0 max-w-[90%] pt-[0.37rem] leading-[1.6] font-medium"
          transloco="checkout.billing.form-name"></label>
        <input type="text" formControlName="fullName"
          class="{{addressForm.get('fullName')?.invalid && formSubmitted ? 'border-danger' : 'border-greyDark'}} block min-h-[auto] w-full rounded border  bg-transparent px-3 py-[0.32rem] leading-[1.6] outline-none focus:border-grey peer-invalid:border-red"
          id="fullName" />
      </div>

      <!--Address input-->
      <div class="mb-4">
        <label for="address" class="mb-0 max-w-[90%] pt-[0.37rem] leading-[1.6] font-medium"
          transloco="checkout.billing.form-address"></label>
        <input type="text" formControlName="address"
          class="{{addressForm.get('address')?.invalid && formSubmitted ? 'border-danger': 'border-greyDark'}} block min-h-[auto] w-full rounded border bg-transparent px-3 py-[0.32rem] leading-[1.6] outline-none focus:border-grey"
          id="address" />
      </div>

      <!-- Country & State inputs -->
      <div class="flex gap-4 sm:gap-8">
        <!-- COUNTRY FIELD -->
        <div class="mb-4 w-1/2">
          <label for="country" class="mb-0 max-w-[90%] pt-[0.37rem] leading-[1.6] font-medium"
            transloco="checkout.billing.form-country"></label>
          <select type="text" formControlName="country" (change)="onCountryChange($event)"
            class="{{addressForm.get('country')?.invalid && formSubmitted ? 'border-danger': 'border-greyDark'}} text-sm sm:text-base block min-h-[auto] w-full rounded border bg-transparent px-3 py-[0.32rem] leading-[1.6] outline-none focus:border-grey"
            id="country">
            <ng-container *ngFor="let country of countries">
              <option class="" value={{country.countryID}}>{{country.countryName}}</option>
            </ng-container>
          </select>
        </div>
        <!-- STATE FIELD -->
        <div class="mb-4 w-1/2">
          <label for="state" class="mb-0 max-w-[90%] pt-[0.37rem] leading-[1.6] font-medium"
            transloco="checkout.billing.form-state"> </label>
          <select type="text" formControlName="state"
            class="{{addressForm.get('state')?.invalid && formSubmitted ? 'border-danger': 'border-greyDark'}} block min-h-[auto] w-full rounded border bg-transparent px-3 py-[0.32rem] leading-[1.6] outline-none focus:border-grey"
            id="state">
            <ng-container *ngFor="let state of states">
              <option class="" value={{state.stateID}}>{{state.state}}</option>
            </ng-container>
          </select>

        </div>
      </div>

      <!-- City & zip inputs -->
      <div class="flex gap-4 sm:gap-8">
        <!-- CITY FIELD -->
        <div class="mb-4 w-1/2">
          <label for="city" class="mb-0 max-w-[90%] pt-[0.37rem] leading-[1.6] font-medium"
            transloco="checkout.billing.form-city"></label>
          <input type="text" formControlName="city" placeholder=""
            class="{{addressForm.get('city')?.invalid && formSubmitted ? 'border-danger': 'border-greyDark'}} block min-h-[auto] w-full rounded border bg-transparent px-3 py-[0.32rem] leading-[1.6] outline-none focus:border-grey"
            id="city" />
        </div>
        <!-- ZIP CODE FIELD -->
        <div class="mb-4 w-1/2">
          <label for="zipCode" class="mb-0 max-w-[90%] pt-[0.37rem] leading-[1.6] font-medium"
            transloco="checkout.billing.form-zip-code"></label>
          <input type="text" formControlName="zipCode"
            class="{{addressForm.get('zipCode')?.invalid && formSubmitted ? 'border-danger': 'border-greyDark'}} block min-h-[auto] w-full rounded border bg-transparent px-3 py-[0.32rem] leading-[1.6] outline-none focus:border-grey"
            id="zipCode" />
        </div>
      </div>


      <!-- Remember address checkbox -->
      <!-- <div class="mb-4 flex items-center justify-between">
        <div class="mb-[0.125rem] block min-h-[1.5rem] pl-[1.5rem]">
          <input
            class="relative float-left -ml-[1.5rem] mr-[6px] mt-[0.15rem] h-[1.125rem] w-[1.125rem] appearance-none rounded-full border-[0.125rem] border-solid border-neutral-300 outline-none before:pointer-events-none before:absolute before:h-[0.875rem] before:w-[0.875rem] before:scale-0 before:rounded-full before:bg-transparent before:opacity-0 before:shadow-[0px_0px_0px_13px_transparent] before:content-[''] checked:border-orange checked:bg-orange checked:before:opacity-[0.16] checked:after:absolute checked:after:-mt-px checked:after:ml-[0.25rem] checked:after:block checked:after:h-[0.8125rem] checked:after:w-[0.375rem] checked:after:rotate-45 checked:after:border-[0.125rem] checked:after:border-l-0 checked:after:border-t-0 checked:after:border-solid checked:after:border-white checked:after:bg-transparent checked:after:content-[''] hover:cursor-pointer hover:before:opacity-[0.04] hover:before:shadow-[0px_0px_0px_13px_rgba(0,0,0,0.6)] focus:shadow-none focus:transition-[border-color_0.2s] focus:before:scale-100 focus:before:opacity-[0.12] focus:before:shadow-[0px_0px_0px_13px_rgba(0,0,0,0.6)] focus:before:transition-[box-shadow_0.2s,transform_0.2s] focus:after:absolute focus:after:z-[1] focus:after:block focus:after:h-[0.875rem] focus:after:w-[0.875rem] focus:after:rounded-[0.125rem] focus:after:content-[''] checked:focus:before:scale-100 checked:focus:before:shadow-[0px_0px_0px_13px_#3b71ca] checked:focus:before:transition-[box-shadow_0.2s,transform_0.2s] checked:focus:after:-mt-px checked:focus:after:ml-[0.25rem] checked:focus:after:h-[0.8125rem] checked:focus:after:w-[0.375rem] checked:focus:after:rotate-45 checked:focus:after:rounded-none checked:focus:after:border-[0.125rem] checked:focus:after:border-l-0 checked:focus:after:border-t-0 checked:focus:after:border-solid checked:focus:after:border-white checked:focus:after:bg-transparent"
            type="checkbox" value="" id="checkAddress" />
          <label class="inline-block pl-[0.15rem] hover:cursor-pointer text-sm text-grey" for="checkAddress" transloco="checkout.billing.form-save-address">
          </label>
        </div>
      </div> -->

      <h3 class="font-bold text-2xl py-4" transloco="checkout.billing.title-2"></h3>

      <!--Email input-->
      <div class="mb-4">
        <label for="email" class="mb-0 max-w-[90%] pt-[0.37rem] leading-[1.6] font-medium"
          transloco="checkout.billing.form-email"> </label>
        <input type="email" formControlName="email"
          class="{{addressForm.get('email')?.invalid && formSubmitted ? 'border-danger': 'border-greyDark'}} block min-h-[auto] w-full rounded border bg-transparent px-3 py-[0.32rem] leading-[1.6] outline-none focus:border-grey"
          id="email" />
      </div>

      <!-- Confirm Email input-->
      <div class="mb-4" *ngIf="isEsim">
        <label for="confirmEmail" class="mb-0 max-w-[90%] pt-[0.37rem] leading-[1.6] font-medium"
          transloco="checkout.billing.form-confirm-email"> </label>
        <input type="email" formControlName="confirmEmail"
          class="{{addressForm.get('confirmEmail')?.invalid && formSubmitted || addressForm.get('confirmEmail')?.value !== addressForm.get('email')?.value && formSubmitted ? 'border-danger': 'border-greyDark'}} block min-h-[auto] w-full rounded border bg-transparent px-3 py-[0.32rem] leading-[1.6] outline-none focus:border-grey"
          id="confirmEmail" />
      </div>

      <!--Phone input-->
      <div class="flex flex-col mb-4">
        <label for="phoneInput" class="mb-0 max-w-[90%] pt-[0.37rem] leading-[1.6] font-medium"
          transloco="checkout.billing.form-phone"></label>
        <input #phoneInput type="tel" formControlName="phone" id="phoneInput"
          class="{{addressForm.get('phone')?.invalid && formSubmitted ? 'border-danger': 'border-greyDark'}} block min-h-[auto] w-full rounded border bg-transparent px-3 py-[0.32rem] leading-[1.6] outline-none focus:border-grey" />
      </div>

      <!-- Shipping Options -->
      <ng-container *ngIf="!isEsim && !isActivation">
        <div class="{{ addressForm.invalid ? 'pointer-events-none opacity-25' : ''}}">
          <h3 class="font-bold text-2xl py-4" transloco="checkout.billing.title-3"></h3>
          <!-- SHIPPING OPTIONS PER COUNTRY -->
          <app-shipping-options [shippingOptions]="shippingOptions" (pickupOption)="onPickUpSelected($event)"
            [isPickUpSelected]="isPickUpSelected" [showPickUpOption]="showPickUpOption"
            (optionSelected)="handleOptionSelected($event)"></app-shipping-options>
          <!-- PICK UP CITY  -->
          <article *ngIf="isPickUpSelected" class="my-4">
            <label for="pickUpOptions" class="mb-0 max-w-[90%] pt-[0.37rem] leading-[1.6] font-medium"
              transloco="checkout.shipping.subtitle-pick"></label>
            <select id="pickUpOptions" (change)="onStoreLocationChange($event)"
              class="block min-h-[auto] w-full rounded border border-greyDark bg-transparent px-3 py-[0.32rem] leading-[1.6] outline-none focus:border-grey">
              <option value="null" selected disabled transloco="checkout.shipping.select-city"></option>
              <option *ngFor="let location of storeLocationStates" [value]="location">
                {{ location }}
              </option>
            </select>
          </article>

          <!-- CHOOSE A STORE -->
          <div *ngIf="isPickUpSelected" class="md:mx-8">
            <h5 class="uppercase font-medium text-grey text-center py-4" transloco="checkout.shipping.choose-store">
            </h5>
            <div class="rounded-xl border border-greyDark h-32 overflow-y-auto">
              <div *ngFor="let store of selectedLocations"
                class="{{envelopeObj && envelopeObj.locationID === store.locationID.toString() ? 'bg-orange text-white pointer-events-none' : ''}} flex hover:bg-orange hover:text-white py-2 px-4 cursor-pointer"
                (click)="showStoreData(store)">
                <div class="flex flex-col flex-1">
                  <h6 class="text-sm font-semibold" [transloco]="'pickup.store_name_' + store.locationID"></h6>
                  <p class="text-sm " [transloco]="'pickup.store_address_' + store.locationID"> </p>
                </div>
                <div *ngIf="envelopeObj && envelopeObj.locationID === store.locationID.toString()"
                  class="flex items-center">
                  <img src="assets/images/icons/check-w.svg" alt="" class="w-[1.5rem] h-[1.5rem]">

                </div>
              </div>

            </div>
          </div>
        </div>
      </ng-container>

      <!-- Submit button -->
      <div class="flex items-center justify-center mt-12">
        <button type="submit" *ngIf="!isLoading"
          class="{{ !addressForm.valid || ( !isEsim && !isActivation && shippingOption === undefined ) ? 'hover:bg-red-500 opacity-50' : 'hover:scale-105'}} inline-block w-full rounded-full bg-orange px-7 pb-2.5 pt-3 text-sm font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out"
          data-te-ripple-init data-te-ripple-color="light" transloco="checkout.billing.form-btn-send">
        </button>
        <ng-container *ngIf="isLoading">
          <app-spinner-small></app-spinner-small>
        </ng-container>
      </div>

    </form>
  </article>
</section>