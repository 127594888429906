<main class="py-10 px-6 md:px-20 xl:px-40">
  <!-- Banner - Title -->
  <section class="bg-title-bg bg-no-repeat md:bg-[center_left_10rem] mlg:bg-[center_left_35rem] py-16 animate-fade-down">
    <h1 class="text-center text-4xl font-semibold" transloco="blog.title"></h1>
  </section>

  <section *ngIf="latest">
    <!-- LATEST POST -->

    <article  [style.background-image]="imageUrl" class="relative p-6 sm:p-12 rounded-xl my-12 bg-no-repeat bg-cover cursor-pointer" (click)="goToPost(latest)">
      <div class="absolute inset-0 bg-black opacity-50 rounded-xl"></div> 
      <div class="flex flex-col items-center md:items-start smd:pt-20 relative z-10"> 
        <button class="bg-orange text-white text-xs px-2 py-1 rounded my-4 " [transloco]="'blog.category-' + latest.id"></button>
        <h2 class="text-xl text-white font-semibold mb-4" [transloco]="'blog.title-' + latest.id"></h2>
        <p class="text-sm text-white">{{ latest.date | date:'medium' }}</p>
      </div>
    </article>
    <!-- ALL POST -->
    <article *ngIf="articles" class="flex flex-wrap justify-around gap-4">
      <ng-container *ngFor="let article of articles">
        <app-blog-card [article]="article"></app-blog-card>
      </ng-container>
    </article>
  </section>
  
  <ng-container *ngIf="isLoading">
    <app-spinner></app-spinner>
  </ng-container>
  
</main>

