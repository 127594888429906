import { HttpClient } from '@angular/common/http';
import { Injectable, Signal, computed, signal } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { ControllerApiList } from '../models/controllers/ControllerList';
import { SettingsService } from '../settings.service';

@Injectable({
  providedIn: 'root'
})
export class SimPriceService {

  constructor(private http: HttpClient, private settings:SettingsService) {}



  async getSimPrice(order: any) {
    const res = this.http.post<any>(`${this.settings.getBaseUrlApi}/${ControllerApiList.WebApi.GetSimPrice}`, { ...order })
    const data = await lastValueFrom(res)

    return data
  }
}
