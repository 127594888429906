<main class="py-10 px-6 md:px-20 xl:px-40">
  <!-- Banner - Title -->
  <section class="bg-title-bg bg-no-repeat md:bg-[center_left_10rem] mlg:bg-[center_left_35rem] py-16 animate-fade-down">
    <h2 class="text-center text-4xl font-semibold" transloco="contact.title"></h2>
  </section>

  <section class="py-10 animate-fade-up">
    <div class="bg-contact-banner-mobile mlg:ltr:bg-contact-banner mlg:rtl:bg-contact-banner-rtl bg-cover flex flex-col mlg:flex-row items-center relative bg-no-repeat p-4 sm:p-12 mlg:p-28 rounded-[45px] ">
      <article class="w-full mlg:w-1/2 text-white px-6">
        <h2 class="text-4xl font-semibold py-4" transloco="contact.second-title"></h2>
        <span class="" transloco="contact.subtitle"></span>
        <ul class="py-20">
          <li class="flex py-2">
            <img src="assets/images/icons/mail-icon.svg" alt="mail icon" class="w-[1.25rem] h-[1.25rem]"> <a href="mailto:info@globusim.com" class="px-4 text-sm">info@globusim.com</a>
          </li>
          <li class="flex py-2">
            <img src="assets/images/icons/phone-icon.svg" alt="phone icon" class="w-[1.25rem] h-[1.25rem]"> <a href="tel+1-855-613-8188" class="px-4 text-sm">1-855-613-8188</a>
          </li>
          <li class="flex py-2">
            <img src="assets/images/icons/loc-icon.svg" alt="location icon" class="w-[1.25rem] h-[1.25rem]"> <span class="px-4 text-sm">2333 FL-7, Margate, FL 33063, USA</span>
          </li>
        </ul>
      </article>

      <article class="w-full mlg:w-1/2 xl:px-16">
        <app-contact-form></app-contact-form>
        <p class="text-sm text-greyLight text-center w-full py-4" transloco="contact.marked-fields-required"></p>
      </article>

    </div>
  </section>
</main>