import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { Plan, SimPlan } from 'src/app/services/models/market-plans.model';
import { Collapse, Ripple, initTE } from 'tw-elements';
import { TranslocoModule } from '@ngneat/transloco';

@Component({
  selector: 'app-plan-card',
  standalone: true,
  imports: [CommonModule, RouterModule, TranslocoModule],
  templateUrl: './plan-card.component.html',
  styleUrls: ['./plan-card.component.scss'],
})
export class PlanCardComponent {
  @Input() plan: SimPlan | null = null;
  selectedMarket: string = this.route.snapshot.params['market'];
  selectedSimType: string;
  showDetails: boolean = false;

  constructor(private route: ActivatedRoute, private router: Router) {}

  ngOnInit(): void {
    initTE({ Collapse, Ripple });

    this.selectedSimType = this.route.snapshot.params['type'] || this.plan.simPlanMarketsSimTypeName
  }

  selectPlan(planId: number | undefined) {
    const isActivation = this.router.url.includes("/activate-sim/");
    const isReload = this.router.url.includes("/reload-esim/");

    this.router.navigate([
      isActivation ? '/activate-sim' : isReload ? '/reload-esim' : '/get-your-sim',
      this.selectedMarket,
      this.selectedSimType,
      planId,
    ]);
  }

  showMoreDetails() {
    this.showDetails = !this.showDetails;
  }
}
