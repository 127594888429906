import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslocoModule } from '@ngneat/transloco';

@Component({
  selector: 'app-spinner',
  standalone: true,
  imports: [CommonModule, TranslocoModule],
  templateUrl: './spinner.component.html',
  styleUrls: ['./spinner.component.scss']
})
export class SpinnerComponent {
  ngOnInit(): void {
    this.startProgress();
  }

  startProgress() {
    const progressElement = document.querySelector('.progress-value') as HTMLElement;
    const interval = setInterval(() => {
      const value = parseInt(progressElement.style.getPropertyValue('--progress')) || 0;
      if (value >= 100) {
        clearInterval(interval);
      } else {
        progressElement.style.setProperty('--progress', `${value + 1}%`);
      }
    }, 50);
  }
}
