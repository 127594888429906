import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SettingsService } from '../settings.service';
import { ControllerApiList } from '../models/controllers/ControllerList';
import { lastValueFrom } from 'rxjs';
import { EnvelopeStore, ICaptureEnvelope, PickUpStore, RootEnvelope, RootPickUp } from '../models/pickup-store.model';
import { ApiResponse } from '../models/base/ApiResponse';

@Injectable({
  providedIn: 'root'
})
export class PickupStoreLocationsService {

  private selectedStore: any;

  constructor(private http:HttpClient, private settings:SettingsService) { }

  // async getStoreLocations() {
  //   const res = this.http.post<RootPickUp>(`${this.settings.getBaseUrlApi}/${ControllerApiList.WebApi.GetPickUpStoreLocations}`, {})

  //   const data = await lastValueFrom(res)
  //   return data
  // }

  // async getEnvelopeStores(param: any) {
  //   const res = this.http.post<RootEnvelope>(`${this.settings.getBaseUrlApi}/${ControllerApiList.WebApi.GetEnvelopeStores}`, {...param})

  //   const data = await lastValueFrom(res)
  //   return data
  // }

  async getPickUpStores<T>(countryParams: any) {

    const response = this.http.post<ApiResponse<T>>(
      `${this.settings.getGlobusimBaseUrlApi}/${ControllerApiList.GlobusimApi.GetPickUpStores}`, {...countryParams} 
    );
    const data = await lastValueFrom(response);
    return data;
  }

  async captureEnvelope(param: any) {
    const res = this.http.post<ICaptureEnvelope>(`${this.settings.getBaseUrlApi}/${ControllerApiList.WebApi.CaptureEnvelope}`, {...param})

    const data = await lastValueFrom(res)
    return data
  }

  setStore(store: any) {
    this.selectedStore = store
  }

  getStore() {
    return this.selectedStore
  }
}
