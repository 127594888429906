import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { SettingsService } from '../settings.service';
import { lastValueFrom } from 'rxjs';
import { ControllerApiList } from '../models/controllers/ControllerList';

@Injectable({
  providedIn: 'root'
})
export class SubmitOrderService {
  private orderData: any = {};
  private simType: string = '';

  submitForm = this.formBuilder.group({
    dateForm: this.formBuilder.group({
      beginDate: ['', Validators.required],
      endDate: ['', Validators.required],
      simQuantity: [1, Validators.required],
      simsPerPeriod: [1, Validators.required],
    }),
    addressForm: this.formBuilder.group({
      fullName: ['', Validators.compose([Validators.required, Validators.minLength(3)]), ],
      address: ['', Validators.compose([Validators.required, Validators.minLength(5)]), ],
      country: ['', Validators.required],
      state: ['', Validators.required],
      city: ['', Validators.required],
      zipCode: ['', Validators.required],
      email: ['', Validators.compose([Validators.required, Validators.email])],
      confirmEmail: ['', Validators.compose([Validators.required, Validators.email]), ],
      phone: ['', Validators.required],
    }),
    shipAddressForm: this.formBuilder.group({
      address: ['', Validators.compose([Validators.required, Validators.minLength(5)]), ],
      country: ['', Validators.required],
      state: ['', Validators.required],
      city: ['', Validators.required],
      zipCode: ['', Validators.required],
      email: ['', Validators.compose([Validators.required, Validators.email])],
    }),
    paymentForm: this.formBuilder.group({
      cardName: ['', Validators.compose([Validators.required, Validators.minLength(3)]), ],
      cardNumber: ['', Validators.compose([
          Validators.required,
          Validators.minLength(13),
          Validators.maxLength(16),
      ]),],
      cardExpirationMM: ['', Validators.compose([Validators.required])],
      cardExpirationYY: ['', Validators.compose([Validators.required])],
      cardCVV: ['', Validators.compose([
          Validators.required,
          Validators.minLength(3),
          Validators.maxLength(4),
      ]),],
    }),
    activateForm: this.formBuilder.group({
      phoneNumber: ['', [Validators.required]],
      serialNumber: ['', [Validators.required]],
    }),
    reloadForm: this.formBuilder.group({
      phoneNumber: ['', [Validators.required]],
    }),
    couponForm: this.formBuilder.group({
      couponCode: ['', Validators.required],
    })
  });

  constructor(public formBuilder: FormBuilder, private http:HttpClient, private settings:SettingsService) {}
  
  async submitOrder (order: any) {
    const headers = new HttpHeaders()
    .set('ticket', order.headers)
    .set('Content-Type', 'application/json;charset=UTF-8')
    .set('Accept', 'application/json, text/javascript, */*')
    
    const res = this.http.post<any>(`${this.settings.getBaseUrlApi}/${ControllerApiList.WebApi.SubmitOrder}`, order.body, { 'headers': headers})
    const data = await lastValueFrom(res)
    
    return data
  }
  
  setOrderData(data: any) {
    this.orderData = { ...this.orderData, ...data };
  }
  
  getOrderData(): any {
    return { ...this.orderData };
  }
  
  clearOrderData() {
    this.orderData = {};
  }
}