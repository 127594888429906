import { Component, AfterViewInit, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DestinationsButtonsComponent } from '../destinations-buttons/destinations-buttons.component';
import { TranslocoModule } from '@ngneat/transloco';
import { RouterModule } from '@angular/router';

@Component({
  selector: 'app-home-how-esim-work',
  standalone: true,
  imports: [
    CommonModule,
    DestinationsButtonsComponent,
    TranslocoModule,
    RouterModule
  ],
  templateUrl: './home-how-esim-work.component.html',
  styleUrls: ['./home-how-esim-work.component.scss'],
})
export class HomeHowEsimWorkComponent {}
