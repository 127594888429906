import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, lastValueFrom, map } from 'rxjs';
import { ControllerApiList } from '../models/controllers/ControllerList';
import { SettingsService } from '../settings.service';
import { ApiResponse } from '../models/base/ApiResponse';
import { OldApiResponse } from '../models/base/OldApiResponse';

@Injectable({
  providedIn: 'root'
})

export class MarketPlansService {

  constructor(private http:HttpClient, private settings:SettingsService) { }
  
  async getMarketPlans<T>(groupName: string = '') {
    let params = new HttpParams().set('groupName', groupName);

    const response = this.http.get<ApiResponse<T>>(
      `${this.settings.getGlobusimBaseUrlApi}/${ControllerApiList.GlobusimApi.GetSimPlans}`, { params: params,}
    );
    const data = await lastValueFrom(response);
    return data;
  }
  
  async getSimPlanById<T>(simPlanId: number) {
    const response = this.http.get<ApiResponse<T>>(
      `${this.settings.getGlobusimBaseUrlApi}/${ControllerApiList.GlobusimApi.GetSimPlans}/${simPlanId}`
    );
    const data = await lastValueFrom(response);
    return data;
  }
}
