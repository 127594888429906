import { Component, ElementRef, ViewChild, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import * as intlTelInput from 'intl-tel-input';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { PopUpComponent } from '../pop-up/pop-up.component';
import { TranslocoModule, TranslocoService } from '@ngneat/transloco';
import { ContactUsInquiryService } from 'src/app/services/contact-us-inquiry/contact-us-inquiry.service';

@Component({
  selector: 'app-contact-form',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, PopUpComponent, TranslocoModule],
  templateUrl: './contact-form.component.html',
  styleUrls: ['./contact-form.component.scss'],
})
export class ContactFormComponent {
  contactForm: FormGroup;
  formSubmitted: boolean = false;
  showPopUp: boolean = false;
  currentLang: string = '';
  translateService = inject(TranslocoService);

  @ViewChild('phoneInput') phoneInput!: ElementRef;

  constructor(private formBuilder: FormBuilder, private contactInquiryService: ContactUsInquiryService) {
    this.contactForm = this.formBuilder.group({
      fullName: [
        '',
        Validators.compose([
          Validators.required,
          Validators.minLength(3),
          Validators.pattern(/^[a-zA-Z\s]*$/),
          Validators.pattern(/^[\p{L}\s]*$/u),
          Validators.maxLength(30),
        ]),
      ],
      email: ['', Validators.compose([Validators.required, Validators.email])],
      phone: '',
      message: '',
    });

    this.contactForm.get('phone')
    ?.setValidators([Validators.required, this.phoneNumberValidator]);
  }

  ngOnInit(): void {
    this.currentLang = this.translateService.getActiveLang();
  }

  phoneNumberValidator(control: FormControl) {
    const cleanedNumber = control.value;

    if (cleanedNumber) {
      const input = document.querySelector('#phoneInput');
      const iti = window.intlTelInputGlobals.getInstance(input);
  
      return iti.isValidNumber() ? null : { invalidPhoneNumber: true };
    }
    return null;
  }

  onSubmitContactForm() {
    let marketId;
    this.formSubmitted = true;

    if (this.contactForm.valid) {
      if (this.currentLang === 'en' || this.currentLang === 'he') {
        marketId = 1; // English/Hebrew market
      } else if (this.currentLang === 'es') {
        marketId = 2; // Spanish market
      } else if (this.currentLang === 'pt') {
        marketId = 3; // Portuguese market
      } else {
        // default to English/Hebrew market if language is not detected
        marketId = 1;
      }

      const jsonData = {
        languageISO: this.currentLang,
        affiliate: 'Globusim_Contact',
        MarketID: marketId,
        phoneNumber: this.contactForm.get('phone').value,
        email: this.contactForm.get('email').value,
        inquirySourceId: '34',
        fullName: this.contactForm.get('fullName').value,
        properties: { Message: this.contactForm.get('message').value },
      };

      const res = this.contactInquiryService.sendContactInquiry(jsonData)

      console.log("SUBMIT: ", res)
      this.contactForm.reset();
      this.formSubmitted = false;
      this.showPopUp = true;
    }
  }

  ngAfterViewInit() {
    const inputElement = this.phoneInput.nativeElement;
    if (inputElement) {
      const iti = intlTelInput(inputElement, {
        initialCountry: 'US',
        separateDialCode: false,
        nationalMode: false,
        formatOnDisplay: true,
        autoInsertDialCode: true,
        utilsScript:
        'https://cdn.jsdelivr.net/npm/intl-tel-input@18.2.1/build/js/utils.js',
      }); 
      
      inputElement.addEventListener('input', () => {
        const phoneNumber = iti.getNumber();
        this.contactForm.get('phone').setValue(phoneNumber);
        this.contactForm.get('phone').updateValueAndValidity(); // Actualiza la validez del campo
      });
    }
  }

  onClosePopUp() {
    this.showPopUp = false;
  }
}
