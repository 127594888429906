import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SettingsService } from '../settings.service';
import { ControllerApiList } from '../models/controllers/ControllerList';
import { Observable, lastValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ContactUsInquiryService {

  constructor(private http:HttpClient, private settings:SettingsService) { }

  async sendContactInquiry(message: any) {
    const res = this.http.post(`${this.settings.getBaseUrlApi}/${ControllerApiList.WebApi.ContactUsInquiry}`, message)
    const data = await lastValueFrom(res)
    return data
  }
}
