import {
  Component,
  Output,
  EventEmitter,
  ViewChild,
  ElementRef,
  Input,
  signal,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import * as intlTelInput from 'intl-tel-input';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { CountriesService } from 'src/app/services/countries/countries.service';
import { ICountries } from 'src/app/services/models/countries.model';
import { IState } from 'src/app/services/models/states.model';
import { StatesService } from '../../../services/states/states.service';
import { TranslocoModule, TranslocoService } from '@ngneat/transloco';
import { ShippingOptionsComponent } from '../shipping-options/shipping-options.component';
import { ShippingOptionsService } from 'src/app/services/shipping-options/shipping-options.service';
import { ShipElement } from 'src/app/services/models/shipping-options.model';
import { SubmitOrderService } from '../../../services/submit-order/submit-order.service';
import { Signal } from '@angular/core';
import { SimPlan } from 'src/app/services/models/market-plans.model';
import { PickupStoreLocationsService } from 'src/app/services/pickup-store-locations/pickup-store-locations.service';
import { GetSimCustomerService } from 'src/app/services/sim-customer/get-sim-customer.service';
import { IEnvelopeObj, IPickUpStores } from 'src/app/services/models/pickup-store.model';
import { SpinnerSmallComponent } from '../spinner-small/spinner-small.component';
import { GoogleTagManagerService } from 'src/app/services/google-tag-manager/google-tag-manager.service';

@Component({
  selector: 'app-checkout-billing',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    TranslocoModule,
    ShippingOptionsComponent,
    SpinnerSmallComponent
  ],
  templateUrl: './checkout-billing.component.html',
  styleUrls: ['./checkout-billing.component.scss'],
})
export class CheckoutBillingComponent {
  @ViewChild('phoneInput') phoneInput!: ElementRef;
  @ViewChild('phoneprefix') phoneprefix!: ElementRef;
  @Output() completed = new EventEmitter<void>();
  @Output() showStoreInfo = new EventEmitter<boolean>();
  @Output() fillUserID = new EventEmitter<string>();
  @Output() optionSelected: EventEmitter<ShipElement> = new EventEmitter<ShipElement>() || null;
  @Output() totalPriceChange: EventEmitter<Signal<number>> = new EventEmitter<Signal<number>>();
  @Output() envelopeData = new EventEmitter<IEnvelopeObj>();

  addressForm = this.submitOrderService.submitForm.get('addressForm') as FormGroup;

  countries: ICountries[] = [];
  states: IState[] = [];
  selectedCountryId: string = '';
  selectedState: string = '';
  formSubmitted: boolean = false;
  shippingOptions: ShipElement[] = [];
  showPickUpOption: boolean = false;
  isPickUpSelected: boolean = false;
  storeLocationStates: string[] = [];
  stores: IPickUpStores[] = [];
  selectedLocations: any[] = [];
  isLoading: boolean = false

  @Input() shippingOption: ShipElement | null;
  @Input() isEsim: boolean = false;
  @Input() isActivation: boolean = false;
  @Input() totalPrice: Signal<number>;
  @Input() simQuantity: number = 1;
  @Input() simsPerPeriod: number = 1;
  @Input() selectedPlan: SimPlan;
  @Input() currentLang: string = "";
  @Input() envelopeObj: IEnvelopeObj;

  constructor(
    private countriesService: CountriesService,
    private statesService: StatesService,
    private shippingOptionsService: ShippingOptionsService,
    private pickUpStoreService: PickupStoreLocationsService,
    private submitOrderService: SubmitOrderService,
    private getSimCustomerService: GetSimCustomerService,
    private translocoService: TranslocoService,
    private gtmService: GoogleTagManagerService
  ) {
    this.currentLang = this.translocoService.getActiveLang();

    this.addressForm.get('phone')
    ?.setValidators([Validators.required, this.phoneNumberValidator]);
  }

  ngOnInit(): void {
    // this.addressForm.patchValue({
    //   fullName: 'John Doe',
    //   address: '123 Main St',
    //   country: '218',
    //   state: 5,
    //   city: 'Los Angeles',
    //   zipCode: '90001',
    //   email: 'johndoe@example.com',
    //   confirmEmail: 'johndoe@example.com',
    //   // phone: '2015551230',
    // });

    if (!this.isEsim) {
      this.addressForm.get('confirmEmail').clearValidators();
    }
    
    this.getCountries();
    this.addressForm.get('country')
    ?.valueChanges.subscribe((selectedCountry: string) => {
      this.selectedCountryId = selectedCountry;
      if (!this.isEsim) {
        this.getShippingOptions(this.selectedCountryId);
        
        if (this.selectedCountryId === '29') {
          this.showPickUpOption = false;
        } else {
          this.getPickUpStores(this.selectedCountryId)
        }
      }
    });
  }
  
  ngAfterViewInit() {
    const inputElement = this.phoneInput.nativeElement;
    if (inputElement) {
      const iti = intlTelInput(inputElement, {
        initialCountry: 'US',
        separateDialCode: false,
        nationalMode: false,
        formatOnDisplay: true,
        autoInsertDialCode: true,
        utilsScript:
        'https://cdn.jsdelivr.net/npm/intl-tel-input@18.2.1/build/js/utils.js',
      }); 
      
      inputElement.addEventListener('input', () => {
        const phoneNumber = iti.getNumber();
        this.addressForm.get('phone').setValue(phoneNumber);
        this.addressForm.get('phone').updateValueAndValidity(); // Updates field validity
      });
    }
  }

  shippingOptionsGoogleTagManager() {
    const variant = this.isActivation ? "Activation" : "Normal";
    this.gtmService.addShippingOptions(this.selectedPlan, this.simQuantity, this.shippingOption, variant);
  }

  handleOptionSelected(option: string): void {
    if (option !== 'pickup') {
      this.isPickUpSelected = false;
      this.shippingOption = this.shippingOptions.find(
        (i) => i.ShippingName === option
      );
      this.optionSelected.emit(this.shippingOption);
      
    } else {
      this.optionSelected.emit(undefined);
      this.isPickUpSelected = true;
    }
  }

  onCountryChange(event: Event) {
    const countryCode = Number((event.target as HTMLSelectElement).value);
    this.getStates(countryCode);

    // if regular sim clean all shipping options selected
    if (!this.isEsim) {
      const newTotalPrice =
        this.selectedPlan?.price * this.simsPerPeriod * this.simQuantity;

      this.totalPriceChange.emit(signal(newTotalPrice));
      this.shippingOption = undefined;
      this.optionSelected.emit(undefined);
      this.isPickUpSelected = false;
      this.selectedLocations = [];
      this.stores = [];
      this.selectedLocations = [];
      this.storeLocationStates = [];
    }
  }

  onStoreLocationChange(event: Event) {

    this.optionSelected.emit(undefined);
    const storeSelected = (event.target as HTMLSelectElement).value;

    this.selectedLocations = this.stores.filter(
      (i) => i.city_en === storeSelected
    );
  }

  onPickUpSelected(value: boolean) {
    this.isPickUpSelected = value;
  }

  showStoreData(store: any) {
    this.pickUpStoreService.setStore(store)
    this.showStoreInfo.emit(true);
  }

  onSubmitAddressForm() {
    this.formSubmitted = true;
    let valid = true;

    if (
      this.isEsim &&
      this.addressForm.get('email')?.value !==
        this.addressForm.get('confirmEmail')?.value
    ) {
      valid = false;
    }

    if (
      !this.isEsim &&
      !this.isActivation &&
      this.shippingOption === undefined
    ) {
      valid = false;
    }

    if (this.addressForm.valid && valid) {
      this.isLoading = true
      this.formSubmitted = false;
      this.submitOrderService.setOrderData(this.addressForm.value);
      this.getSimCustomerData(this.submitOrderService.getOrderData())
      this.shippingOptionsGoogleTagManager()
    }
  }

  phoneNumberValidator(control: FormControl) {
    const cleanedNumber = control.value;

    if (cleanedNumber) {
      const input = document.querySelector('#phoneInput');
      const iti = window.intlTelInputGlobals.getInstance(input);
  
      return iti.isValidNumber() ? null : { invalidPhoneNumber: true };
    }
    return null;
  }
  
  getSimCustomerData(orderData: any) {
    const currentMarketID = this.currentLang === 'pt' ? 3 : 1;
    const phoneNumber: string = orderData.phone;
    const prefixRegex: RegExp = /^\+\d{1,3}/; // Regular expression for prefix search (format: +followed by 1 to 3 digits)
    const matchPrefix = phoneNumber.match(prefixRegex);
    const prefix = matchPrefix ? matchPrefix[0] : '';
    
    const data = {
      AgentID: 146,
      firstName: orderData.fullName,
      prefix: prefix,
      phone: phoneNumber.replace(prefix, ''),
      email: orderData.email,
      numItems: this.simQuantity.toString(),
      market: currentMarketID, //SimTypePromotion == 1 ? 7 : 3,*********************** 5 == Activate
    };
    
    this.getSimCustomer(data);
  }

  async getSimCustomer(simCustomer) {
    const res = await this.getSimCustomerService.getSimCustomer(simCustomer);
    
    if (res.response_code === 200) {
      this.fillUserID.emit(res.ticket)

      if (this.selectedCountryId === '101' && this.isPickUpSelected) {
        this.captureEnvelope(this.envelopeObj.locationID)
      }

      this.completed.emit();
      this.isLoading = false
    }
  }

  async getCountries() {
    const res = await this.countriesService.getCountries<ICountries[]>();

    if (res.isError) {
      console.error('ERROR: ', res.errorMessage);
      return;
    }

    if (res.isSuccessful) {
      this.countries = res.result;

      if (!this.isEsim) {
        const validCountryNumber = [101, 29, 35, 218];
        this.countries = this.countries.filter((country) =>
          validCountryNumber.includes(country.countryID)
        );
      }

      if (this.countries.length > 0) {
        this.selectedCountryId = this.currentLang === 'he' ? '101' : '218';
        this.addressForm.get('country')?.setValue(this.selectedCountryId);

        this.getStates(Number(this.selectedCountryId));
      }
    }
  }

  async getStates(countryId: number) {
    const res = await this.statesService.getStates<IState[]>(countryId);

    if (res.isSuccessful) {
      this.states = res.result;
    }
  }

  async getShippingOptions(shipCountry: string) {
    const res = await this.shippingOptionsService.getShippingOptions(
      shipCountry
    );

    if (res.response_code === 200) {
      this.shippingOptions = res.response_list.elements;
    }
  }

  async getPickUpStores(countryIDparam: string) {

    const jsonData = {
      countryId: countryIDparam,
      planName: this.selectedPlan.planName,
      quantity: this.simQuantity,
    };

    try {     
      const res = await this.pickUpStoreService.getPickUpStores<IPickUpStores[]>(jsonData);

      if (res.isSuccessful) {
        const uniqueStates = new Set<string>();
        
        res.result.forEach((element) => {
          uniqueStates.add(element.city_en);
        });
        
        this.storeLocationStates = Array.from(uniqueStates);
        this.stores = res.result
        
        if (this.stores.length > 0) {
          this.showPickUpOption = true;
        } else {
          this.showPickUpOption = false;
        }
      } else {
        console.log(res.messages)        
      }
    } catch (error) {
      console.log(error)
    }
  }

  async captureEnvelope(location: string) {
    const jsonData = {
      "Plan": this.selectedPlan.planName,
      "Quantity": this.simQuantity.toString(),
      "Location": location.toString(),
      "Email": this.addressForm.get('email').value,
    };

    const res = await this.pickUpStoreService.captureEnvelope(jsonData);

    if (res.response_code === 200) {
      this.envelopeObj.envelopes = res.envelopes
      
      this.envelopeData.emit(this.envelopeObj)
      // this.optionSelected.emit(null)
      // this.showStoreInfo.emit(false);

    }
  }
}
