<section class="w-full py-10 px-6 md:px-20 mlg:px-28 lg:px-40">
  <h2 class="text-center text-4xl font-semibold" transloco="home.how-esim-work-title"></h2>
  <article class="p-10 md:py-10 text-center md:text-start">

    <div class="flex flex-col lg:flex-row items-center py-6 max-w-[820px] mx-auto">
      <img  src="assets/images/layers/Layer-2.svg" alt="layer 2" class="w-[16rem] h-fit scroll-driven-l-to-r"  loading="lazy">
      <div class="flex flex-col p-6 lg:p-12 xl:pl-28 scroll-driven-r-to-l">
        <p class="text-xl font-semibold" transloco="home.how-esim-work-step1-title"></p>
        <span class="text-grey py-6" transloco="home.how-esim-work-step1-subtitle"></span>
        <p routerLink="/supported-esim-devices" class="flex text-sm items-center underline cursor-pointer mx-auto md:mx-0">
          <span transloco="home.how-esim-work-step1-check-compatibility"></span>
          <svg class="w-6 ml-2 animate-bounce-right" viewBox="0 0 31 31" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M25.3558 15.6506L25.3547 15.6495L19.0273 9.35262C18.5533 8.8809 17.7866 8.88266 17.3147 9.35674C16.8429 9.83076 16.8448 10.5975 17.3188 11.0692L21.567 15.2969H5.21094C4.54214 15.2969 4 15.839 4 16.5078C4 17.1766 4.54214 17.7187 5.21094 17.7187H21.5669L17.3188 21.9464C16.8448 22.4182 16.843 23.1849 17.3148 23.6589C17.7866 24.133 18.5534 24.1347 19.0273 23.663L25.3547 17.3661L25.3558 17.365C25.8301 16.8916 25.8286 16.1224 25.3558 15.6506Z"
              fill="black" />
          </svg>
        </p>
      </div>
    </div>

    <div class="flex flex-col lg:flex-row items-center py-6 max-w-[820px] mx-auto">
      <img src="assets/images/layers/Layer-3.svg" alt="layer 3" class="w-[16rem] h-fit box scroll-driven-r-to-l"  loading="lazy">

      <div  class="p-6 lg:p-12 xl:pl-28 scroll-driven-l-to-r">
        <p class=" text-xl font-semibold" transloco="home.how-esim-work-step2-title"></p>
        <p class="text-grey py-6" transloco="home.how-esim-work-step2-subtitle"></p>
      </div>
    </div>

    <div class="flex flex-col lg:flex-row items-center py-6 max-w-[820px] mx-auto">
      <img  src="assets/images/layers/Layer-1.svg" alt="layer 1" class="w-[16rem] h-fit scroll-driven-l-to-r"  loading="lazy">
      <div class="p-12 xl:pl-28 scroll-driven-r-to-l">
        <p class="text-xl font-semibold" transloco="home.how-esim-work-step3-title"></p>
        <p class="text-grey py-6" transloco="home.how-esim-work-step3-subtitle"></p>
        <div class="flex justify-center md:justify-start">
          <app-destinations-buttons></app-destinations-buttons>
        </div>
      </div>
    </div>
  </article>
</section>