import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MarketsCardComponent } from 'src/app/core/shared/markets-card/markets-card.component';
import { CustomerCarouselComponent } from 'src/app/core/shared/customer-carousel/customer-carousel.component';
import { SearchInputComponent } from 'src/app/core/shared/search-input/search-input.component';
import { ModalSearchInputComponent } from 'src/app/core/shared/modal-search-input/modal-search-input.component';
import { MarketService } from 'src/app/services/markets/markets.service';
import { RegionsCardComponent } from 'src/app/core/shared/regions-card/regions-card.component';
import { Market } from 'src/app/services/models/markets.model';
import { TranslocoModule } from '@ngneat/transloco';
import { Meta, Title } from '@angular/platform-browser';

@Component({
  standalone: true,
  imports: [
    CommonModule,
    TranslocoModule,
    SearchInputComponent,
    MarketsCardComponent,
    ModalSearchInputComponent,
    RegionsCardComponent,
    CustomerCarouselComponent,
  ],
  templateUrl: './get-sim-page.component.html',
  styleUrls: ['./get-sim-page.component.scss'],
})
export class GetSIMPageComponent {
  showModal: boolean = false;
  countries: Market[] = [];
  regions: Market[] = [];
  visibleCountries: Market[] = [];
  showAllCountries = false;
  skeletonBoxes: number[] = [1, 2, 3, 4, 5, 6, 7, 8];

  constructor(
    private marketService: MarketService,
    private titleService: Title,
    private meta: Meta
  ) {}

  ngOnInit(): void {
    this.getMarkets();

    this.updateMetaTags(
      'Globusim | Get Your eSIM/SIM',
      'esim, sim, data only, connectivity ',
      'Travel the world, stay connected'
    );
  }

  updateMetaTags(title: string, keywords: string, description: string) {
    this.titleService.setTitle(title);
    this.meta.updateTag({ name: 'keywords', content: keywords });
    this.meta.updateTag({ name: 'description', content: description });
    this.meta.updateTag({
      rel: 'canonical',
      href: 'https://globusim.com/get-your-sim',
    });
  }

  showMoreMarkets() {
    this.showAllCountries = true;
    this.visibleCountries = this.countries;
  }

  showLessMarkets() {
    this.showAllCountries = false;
    this.visibleCountries = this.countries.slice(0, 12);
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }

  onCloseModal() {
    this.showModal = false;
  }

  async getMarkets() {
    const data = await this.marketService.getMarkets<Market[]>();

    try {
      const uniqueCountries = new Set<Market>();
      const uniqueRegions = new Set<Market>();

      data.result.forEach((item) => {
        if (item.activeInSearch) {
          if (item.groupLevel === 2) {
            uniqueCountries.add(item);
          } else if (item.groupLevel === 1) {
            uniqueRegions.add(item);
          }
        }
      });

      this.countries = Array.from(uniqueCountries);
      this.regions = Array.from(uniqueRegions);
      this.visibleCountries = this.countries.slice(0, 12);
    } catch (err: any) {
      console.error('ERROR: ', err);
    }
  }
}
