import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Carousel, initTE } from 'tw-elements';
import { TranslocoModule } from '@ngneat/transloco';

@Component({
  selector: 'app-customer-carousel',
  standalone: true,
  imports: [CommonModule, TranslocoModule],
  templateUrl: './customer-carousel.component.html',
  styleUrls: ['./customer-carousel.component.scss'],
})
export class CustomerCarouselComponent {
  ngOnInit() {
    initTE({ Carousel });
  }
}
