<section class="w-full">
  <h3 class="font-bold pb-3 text-xl">Edita tus datos personales</h3>

  <article class="flex flex-col pb-6">
    <label for="nameInput" class="py-3 font-medium">
      Nombre completo
    </label>
    <input id="nameInput" [(ngModel)]="name" class="p-2 w-80 block min-h-[auto] rounded-md border border-greyDark bg-transparent px-3 py-[0.32rem] leading-[1.6] outline-none focus:border-grey" />
  </article>

  <article class="flex items-center border-t border-greyLight w-full py-6">
    <div class="flex flex-col flex-1">
      <h6 class="pb-3 font-medium">Usuario</h6>
      <p class="text-grey">@kerning.dev</p>
    </div>
    <div class="">
      <button type="button" class="px-3 border border-greyDark rounded font-medium text-sm  hover:border-grey">Modificar</button>
    </div>
  </article>

  <article class="flex items-center border-t border-greyLight w-full py-6">
    <div class="flex flex-col flex-1">
      <h6 class="pb-3 font-medium">Contraseña</h6>
      <p class="text-grey">*********</p>
    </div>
    <div class="">
      <button type="button" class="px-3 border border-greyDark rounded font-medium text-sm  hover:border-grey">Modificar</button>
    </div>
  </article>

  <article class="flex items-center border-t border-greyLight w-full py-6">
    <div class="flex flex-col flex-1">
      <h6 class="pb-3 font-medium">Dirección de correo electrónico</h6>
      <p class="text-grey">info@kerning.dev</p>
    </div>
    <div class="">
      <button type="button" class="px-3 border border-greyLight rounded font-medium text-sm  hover:border-grey">Modificar</button>
    </div>
  </article>

  <article class="flex items-center border-t border-greyLight w-full py-6">
    <div class="flex flex-col w-80">
      <h6 class="pb-3 font-medium">Idioma</h6>
      <select data-te-select-init data-te-select-placeholder="Elegir idioma" class="border border-greyDark outline-none ">
        <option value="1" class="hover:bg-greyLight">One</option>
        <option value="2" class="hover:bg-greyLight">Two</option>
        <option value="3" class="hover:bg-greyLight">Three</option>
        <option value="4" class="hover:bg-greyLight">Four</option>
        <option value="5" class="hover:bg-greyLight">Five</option>
      </select>
    </div>
  </article>

  <article class="flex border-t border-greyLight w-full py-6">
    <div class="flex flex-col w-80">
      <h6 class="pb-3 font-medium">Zona horaria</h6>
      <select data-te-select-init data-te-select-placeholder="Elegir zona horaria">
        <option value="1">One</option>
        <option value="2">Two</option>
        <option value="3">Three</option>
        <option value="4">Four</option>
        <option value="5">Five</option>
      </select>

    </div>
  </article>

  <button type="button" class="bg-orange text-white font-semibold px-24 text-sm py-3 rounded-full my-6">Guardar cambios</button>

  <article class="flex border-t border-greyLight w-full py-6">
    <div class="flex flex-col">
      <h6 class="font-medium">Eliminar tu cuenta</h6>
      <p class="text-grey py-4">Cuando elimina su cuenta, pierde el acceso a los servicios de la cuenta y eliminamos permanentemente sus datos personales. Puedes cancelar la eliminación durante 14 días.</p>
      <button type="button" class="text-red bg-transparent underline text-start text-sm">Eliminar mi cuenta</button>
    </div>
  </article>
</section>