import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslocoModule } from '@ngneat/transloco';
import { Router } from '@angular/router';

@Component({
  selector: 'app-pop-up',
  standalone: true,
  imports: [CommonModule, TranslocoModule],
  templateUrl: './pop-up.component.html',
  styleUrls: ['./pop-up.component.scss']
})
export class PopUpComponent {

  @Input() showPopUp: boolean = false;
  @Input() translate: string = ""

  @Output() closePopUpEvent: EventEmitter<void> = new EventEmitter<void>();

  constructor(private router: Router) {}
  
  closePopUp() {
    this.showPopUp = false;
    this.closePopUpEvent.emit();
  }
  
  ngOnInit(): void {}
}
