import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SettingsService } from '../settings.service';
import { ControllerApiList } from '../models/controllers/ControllerList';
import { lastValueFrom } from 'rxjs';
import { CouponResponse } from '../models/coupon-code.model';

@Injectable({
  providedIn: 'root'
})
export class CouponCodeService {

  constructor(private http: HttpClient, private settings: SettingsService) {}

  async validateCouponCode(code: any) {
    const response = this.http.post<CouponResponse>(
      `${this.settings.getBaseUrlApi}/${ControllerApiList.WebApi.PromoCodeValidation}`, code);
    const data = await lastValueFrom(response);
    return data;
  }
}
