import { Routes } from '@angular/router';
import { HomePageComponent } from './pages/home-page/home-page.component';
import { ActivatePageComponent } from './pages/activate-page/activate-page.component';
import { AboutPageComponent } from './pages/about-page/about-page.component';
import { FaqPageComponent } from './pages/faq-page/faq-page.component';
import { ContactPageComponent } from './pages/contact-page/contact-page.component';
import { GetSIMPageComponent } from './pages/get-sim-page/get-sim-page.component';
import { ProfilePageComponent } from './pages/profile-page/profile-page.component'; 
import { PlanPageComponent } from './pages/plan-page/plan-page.component';
import { ProfileNotificationsComponent } from './core/shared/profile-notifications/profile-notifications.component';
import { ProfileHelpComponent } from './core/shared/profile-help/profile-help.component';
import { ProfilePaymentMethodComponent } from './core/shared/profile-payment-method/profile-payment-method.component';
import { ProfileSimComponent } from './core/shared/profile-sim/profile-sim.component';
import { ProfileInfoComponent } from './core/shared/profile-info/profile-info.component';
import { CheckoutPageComponent } from './pages/checkout-page/checkout-page.component';
import { TermsAndConditionsPageComponent } from './pages/terms-and-conditions-page/terms-and-conditions-page.component';
import { PrivacyPolicyPageComponent } from './pages/privacy-policy-page/privacy-policy-page.component';
import { SupportedEsimDevicesComponent } from './pages/supported-esim-devices/supported-esim-devices.component';
import { OrderPageComponent } from './pages/order-page/order-page.component';
import { NotFoundPageComponent } from './pages/not-found-page/not-found-page.component';
import { ReloadEsimPageComponent } from './pages/reload-esim-page/reload-esim-page.component';
import { PickUpStoresPageComponent } from './pages/pick-up-stores-page/pick-up-stores-page.component';
import { RedirectOldVComponent } from './pages/redirect-old-v/redirect-old-v.component';
import { InstructionsEsimAndroidComponent } from './pages/instructions-esim-android/instructions-esim-android.component';
import { InstructionsEsimIphoneComponent } from './pages/instructions-esim-iphone/instructions-esim-iphone.component';
import { QRInformationComponent as QRInformationComponent } from './pages/qr-information/qr-information.component';
import { BlogPageComponent } from './pages/blog-page/blog-page.component';
import { BlogArticlePageComponent } from './pages/blog-article-page/blog-article-page.component';

export const routes: Routes = [
  { path: '', redirectTo: 'home', pathMatch: 'full', },
  { path: 'not-found', component: NotFoundPageComponent },
  { path: 'home', component: HomePageComponent },
  { path: 'get-your-sim', component: GetSIMPageComponent },
  { path: 'activate-sim', component: ActivatePageComponent },
  { path: 'reload-esim', component: ReloadEsimPageComponent },
  { path: 'contact', component: ContactPageComponent },
  { path: 'faq', component: FaqPageComponent },
  { path: 'about', component: AboutPageComponent },
  { path: 'get-your-sim/:market', component: PlanPageComponent},
  { path: 'get-your-sim/:market/:type', component: PlanPageComponent},
  { path: 'activate-sim/:market/:type', component: PlanPageComponent},
  { path: 'reload-esim/:market/:type', component: PlanPageComponent},
  { path: 'get-your-sim/:market/:type/:plan-id', component: CheckoutPageComponent},
  { path: 'activate-sim/:market/:type/:plan-id', component: CheckoutPageComponent},
  { path: 'reload-esim/:market/:type/:plan-id', component: CheckoutPageComponent},
  { path: 'pickup-store', component: PickUpStoresPageComponent},
  { path: 'order/:simType/:id/:plan-id/:ship-id/:shipPrice/:couponPrice', component: OrderPageComponent},
  { path: 'terms-and-conditions', component: TermsAndConditionsPageComponent},
  { path: 'privacy-policy', component: PrivacyPolicyPageComponent},
  { path: 'supported-esim-devices', component: SupportedEsimDevicesComponent},
  { path: 'instructions-esim-android', component: InstructionsEsimAndroidComponent},
  { path: 'instructions-esim-iphone', component: InstructionsEsimIphoneComponent},
  { path: 'blog', component: BlogPageComponent},
  { path: 'blog/:lang/:id/:title', component: BlogArticlePageComponent},
  { path: 'email-esim-purchase-information', component: QRInformationComponent},
  { path: 'profile', component: ProfilePageComponent, children: [
    { path: '', redirectTo: 'my-sim', pathMatch: 'full' },
    { path: 'info', component: ProfileInfoComponent},
    { path: 'notifications', component: ProfileNotificationsComponent},
    { path: 'help', component: ProfileHelpComponent},
    { path: 'payment-methods', component: ProfilePaymentMethodComponent},
    { path: 'my-sim', component: ProfileSimComponent}
  ] },
  { path: 'index.html', component: RedirectOldVComponent},
  { path: '**', redirectTo: '/not-found' },
  
];
