<main class="{{ isReloadEsim ? 'pb-10' : 'p-5'}} mx-auto relative">
  <span *ngIf="currentStep > 1" (click)="prevStep()" class="absolute top-4 left-6 opacity-50 hover:opacity-100 hover:scale-105 cursor-pointer">
    <img src="assets/images/icons/arrow-back-icon.svg" alt="back arrow" class="w-[2rem] h-[2rem]">
  </span>
  <!-- RELOAD Esim slider header -->
  <section *ngIf="isReloadEsim"
    class="bg-reload-slider bg-cover bg-no-repeat flex flex-col items-center text-white py-10 animate-fade-down" [style.background-image]="'url(' + (selectedMarket | appSliders) + ')'">
    <h2 class="text-center text-3xl capitalize font-semibold py-2" transloco="checkout.reload-title"> </h2>
  </section>

  <section class="flex flex-col items-center">
    <!-- STEP COUNTER -->
    <div *ngIf="!isReloadEsim" class="w-full mlg:px-20 animate-fade-down">
      <app-checkout-steps [currentStep]="currentStep" (prevStep)="prevStep()"></app-checkout-steps>
    </div>

    <article *ngIf="selectedPlan" class="flex flex-col lg:flex-row w-full lg:justify-center animate-fade-up">
      <!-- LEFT SIDE -->
      <div class="lg:w-1/2 max-w-[820px]">
        <!-- CHOOSE DATE STEP -->
        <div *ngIf="currentStep === 1">
          <app-checkout-date-step [selectedPlan]="selectedPlan" (completed)="nextStep()" [simQuantity]="simQuantity"
            (newSimQuantity)="onNewSimQuantity($event)" [isActivation]="isActivation"
            (changePeriod)="onChangePeriod($event)"></app-checkout-date-step>
        </div>
        <!-- BILLING STEP -->
        <div *ngIf="currentStep === 2">
          <app-checkout-billing (completed)="nextStep()" (optionSelected)="onShippingOptionsChange($event)"
            (totalPriceChange)="updateTotalPrice($event)" (showStoreInfo)="onShowStoreData($event)" [isEsim]="isEsim" [isActivation]="isActivation"
            [simQuantity]="simQuantity" [simsPerPeriod]="simsPerPeriod" [selectedPlan]="selectedPlan" [shippingOption]="shippingOption"
            [totalPrice]="totalPrice" [currentLang]="currentLanguage" (fillUserID)="onGetSimCustomer($event)" [envelopeObj]="envelopeObj" (envelopeData)="onCaptureEnvelope($event)">
          </app-checkout-billing>
        </div>
        <!-- PAYMENT STEP -->
        <div *ngIf="currentStep === 3">
          <app-checkout-payment #paymentForm (completed)="nextStep()" [isEsim]="isEsim" [isReloadEsim]="isReloadEsim"
            (billingAddressChanged)="onBillingAddressChanged($event)" [fxdCustomerPlan]="fxdCustomerPlan" [totalPrice]="totalPrice"
            (changeMethod)="onPaymentFormMethodSelected($event)" (finalizeOrder)="finalizePurchase()"
            (fillPayPalDetails)="onFillPayPalDetails($event)"
            (finalizeReloadOrder)="finalizeReloadPurchase()"></app-checkout-payment>
        </div>
      </div>
      <!-- RIGHT SIDE -->
      <div class="flex flex-col-reverse md:flex-col lg:w-1/2 max-w-[820px]">
        <!-- SUMMARY -->
        <app-checkout-summary [selectedPlan]="selectedPlan" [totalPrice]="totalPrice"
          [shippingPrice]="shippingOption?.Fee" [simQuantity]="simQuantity" [simsPerPeriod]="simsPerPeriod"
          [couponPrice]="couponPrice">
        </app-checkout-summary>
        <!-- COUPON CODE -->
        <ng-container *ngIf="currentStep === 3">
          <app-coupon-code (promoCode)="onCouponCodeSubmit($event)" [couponPrice]="couponPrice"
            (changeCouponPrice)="onRemoveCouponCode()"></app-coupon-code>
        </ng-container>

        <!-- FINAL SUBMIT BUTTON -->
        <div class="px-6 sm:px-14" *ngIf="selectedPaymentMethod === 'card' && currentStep === 3">
          <button type="submit" (click)="onPaymentFormSubmitted()"
            class="hover:scale-105 inline-block w-full rounded-full bg-orange px-7 pb-2.5 pt-3 text-sm font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out">
            {{ "checkout.place-order-btn" | transloco }}</button>
        </div>
      </div>
    </article>

    <!-- SPINNER -->
    <ng-container *ngIf="isLoading">
      <app-spinner></app-spinner>
    </ng-container>

    <!-- POP UP -->
    <app-pop-up *ngIf="showPopUp" [showPopUp]="showPopUp" (closePopUpEvent)="onClosePopUp()" [translate]="popUpMessage">
    </app-pop-up>
  </section>

  <!-- PICK UP STORE INFORMATION -->
  <div *ngIf="showStoreInfo">
    <app-modal-store-info (showStoreInfo)="closeStoreModal()" [selectedPlan]="selectedPlan" [simQuantity]="simQuantity" (optionSelected)="onShippingOptionsChange($event)" (envelopeData)="onCaptureEnvelope($event)"></app-modal-store-info>
  </div>
</main>