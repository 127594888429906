import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FaqAccordionComponent } from 'src/app/core/shared/faq-accordion/faq-accordion.component';
import { TranslocoModule } from '@ngneat/transloco';
import { Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'app-faq-page',
  standalone: true,
  imports: [CommonModule, FaqAccordionComponent, TranslocoModule],
  templateUrl: './faq-page.component.html',
  styleUrls: ['./faq-page.component.scss'],
})
export class FaqPageComponent {
  constructor(private titleService: Title, private meta: Meta) {}

  ngOnInit(): void {
    this.updateMetaTags(
      'Globusim | F A Q',
      'esim, sim, data only, connectivity ',
      'Travel the world, stay connected'
    );
  }

  updateMetaTags(title: string, keywords: string, description: string) {
    this.titleService.setTitle(title);
    this.meta.updateTag({ name: 'keywords', content: keywords });
    this.meta.updateTag({ name: 'description', content: description });
    this.meta.updateTag({
      rel: 'canonical',
      href: 'https://globusim.com/faq',
    });
  }
}
