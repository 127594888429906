import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-profile-help',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './profile-help.component.html',
  styleUrls: ['./profile-help.component.scss']
})
export class ProfileHelpComponent {

}
