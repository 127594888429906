<div class="mx-auto flex items-center justify-around">
  <div class="p-4">
    <div class="flex">
      <div class="text-center">
        <div class="mx-auto">
          <div>
            <div class="mx-auto"  >
              <div class="mainbar max-w-2xl"  >
                <p class="font-poppins text-18 text-start mt-8 mb-6"><strong><span>{{FirstName}}</span></strong>, prepare your bags! 😎</p>
                <div class="text-justify text-md mb-6 border-b">
                  Thank you for buying a Globusim eSIM. Print your QR code or send it to another device to scan it before you arrive at your destination. When you install the eSIM, your phone needs to be connected to the internet. 
                  <span class="font-bold">Ensure that your 'Roaming' option is turned ON, and your phone is unlocked in order to use the service. <br/><br/></span> 
                </div>
                <p class="font-poppins text-20 text-center mb-0"><strong>HERE IS YOUR QR CODE</strong></p>
                <img id="barcode" [src]="'https://chart.apis.google.com/chart?chs=200x200&cht=qr&chl=' + QRActivationCode +'&choe=UTF-8'" class="block mx-auto" >
                <p class="font-poppins text-18 text-center">
                  <strong>Starting Date</strong>: <span>{{BeginDate}}</span>
                  <br><strong>End Date</strong>: <span>{{EndDate}}</span> 
                  <br><strong>{{PhoneParamName}} number</strong>: <span>{{PhoneNumber}} </span>
                  <br>{{DIDSection}}<strong>Plan chosen: </strong>
                  <span>{{PlanName}}</span>. 
                  
                  <br><br>
                  <strong>For manual activation on an Android phone use: </strong><br>
                  <span>{{QRActivationCode}}</span><br><strong>For manual activation on an iPhone use:</strong><br><span> <strong>SM-DP+ Address:&nbsp;</strong>{{IphoneActivationAddress}}</span><br><span>&nbsp;<strong>Activation code:&nbsp;</strong>{{IphoneActivationCode}}</span>&nbsp;</p><br>
                <div class="font-poppins text-18 text-center"><strong>Click on your type of device <a class="text-orange cursor-pointer" routerLink="/instructions-esim-android">Android</a>/<a class="text-orange cursor-pointer"  routerLink="/instructions-esim-iphone">iOS (Iphone) </a>&nbsp;to see the instructions for activating your eSIM in your phone.</strong></div>
                <div class="font-poppins text-18 text-center">If you need any further help, please contact our customer service:<br>
                  <div class="flex flex-col sm:flex-row">
                    <div class="mb-2 sm:mb-0 sm:mr-4">
                      <strong>Email: </strong><a class="text-orange cursor-pointer" href="mailto:support@globusim.com">support@globusim.com</a>
                    </div>
                    <div>
                      <strong>Whatsapp:</strong>
                      <a href="https://api.whatsapp.com/send/?phone=19542312500" target="_blank"> <span class="text-orange">+19542312500</span></a>
                    </div>
                  </div>
                </div>
                <div class="font-poppins text-18 text-center mt-4 mb-4"><strong>Thank you for ordering. <br>ENJOY YOUR TRIP!</strong></div>
              </div>
            </div>
            <div class="header text-center align-middle mb-6">
              <a href="https://www.withfaye.com/?utm_medium=gd-partnerships&utm_source=globusim&utm_campaign=general_us" target="_blank">
                <img class="max-full" src="./assets/images/faye_banner.png" alt="Globusim - Faye">
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
