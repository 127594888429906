  <div class="fixed inset-0 bg-white bg-opacity-70 flex justify-center pt-[5%] z-[1000] transition-opacity duration-500 ease-in-out">
 
    <div class="flex flex-col items-center md:items-start sm:w-[770px] px-12 py-8 border border-greyDark rounded-2xl shadow-2xl bg-white h-max my-auto mx-8">
      <section class="flex flex-col md:flex-row items-center">
        <img src="assets/images/logo-s.png" alt="small logo" class="w-[5rem] sm:w-[10rem] h-fit md:pr-12">
        <div>
          <h2 class="text-center md:text-start text-2xl sm:text-4xl font-semibold underline decoration-solid decoration-orange" [transloco]="'popup.'+ translate +'.title'"></h2>
          <h4 class="font-semibold text-base sm:text-lg pt-4" [transloco]="'popup.'+ translate +'.subtitle'"></h4>
        </div>
      </section>

      <section class="border-t border-greyLight">
        <p class="text-grey text-sm sm:text-base pt-6 pb-8" [transloco]="'popup.'+ translate +'.message'"></p>
        <div class="flex justify-center md:justify-end">
          <button (click)="closePopUp()"
          class="bg-orange rounded-full py-3 px-14 font-semibold text-white" transloco="popup.back-btn"></button>
        </div>
      </section>
    </div>

  </div>