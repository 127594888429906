import { Pipe, type PipeTransform } from '@angular/core';

@Pipe({
  name: 'appSquares',
  standalone: true,
})
export class SquaresPipe implements PipeTransform {

  transform(value: string, ): string {

    if (value === 'itrek' || value === 'honeymoon' || value === 'israel_long_term' || value === 'sky2000' || value === 'ncsy' || value === 'momentum') {
      return 'assets/images/squares/israel.webp';
    }

    if (value === 'hamaagal') {
      return 'assets/images/squares/canada.webp'
    }

    if (value) {
      return 'assets/images/squares/' + value.toLowerCase().replaceAll(" ", "-") + '.webp';
    } 
    
    return 'null'
  }

}
