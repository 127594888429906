import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, RouterModule } from '@angular/router';

@Component({
  selector: 'app-qr-information',
  standalone: true,
  imports: [CommonModule, RouterModule],
  templateUrl: './qr-information.component.html',
  styleUrls: ['./qr-information.component.css']
})
export class QRInformationComponent implements OnInit {
  FirstName: string;
  DIDSection: string;
  PlanName: string;
  QRActivationCode: string;
  IphoneActivationAddress: string;
  IphoneActivationCode: string;
  BeginDate: string;
  EndDate: string;
  PhoneParamName: string;
  PhoneNumber: string;

  constructor(private route: ActivatedRoute) { }

  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      this.FirstName = params['FirstName'];
      this.DIDSection = params['DIDSection'];
      this.PlanName = params['PlanName'];
      this.QRActivationCode = params['QRActivationCode'];
      this.IphoneActivationAddress = params['IphoneActivationAddress'];
      this.IphoneActivationCode = params['IphoneActivationCode'];
      this.BeginDate = params['BeginDate'];
      this.EndDate = params['EndDate'];
      this.PhoneParamName = params['PhoneParamName'];
      this.PhoneNumber = params['PhoneNumber'];
    });
  }

}