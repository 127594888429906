import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-profile-sim',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './profile-sim.component.html',
  styleUrls: ['./profile-sim.component.scss']
})
export class ProfileSimComponent {

}
