import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SettingsService } from '../settings.service';
import { lastValueFrom } from 'rxjs';
import { ControllerApiList } from '../models/controllers/ControllerList';
import { ApiResponse } from '../models/base/ApiResponse';

@Injectable({
  providedIn: 'root'
})
export class BlogService {

  constructor(private http: HttpClient, private settings: SettingsService) { }

  async getAllBlogArticles<T>() {
    const res = this.http.get<ApiResponse<T>>(`${this.settings.getGlobusimBaseUrlApi}/${ControllerApiList.GlobusimApi.GetBlog}`)
    const data = await lastValueFrom(res)
    return data
  }
  
  async getArticleById<T>(id: number) {
    const res = this.http.get<ApiResponse<T>>(`${this.settings.getGlobusimBaseUrlApi}/${ControllerApiList.GlobusimApi.GetBlog}/${id}`)
    const data = await lastValueFrom(res)
    return data
  }
}
