<main class="p-10 md:px-16 mlg:px-36">
  <section class="bg-title-bg bg-no-repeat md:bg-[center_left_10rem] mlg:bg-[center_left_35rem] py-16 animate-fade-down">
    <h2 class="text-center text-4xl font-semibold">Supported Devices</h2>
  </section>

  <section class="animate-fade-up">


    <h3 class="font-medium text-orange text-3xl pb-4 pt-6">Apple</h3>
    <ul class="list-disc text-sm">
      <li>iPhone XR</li>
      <li>iPhone XS & XS Max</li>
      <li>iPhone 11, 11 Pro, 11 Pro Max</li>
      <li>iPhone SE 2 (2020)</li>
      <li>iPhone 12, 12 Mini, 12 Pro, 12 Pro Max</li>
      <li>iPhone 13, 13 Mini, 13 Pro, 13 Pro Max</li>
      <li>iPhone SE 3 (2022)</li>
      <li>iPhone 14, 14 Plus, 14 Pro, 14 Pro Max</li>
      <li>iPhone 15, 15 Plus, 15 Pro, 15 Pro Max</li>
      <li>*iPhones from mainland China and iPhone devices from Hong Kong and Macao (except for iPhone 13 mini, iPhone 12 mini, iPhone SE 2020, and iPhone XS) don’t have eSIM capability.</li>
      <li>*iPhone 14, iPhone 14 Plus, iPhone 14 Pro, iPhone 14 Pro Max, iPhone 15, iPhone 15 Plus, iPhone 15 Pro, and iPhone 15 Pro Max are not compatible with physical SIM cards in the USA.</li>
      <li>iPad Pro 11″ (model A2068, from 2020)</li>
      <li>iPad Pro 12.9″ (model A2069, from 2020)</li>
      <li>iPad Air (model A2123, from 2019)</li>
      <li>iPad (model A2198, from 2019)</li>
      <li>iPad Mini (model A2124, from 2019)</li>
      <li>IPad 10th generación (modelo 2022)</li>
    </ul>
    <h3 class="font-medium text-orange text-3xl pb-4 pt-6">Samsung</h3>
    <ul class="list-disc text-sm">
      <li>Samsung Galaxy S20, S20+, S20+ 5g, S20 Ultra, S20 Ultra 5G</li>
      <li>Samsung Galaxy S21, S21+ 5G, S21+ Ultra 5G</li>
      <li>Samsung Galaxy S22/ S22+/ S22 Ultra</li>
      <li>Samsung Galaxy S23/ S23+/ S23 Ultra</li>
      <li>Samsung Galaxy Note 20/ Note 20 Ultra 5G</li>
      <li>Samsung Galaxy Fold</li>
      <li>Samsung Galaxy Z Fold2 5G</li>
      <li>Samsung Galaxy Z Fold3 5G</li>
      <li>Samsung Galaxy Z Fold4</li>
      <li>Samsung Galaxy Z Fold5 5G</li>
      <li>Samsung Galaxy Z Flip</li>
      <li>Samsung Galaxy Z Flip3 5G</li>
      <li>Samsung Galaxy Z Flip4</li>
      <li>Samsung Galaxy Z Flip5 5G</li>
      <li>Samsung Galaxy A54 (SCG21 (Japanese Model), SC-53D (Japanese Model), SM-A546B/DS (International Model), SM-A546S (Korean Model), SM-A546U1 (International Model))</li>
      <li>The following Samsung devices are not compatible with eSIM: Samsung Galaxy S20 FE 4G/5G, Samsung S20/S21 (US versions), Galaxy Z Flip 5G (US versions), Samsung Note 20 Ultra (Versions from the US and Hong Kong), Samsung Galaxy Z Fold 2 (Versions from the US and Hong Kong). </li>
      <li>The following models purchased in South Korea: Galaxy S20, Galaxy S20+ 5G, Galaxy S20 Ultra, Galaxy S20 Ultra 5G, Galaxy S21, Galaxy S21+ 5G, Galaxy S21 Ultra 5G, Galaxy S22, Galaxy S22+, Galaxy S22 Ultra, Galaxy Note 20 Ultra 5G, Galaxy Note 20, Galaxy Fold, Galaxy Z Fold2 5G, Galaxy Z Fold3 5G, Galaxy Z Flip, Galaxy Z Flip 5G y el Galaxy Z Flip3 5G.</li>
      <li>*Depending on the country of origin, your device may not support eSIM. Please check if the device in your location supports this feature.</li>
    </ul>
    <h3 class="font-medium text-orange text-3xl pb-4 pt-6">Google</h3>
    <ul class="list-disc text-sm">
      <li>Google Pixel 2 (only phones bought with Google Fi service)</li>
      <li>Google Pixel 2 XL</li>
      <li>Google Pixel 3 (not including phones bought in Australia, Taiwan or Japan)</li>
      <li>Google Pixel 3 XL</li>
      <li>Google Pixel 3a (not including phones bought in Japan or with Verizon service)</li>
      <li>Google Pixel 3a XL</li>
      <li>Google Pixel 4</li>
      <li>Google Pixel 4a</li>
      <li>Google Pixel 4 XL</li>
      <li>Google Pixel 5</li>
      <li>Google Pixel 5a</li>
      <li>Google Pixel 6</li>
      <li>Google Pixel 6a</li>
      <li>Google Pixel 6 Pro</li>
      <li>Google Pixel 7</li>
      <li>Google Pixel 7 Pro</li>
      <li>Google Pixel 8</li>
      <li>Google Pixel 8 Pro</li>
      <li>Google Pixel Fold</li>
      <li>*Google Pixel 3 devices from Australia, Japan, and Taiwan are not compatible with eSIM.</li>
      <li>*Google Pixel 3a from South East Asia is not compatible with eSIM.</li>
    </ul>

    <h3 class="font-medium text-orange text-3xl pb-4 pt-6">Others</h3>
    <ul class="list-disc text-sm">
      <li>Huawei P40</li>
      <li>Huawei P40 Pro</li>
      <li>Huawei Mate 40 Pro</li>
      <li>*The Huawei P40 Pro+ and P50 Pro are not compatible with eSIM.</li>
      <li>Oppo Find X3 Pro</li>
      <li>Find N2 Flip</li>
      <li>Oppo Reno 5A</li>
      <li>Oppo Reno 6 Pro 5G</li>
      <li>Oppo Reno 9A</li>
      <li>Oppo Find X5</li>
      <li>Oppo Find X5 Pro</li>
      <li>Oppo A55s 5G</li>
      <li>*Please note that the OPPO Lite line does not support eSIM.</li>
      <li>Sony Xperia 10 III Lite</li>
      <li>Sony Xperia 10 IV</li>
      <li>Xperia 10V</li>
      <li>Xperia 1 IV</li>
      <li>Sony Xperia 5 IV</li>
      <li>Sony Xperia 1 V</li>
      <li>Sony Xperia Ace III</li>
      <li>Xiaomi 12T Pro</li>
      <li>Xiaomi 13</li>
      <li>Xiaomi 13 Lite</li>
      <li>Xiaomi 13 Pro</li>
      <li>Xiaomi 13T Pro</li>
      <li>Motorola phones with eSIM</li>
      <li>Motorola Razr 2019</li>
      <li>Motorola Razr 5G</li>
      <li>Motorola Razr 40</li>
    </ul>

  </section>
</main>
