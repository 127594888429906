import { Pipe, type PipeTransform } from '@angular/core';

@Pipe({
  name: 'appFlags',
  standalone: true,
})
export class FlagsPipe implements PipeTransform {

  transform(value: string): string {

    if (value === 'Israel_Long_Term' || value === 'SKY2000') {
      return 'assets/images/flags/israel-flag.svg';
    }

    if (value) {
      return 'assets/images/flags/' + value.toLowerCase().replaceAll(" ", "-") + '-flag.svg';
    } 
    
    return 'null'
  }

}
